import React from "react";
import AboutFirstSection from "../../Components/AboutFirstSection/AboutFirstSection";
import Layout from "../../Components/Common/Layout";
import OurTeam from "../../Components/OurTeam/OurTeam";
import OurValues from "../../Components/OurValues/OurValues";
import OurVision from "../../Components/OurVision/OurVision";
import OurStory from "../../Components/OurStory/OurStory";
import AboutUniverseInfo from "../../Components/AboutUniverseInfo/AboutUniverseInfo";

function AboutPage() {
  return (
    <div>
      <Layout>
        <AboutFirstSection />
        <AboutUniverseInfo />
        <OurStory />
        <OurVision />
        <OurValues />
         {/* <OurTeam />  */}
      </Layout>
    </div>
  );
}

export default AboutPage;
