import { React, useState, useEffect } from "react";
import { useBlogs } from "../../Context/BlogContext";
import { Button, Box } from "@mui/material";
import styled from "@emotion/styled";

const CustomButton = styled(Button)(({ selected }) => ({
  padding: " 3px 15.91px 4px 12px ",
  color: selected ? "#FFFFFF" : "rgba(0, 0, 0, 0.64)",
  marginRight: "8px",
  backgroundColor: selected ? "#5570F1" : "rgba(0, 0, 0, 0.10)",
  width: "auto",
  height: "32px",
  fontSize: "11px",
  fontFamily: "Prelia",
  textTransform: "capitalize",
  "&:hover": {
    backgroundColor: selected ? "#3D51B6" : "rgba(0, 0, 0, 0.14)",
  },
  boxShadow: "none",
  marginTop: "10px",
  borderRadius: "8px",
}));

const Filtration = ({ onFilterChange }) => {
  const { blogs, setFilteredBlogs } = useBlogs();
  const [selectedFilter, setSelectedFilter] = useState("see all");

  useEffect(() => {
    setFilteredBlogs(blogs);
  }, []);

  const filterOptions = [
    "see all",
    "Telecom",
    "Software",
    "E-Marketing",
    "Technology",
    "Digital Trends",
    "Other",
  ];

  const handleSelectedFilter = (option) => {
    setSelectedFilter(option);
    onFilterChange(option);
    const filteredItems =
      option.toLowerCase() === "see all"
        ? blogs
        : blogs?.filter(
            (item) =>
              option.toLowerCase() === "see all" ||
              item.category.toLowerCase() === option.toLowerCase()
          );
    setFilteredBlogs(filteredItems);
  };
  return (
    <Box>
      {filterOptions.map((option) => (
        <CustomButton
          key={option}
          variant={"contained"}
          onClick={() => handleSelectedFilter(option)}
          selected={selectedFilter === option}
        >
          {option}
        </CustomButton>
      ))}
    </Box>
  );
};

export default Filtration;
