import React, { useState } from "react";
import BusinessSectionTitle from "../BusinessSectionTitle/BusinessSectionTitle";
import { Box, Container } from "@mui/material";
import BusinessLineDetailsList from "./BusinessLineDetailsList";
import ListItemContent from "./ListItemContent";
import ResponsiveListItemContent from "./ResponsiveListItemContent";
import styled from "@emotion/styled";

const StyledBox = styled(Box)({
  display: "flex",
  justifyContent: "space-between",
  margin: "10px 0px 30px 0px",
  "@media (max-width: 900px)": {
    display: "block",
    margin: "20px 40px",
  },
});

const BusinessLineDetails = ({ color, image, data, title }) => {
  const [selectedItemText, setSelectedItemText] = useState(
    data?.[0]?.content || null
  );

  const [isMobileView, setIsMobileView] = useState(false);

  const handleListItemClick = (text) => {
    setSelectedItemText(text);
    setIsMobileView(true);
  };

  const handleMobileViewClose = () => {
    setIsMobileView(false);
  };

  const isMobile = window.innerWidth <= 600;

  return (
    <Box>
      <Container>
        <BusinessSectionTitle title={title} />
        <StyledBox>
          {isMobile ? (
            isMobileView ? (
              <ResponsiveListItemContent
                selectedItemText={selectedItemText}
                onCloseClick={handleMobileViewClose}
                color={color}
              />
            ) : (
              <BusinessLineDetailsList
                onItemClick={handleListItemClick}
                listItems={data}
                color={color}
              />
            )
          ) : (
            <>
              <BusinessLineDetailsList
                onItemClick={handleListItemClick}
                listItems={data}
                color={color}
              />
              <ListItemContent
                selectedItemText={selectedItemText}
                image={image}
              />
            </>
          )}
        </StyledBox>
      </Container>
    </Box>
  );
};

export default BusinessLineDetails;
