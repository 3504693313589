import React, { useState } from "react";
import styles from "./Faquestions.module.css";
import Faquestion from "../Faquestion/Faquestion";
import { useMediaQuery } from "@mui/material";

export default function Faquestions() {
  const [isComponentAOpen, setComponentAOpen] = useState(false);
  const [isComponentBOpen, setComponentBOpen] = useState(false);
  const [isComponentCOpen, setComponentCOpen] = useState(false);

  const toggleComponentA = () => {
    setComponentAOpen(!isComponentAOpen);
    setComponentBOpen(false);
    setComponentCOpen(false);
  };

  const toggleComponentB = () => {
    setComponentBOpen(!isComponentBOpen);
    setComponentAOpen(false);
    setComponentCOpen(false);
  };

  const toggleComponentC = () => {
    setComponentCOpen(!isComponentCOpen);
    setComponentAOpen(false);
    setComponentBOpen(false);
  };

  const handleContactClick = () => {
    setTimeout(() => {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }, 100); 
  };  

  const isSmallScreen = useMediaQuery("(max-width:1100px)");
  return (
    <div className={styles.container} style={{ textAlign: 'center', marginLeft: "auto", marginRight: "auto" }}>
      <p style={{ color: '#0B3782' }} className={styles.title}>
        Frequently Asked Questions
      </p>
      <div style={{ width: isSmallScreen ? "80%" : "1048px", marginLeft: "auto", marginRight: "auto" }}>
        <Faquestion isOpen={isComponentAOpen} text="What's the Company's Mission and Vision?" paragraph="Our vision is to provide what humanity deserves. And our mission is to employ state-of-the-art technologies and utilize our cutting-edge skills with our professionals to provide the technologies you deserve!" toggleOpen={toggleComponentA} />
        <Faquestion
            isOpen={isComponentBOpen}
            text="How Do I Contact Your Company?"
            paragraph={
                <>
                    You can reach us via the{' '}
                    <span
                        style={{
                            color: '#0B3782', 
                            cursor: 'pointer',
                            textDecoration: 'none',
                            fontFamily: 'Prelia', 
                            fontSize: 'inherit', 
                            margin: 0, 
                        }}
                        onClick={handleContactClick}
                    >
                        <span style={{ color: '#4B82F7' }}>contact us page</span> 
                        <span style={{
                            color: '#0B3782',}}>.</span>
                    </span>
                </>
            }
            toggleOpen={toggleComponentB}
        />
        <Faquestion isOpen={isComponentCOpen} text="What Are Your Operating Hours?" paragraph="We are available from Sunday – Thursday 8:00 am to 5:00 pm." toggleOpen={toggleComponentC} />
      </div>
    </div>
  );
}
