export const universeTelecomSolutionsData = [
  {
    id: 1,
    title: "E-Sport",
    text: "E-sport, e-game, or electronic sports; definitely you will be thrilled to organize tournaments to excite your clients, we are here to compete. ",
  },
  {
    id: 2,
    title: " Video on Demand and Live Streaming ",
    text: "We help you to delight your subscribers with rich content from big brands platforms. Are you thinking of the payment? Don’t worry our payment solutions can handle it. ",
  },
  {
    id: 3,
    title: " Ringing Back Tone – RBT",
    text: "Let your clients enjoy our endless list of the best and latest music through a wide category of musical content.",
  },
];

export const universeTelecomSolutionsData2 = [
  {
    id: 1,
    title: "E-Sport",
    text: "E-sport, e-game, or electronic sports; definitely you will be thrilled to organize tournaments to excite your clients, we are here to compete. ",
  },
  {
    id: 2,
    title: " Social Solutions",
    text: " ",
  },
  {
    id: 3,
    title: " Video on Demand and Live Streaming ",
    text: "We help you to delight your subscribers with rich content from big brands platforms. Are you thinking of the payment? Don’t worry our payment solutions can handle it. ",
  },
  {
    id: 4,
    title: " Ringing Back Tone – RBT",
    text: "Let your clients enjoy our endless list of the best and latest music through a wide category of musical content.",
  },

  {
    id: 5,
    title: " Music Platform ",
    text: "",
  },
];
