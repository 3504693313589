import { Box, Container, Typography } from "@mui/material";
import React, { useState } from "react";
import BusinessSectionTitle from "../BusinessSectionTitle/BusinessSectionTitle";
import styled from "@emotion/styled";

const ItemBox = styled(Box)(({ boxColor, backgroundColor }) => ({
  fontFamily: "Prelia",
  fontSize: "16px",
  lineHeight: "16px",
  padding: "13px 22px",
  color: boxColor,
  backgroundColor: backgroundColor,
  borderRadius: "8px",
  boxShadow: "0px 3px 6px rgba(7, 52, 119, 0.24)",
  marginBottom: "30px",
  cursor: boxColor != "#3eceb3" ? "pointer" : "default", 
  position: "relative",
  "@media (max-width: 600px)": {
    fontSize: "12px",
    marginBottom: "20px",
  },
}));

const HoverBox = styled(Box)(() => ({
  background: "#FFF",
  display: "none",
  position: "absolute",
  top: "110%",
  left: "0",
  padding: "10px",
  borderRadius: "8px",
  zIndex: 1,
  width: "280px",
  height: "180px",
  fontSize: "12px",
  fontFamily: "Prelia",
  lineHeight: "24px",
  boxShadow: "0px 3px 6px rgba(7, 52, 119, 0.24)",
  textTransform: "none",
  color: "#0B3782",
}));

const ItemsBox = styled(Box)({
  display: "flex",
  justifyContent: "space-between",
  marginTop: "30px",
  flexWrap: "wrap",
  gap: "6px",
  "@media (max-width: 600px)": {
    display: "block",
    textAlign: "center",
    width: "80%",
    margin: "0px auto",
  },
});

const Messaging = ({ data, color, hasDescription }) => {
  const { title, bodyText, items } = data;

  const hexToRGBA = (color) => {
    const r = parseInt(color.slice(1, 3), 16);
    const g = parseInt(color.slice(3, 5), 16);
    const b = parseInt(color.slice(5, 7), 16);
    return `rgba(${r}, ${g}, ${b}, 0.12)`;
  };

  const [hoveredText, setHoveredText] = useState(null);

  return (
    <Box sx={{ margin: "60px 0px" }}>
      <Container>
        <BusinessSectionTitle title={title} />
        <Typography
          color="#0B3782"
          fontSize={{ xs: "14px", sm: "16px" }}
          fontFamily="Prelia"
          lineHeight="30.48px"
          m={"10px 0px"}
        >
          {bodyText}
        </Typography>

        {hasDescription ? (
          <ItemsBox>
            {items?.map(({ title, text }, index) => (
              <ItemBox
                boxColor={color}
                backgroundColor={hexToRGBA(color)}
                key={index}
                onMouseEnter={() => setHoveredText(text)}
                onMouseLeave={() => setHoveredText(null)}
              >
                {title}
                <HoverBox
                  style={{ display: hoveredText === text ? "block" : "none", fontSize: "15px" }} //textAlign: "justify"
                >
                  {text}
                </HoverBox>
              </ItemBox>
            ))}
          </ItemsBox>
        ) : (
          <ItemsBox>
            {items?.map((item, index) => (
              <ItemBox
                boxColor={color}
                backgroundColor={hexToRGBA(color)}
                key={index}
              >
                {item}
              </ItemBox>
            ))}
          </ItemsBox>
        )}
      </Container>
    </Box>
  );
};

export default Messaging;