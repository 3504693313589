import React from 'react';
import styles from './DropdownItem.module.css';

function DropdownItem({ imageSrc, text }) {
    return (
        <div className={styles.container}>
            <img src={imageSrc} alt="Dropdown Icon" style={{ width: '24px', height: '24px', marginRight: '8px' }} />
            <span className={styles.text}>{text}</span>
        </div>
    );
}

export default DropdownItem;
