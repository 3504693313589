const DCBInfo = (
  <>
    <p>
      Providing innovative mobile payment solutions which is a powerful tool for expanding the customer base rapidly and providing high-quality user’s experience.
    </p>

    <div
      style={{
        display: "flex",
        gap: "70px",
        "@media (max-width: 600px)": {
          display: "block",
        },
      }}
    >
      <div>
        <b style={{fontFamily: "Roie"}}> ► Main Features: </b>
        <li>Low Risk.</li>
        <li>Worldwide Network.</li>
        <li>Increase Revenues.</li>
        <li>Pay anywhere, anytime. </li>
        <li>Secure payment method.</li>
        <li>Easy & Dynamic Integration Process.</li>
      </div>
    </div>
  </>
);

const psmsInfo = (
  <>
    <p>
      Expand your business, and put your clients at ease, through PSMS solutions to monetize digital services and goods. PSMS payment is one of the most-used methods worldwide for micro-transactions.     
    </p>

    <div
      style={{
        display: "flex",
        gap: "70px",
        "@media (max-width: 600px)": {
          display: "block",
        },
      }}
    >
      <div>
        <b style={{fontFamily: "Roie"}}> ► Main Features: </b>
        <li>Instant Delivery.</li>
        <li>Secure Channel.</li>
        <li>Fast Communication.</li>
        <li>Suitable to all Businesses.</li>
      </div>
    </div>
  </>
);

const landInfo = (
  <>
    <p>
      Universe Telecom’s payment system supports payment to be charged to their landline bill. By this, you can delight your customers and provide them access to the service they need in simple steps.      
    </p>

    <div
      style={{
        display: "flex",
        gap: "70px",
        "@media (max-width: 600px)": {
          display: "block",
        },
      }}
    >
      <div>
        <b style={{fontFamily: "Roie"}}> ► Main Features: </b>
        <li>New channel of revenues.</li>
        <li>Secured transactions.</li>
        <li>Expand the customer base.</li>
      </div>
    </div>
  </>
);

const ipsInfo = (
  <>
    <p>
      Exceed your client’s expectations, enrich your services, through our flexible, reliable, and trusted payment channel which is by the internet service provider to allow secure and ultra-fast online purchases which are directly charged to the user’s internet bill.      
    </p>

    <div
      style={{
        display: "flex",
        gap: "70px",
        "@media (max-width: 600px)": {
          display: "block",
        },
      }}
    >
      <div>
        <b style={{fontFamily: "Roie"}}> ► Main Features: </b>
        <li>Wide range of price points.</li>
        <li>No need to enter bank details.</li>
        <li>Secured and fast transactions.</li>
        <li>Suitable for one-shot payments and subscriptions.</li>
      </div>
    </div>
  </>
);

const eInfo = (
  <>
    <p>
      Unique payment channel displacing mobile wallets, and providing the opportunity of expanding businesses by incorporating real-time payments into their operations:

      <li style={{ fontFamily: "Roie" }}>E-wallet Owner: <span style={{ fontFamily: "Prelia" }}>if you are an e-wallet owner then you are invited to do &nbsp;&nbsp;&nbsp;business together; we can enrich your platform with our endless vouchers &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;collection.</span>
      </li>
      <li style={{ fontFamily: "Roie" }}>Merchants: <span style={{ fontFamily: "Prelia" }}>we provide merchants with large number of payment channels &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;with easy APIs integration to sell your products and services.   </span> </li>
    </p>

    <div
      style={{
        display: "flex",
        gap: "70px",
        "@media (max-width: 600px)": {
          display: "block",
        },
      }}
    >
      <div style={{marginTop: "-0.9rem"}}>
        <b style={{fontFamily: "Roie"}}> ► Main Features: </b>
        <li>Real-Time Payments.</li>
        <li>Quick and Seamless Transactions.</li>
        <li>Secured Payment Channel.</li>
        <li>Simple Integration Through Unified APIs.</li>
      </div>
    </div>
  </>
);

const bankInfo = (
  <>
    <p>
      One of Universe Telecom’s secured payment channels that is beneficial for local and global businesses success. Experience the method of purchasing through bank cards and ensure your transactions are not only secure but also incredibly easy. We have got your financial needs covered from cards to online payments.      <br />
    </p>

    <div
      style={{
        display: "flex",
        gap: "70px",
        "@media (max-width: 600px)": {
          display: "block",
        },
      }}
    >
      <div>
        <b style={{fontFamily: "Roie"}}> ► Main Features: </b>
        <li>Various types of cards.</li>
        <li>High acceptance rate.</li>
        <li>Secured online payments.</li>
        <li>Valid for all types of amounts.</li>
        <li>One-shot payments or subscriptions.</li>
      </div>
    </div>
  </>
);

const vouchersInfo = (
  <>
    <p>
      Universe Telecom serves the market of vouchers by offering wide range of digital vouchers that are securely stored at our platform and ready to be purchased with simple clicks and enjoy a seamless and instant purchase process.    </p>

    <div
      style={{
        display: "flex",
        gap: "70px",
        "@media (max-width: 600px)": {
          display: "block",
        },
      }}
    >
      <div>
        <b style={{fontFamily: "Roie"}}> ► Main Features: </b>
        <li>Buy in Bulk.</li>
        <li>24/7 Customer Support.</li>
        <li>Discounts and promotions.</li>
        <li>Easy Integration through APIs.</li>
        <li>Customized Incentives & Rewards.</li>
      </div>
    </div>
  </>
);

export const universeTelecomLineData = [
  {
    id: 1,
    title: "Direct Carrier Billing",
    content: DCBInfo,
    height: "600px",
  },

  {
    id: 2,
    title: "PSMS",
    content: psmsInfo,
    height: "600px",
  },

  {
    id: 3,
    title: "Landline",
    content: landInfo,
    height: "600px",
  },

  {
    id: 4,
    title: "IPS",
    content: ipsInfo,
    height: "600px",
  },

  {
    id: 5,
    title: "E-Wallet",
    content: eInfo,
    height: "600px",
  },

  {
    id: 6,
    title: "Banks and Cards Payment",
    content: bankInfo,
    height: "600px",
  },
  {
    id: 7,
    title: "Vouchers",
    content: vouchersInfo,
    height: "600px",
  },
];
