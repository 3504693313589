import React, { useRef } from "react";
import AliceCarousel from "react-alice-carousel";
import "react-alice-carousel/lib/alice-carousel.css";
import { Container, IconButton, Box, useMediaQuery } from "@mui/material";
import ArrowBackIosNewOutlinedIcon from "@mui/icons-material/ArrowBackIosNewOutlined";
import ArrowForwardIosOutlinedIcon from "@mui/icons-material/ArrowForwardIosOutlined";

const CustomCarousel = ({ items }) => {
  const carouselRef = useRef(null);

  const handlePrevClick = () => {
    if (carouselRef.current) {
      carouselRef.current.slidePrev() ;
    }
  };

  const handleNextClick = () => {
    if (carouselRef.current) {
      carouselRef.current.slideNext() ;
    }
  };

  

  const responsive = {
    0: { items: 1 },
    600: { items: 2 },
    900: { items: 3 },
  };

  const isMobile = useMediaQuery("(max-width: 600px)");
  const isTablet = useMediaQuery("(max-width: 900px)");

  const buttonStyle = {
    position: "absolute",
    width: "50px",
    backgroundColor: "#FFF",
    boxShadow: "1px 1px 1px gray",
    border: "1px solid gray",
  };

  return (
    <Container>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          margin: "20px  0px 10px ",
          position: "relative",
        }}
      >
        <AliceCarousel
          mouseTracking
          items={items}
          responsive={responsive}
          controlsStrategy="alternate"
          disableDotsControls
          ref={carouselRef}
          stagePadding={{ paddingLeft: 50, paddingRight: 50 }}
          renderPrevButton={() => (
            <IconButton
              style={{ ...buttonStyle, left: "15%" }}
              onClick={handlePrevClick}
            >
              <ArrowBackIosNewOutlinedIcon />
            </IconButton>
          )}
          renderNextButton={() => (
            <IconButton
              style={{ ...buttonStyle, right: "20%" }}
              onClick={handleNextClick}
            >
              <ArrowForwardIosOutlinedIcon />
            </IconButton>
          )}
       
        />
      </Box>
    </Container>
  );
};

export default CustomCarousel;
