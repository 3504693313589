import { Box } from "@mui/material";
import { React } from "react";
import BlogDetailsTopSection from "./BlogDetailsTopSection/BlogDetailsTopSection";
import { useBlogs } from "../../Context/BlogContext";
import { useParams } from "react-router-dom";
import BlogDetailsBottomSection from "./BlogDetailsBottomSection/BlogDetailsBottomSection";

const BlogDetails = () => {
  const { id } = useParams();
  const { blogs } = useBlogs();

  const selectedBlog = blogs?.find((blog) => blog.id === Number(id));

  return (
    <Box margin={"100px 0px 40px"}>
      <BlogDetailsTopSection blogDetails={selectedBlog} />
      <BlogDetailsBottomSection
        category={selectedBlog?.category}
        id={selectedBlog?.id}
      />
    </Box>
  );
};

export default BlogDetails;
