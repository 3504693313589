import { Box, Typography } from "@mui/material";
import React from "react";
import { useBlogs } from "../../../Context/BlogContext";
import BlogCard from "../../BlogCard/BlogCard";
import { Link } from "react-router-dom";
import styled from "@emotion/styled";

const RelatedArticlesBox = styled(Box)({
  display: "flex",
  flexWrap: "wrap",
  margin: "44px 130px ",
  gap: "50px",
  justifyContent: "flex-start",
  "@media (max-width: 900px)": {
    justifyContent: "space-evenly",
    margin: "20px 30px",
  },
});

const BlogDetailsBottomSection = ({ category, id }) => {
  const { blogs } = useBlogs();

  const relatedArticles = blogs
    .filter(
      (blog) =>
        blog.category.toLowerCase() === category?.toLowerCase() &&
        blog.id !== id
    )
    .sort((a, b) => new Date(b.date) - new Date(a.date));

  let maxArticlesToShow = 2;

  if (window.innerWidth <= 600) {
    maxArticlesToShow = 1;
  } else if (window.innerWidth > 900) {
    maxArticlesToShow = 3;
  }

  if (relatedArticles.length === 0) {
    return null;
  }

  return (
    <Box margin={"72px 0px 90px"}>
      <Typography
        color={"#0B3782"}
        fontFamily={"Roie"}
        fontSize={{ xs: "30px", md: "44px" }}
        letterSpacing={"-0.72px"}
        textAlign={"center"}
      >
        Related Articles
      </Typography>
      <RelatedArticlesBox>
        {relatedArticles?.slice(0, maxArticlesToShow).map((blog) => (
          <Link key={blog.id} to={`/blogs/${blog.id}`}>
            <BlogCard blog={blog} key={blog.id} />
          </Link>
        ))}
      </RelatedArticlesBox>
    </Box>
  );
};

export default BlogDetailsBottomSection;
