import React from "react";
import styles from "./JobsHead.module.css";

export default function JobsHead() {
  const containerStyle = {
    textAlign: "justify",
    width: "fit-content"
  };
  return (
    <div className={styles.container}>
      <p className={styles.title}>Jobs & Opportunities</p>
      <p className={styles.paragraph} style={containerStyle}>
      This is where your journey with Universe begins!
<br/><br/>
At Universe, we don’t just offer jobs; we create careers. We are always seeking for a dedicated team members who share our passion and look for success through an exceptional working environment where talents are nurtured and ambitions are realized. Your contributions matter. Join us in making a difference in the world through our innovative solutions and initiatives.
      </p>
      <p className={styles.paragraph}>Available vacancies:</p>
    </div>
  );
}
