import React from "react";
import Layout from "../../Components/Common/Layout";
import WhyUniverseFirstSection from "../../Components/WhyUniversFirstSection/WhyUniverseFirstSection";
import WhyUniverseSecondSection from "../../Components/WhyUniverseSecondSection/WhyUniverseSecondSection";

function WhyUniversePage() {
  return (
    <Layout>
      <WhyUniverseFirstSection />
      <WhyUniverseSecondSection />
    </Layout>
  );
}

export default WhyUniversePage;
