import React, { useState } from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import { Button, useMediaQuery } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Link } from 'react-router-dom';

export default function JobAccordion({ colorLabel, textLabel, id, jobTitle, jobCategory, jobDescription, jobDetails, jobQualifications, jobDate, isOpen, onAccordionClick }) {
    const [expanded, setExpanded] = useState(null);
    const paragraphParts = jobDescription.split('@@@');
    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : null);
    };
    let date = jobDate.split("T");
    jobDate=date[0];
    const isMobileView = useMediaQuery('(max-width:900px)');
    const commonTypographyStyle = {
        fontFamily: 'Prelia',
        fontSize: isMobileView ? '12px' : '16px',
        fontWeight: 400,
        lineHeight: '24px',
        letterSpacing: '0em',
        textAlign: 'left',
        color: isOpen ? '#4B82F7' : '#0B3782',
    };
    const isxSmallScreen = useMediaQuery("(max-width:400px)");

    return (
        <Accordion
            expanded={isOpen}
            onChange={onAccordionClick}
        >
            <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
                sx={{ bgcolor: isOpen ? '#4B82F71A' : '#fff' }}
            >
                <Typography style={commonTypographyStyle}>
                    {jobTitle}
                </Typography>
                <Button
                    variant="contained"
                    style={{
                        backgroundColor: colorLabel,
                        color: 'white',
                        borderRadius: '10px',
                        fontFamily: 'Prelia',
                        fontSize: isxSmallScreen ? '10px' : '11px',
                        fontWeight: 400,
                        lineHeight: '16px',
                        letterSpacing: '0em',
                        textAlign: 'left',
                        padding: '4px 16px 4px 12px',
                        height: '24px',
                        width: 'fit-content',
                        textTransform: 'capitalize',
                        marginLeft: '30px',
                    }}
                >
                    {textLabel}
                </Button>
            </AccordionSummary>
            <AccordionDetails>
                {paragraphParts.map((part, index) => (
                    <Typography
                        key={index}
                        style={{
                            ...commonTypographyStyle, color: '#0B3782', marginBottom: '20px',
                            fontSize: isMobileView ? '12px' : '16px',
                        }}
                    >
                        {part}
                    </Typography>
                ))}
                <Link to={`/jobsdetails/${id}`} style={{
                    color: "#4B82F7",
                    fontFamily: 'Prelia',
                    fontSize: isMobileView ? '12px' : '16px',
                    fontWeight: 400,
                    lineHeight: '24px',
                    letterSpacing: '0em',
                    textAlign: 'left',
                }}>
                    READ MORE
                </Link>
            </AccordionDetails>
        </Accordion>
    );
}