import React from "react";
import HeroImage from '../../Components/HeroImage/HeroImage';
import BusinessCards from "../../Components/BusinessCards/BusinessCards";
import ImageGallery from '../../Components/ImageGallery/ImageGallery';
import ImageWithText from '../../Components/ImageWithText/ImageWithText';
import Rectangle from '../../Components/Rectangle/Rectangle';
import Layout from '../../Components/SpecialComponentHome/Layout';

const HomePage = () => {
    return (
        <Layout>
            <HeroImage
                title="Innovation you deserve"
                text="Driven by innovation to provide leading-edge technologies in Telecom, Software Solutions, Technologies, and E-Marketing"
                titleColor="#041C56"
                textColor="#0B3782"
            />
            <BusinessCards/>
            <ImageWithText
                backgroundImageUrl="/assets/images/Rectangle.svg"
                imageUrl="/assets/images/WorldMap.svg"
                title="We consistently strive to provide our clients with the finest service."
                paragraph="We are always keen to make our customer Happy. We provide all kind of
        facilities. Your Satisfaction is our main priority."
            />
            {/* <ImageGallery /> */}
            <Rectangle/>
        </Layout>
    );
};

export default HomePage;
