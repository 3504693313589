import React, { useState } from "react";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import styles from "./RequestDemo.module.css";
import {
  Box,
  InputLabel,
  Checkbox,
  TextField,
  Select,
  MenuItem,
  useMediaQuery,
} from "@mui/material";
import { Link, useNavigate } from 'react-router-dom';
import { Alert } from "@mui/material";
import config from "../../config";

const apiUrl = config.urls.apiUrl;

export default function RequestDemo({
  backgroundImageUrl,
  title_first,
  title_second,
}) {
  const isxSmallScreen = useMediaQuery("(max-width:700px)");

  const containerStyle = {
    backgroundImage: `url(${backgroundImageUrl})`,
    backgroundRepeat: "no-repeat",
    backgroundPosition: "bottom",
    height: "97%",
    paddingBottom: "3rem",
    marginLeft: isxSmallScreen ? "6rem" : "",
  };

  const isSmallScreen = useMediaQuery("(max-width:1200px)");

  const buttonSubmit = {
    width: isSmallScreen ? "15rem" : "528px",
    marginTop: "14px",
  };

  const sharedTitleStyle = {
    fontFamily: "Roie",
    fontSize: isSmallScreen ? "22px" : "28px",
    fontWeight: 400,
    lineHeight: "44px",
    letterSpacing: "-0.7200000286102295px",
    textAlign: "center",
  };

  const titleSecondStyle = {
    ...sharedTitleStyle,
    color: "#39abd7",
  };

  const customInputLabelStyle = {
    fontFamily: "Prelia",
    fontSize: isSmallScreen ? "12px" : "14px",
    fontWeight: 400,
    lineHeight: "18px",
    textAlign: "left",
    color: "#0B3782",
    padding: "8px 0",
    marginTop: "15px",
  };

  const customTextFieldStyle = {
    width: isSmallScreen ? "15rem" : "528px",
    color: "#0B3782",
  };

  const label = "Checkbox Label";

  const [fName, setFName] = useState("");
  const [nameError, setNameError] = useState("");
  const handleNameChange = (event) => {
    const newName = event.target.value;
    setNameError("");
    setFName(newName);
  };
  const [subscribe, setSubscribe] = useState(false);
  const [company, setCompany] = useState("");
  const [companyError, setCompanyError] = useState("");
  const handleCompanyChange = (event) => {
    const newCompany = event.target.value;
    setCompanyError("");
    setCompany(newCompany);
  };
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState("");
  const handleEmailChange = (event) => {
    const newEmail = event.target.value;
    setEmailError("");
    setEmail(newEmail);
  };
  const [phone, setPhone] = useState("");
  const [phoneError, setPhoneError] = useState("");
  const handlePhoneChange = (value) => {
    setPhoneError("");
    setPhone(value);
  };

  const [isChecked, setIsChecked] = useState(false);

  const handleCheckboxChange = (event) => {
    setIsChecked(event.target.checked);
  };

  const [selectedOption, setSelectedOption] = useState("");
  const handleCChange = (event) => {
    setSelectedOption(event.target.value);
  };
  const [checkk, setCheckk] = useState(false);

  const onSubmit = async (event) => {
    event.preventDefault();
    const newEmail = event.target.elements.email.value;
    const newPhone = phone;
    const newCompany = event.target.elements.company.value;
    const newName = event.target.elements.name.value;
    let hasError = false;

    if (!/^[A-Za-z\s]+$/.test(newName)) {
      setNameError("Only letters are allowed");
      hasError = true;
    } else {
      setNameError("");
    }
    if (!/^[A-Za-z\s]+$/.test(newCompany)) {
      setCompanyError("Only letters are allowed");
      hasError = true;
    } else {
      setCompanyError("");
    }
    if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(newEmail)) {
      setEmailError("Please enter a valid email address");
      hasError = true;
    } else {
      setEmailError("");
    }
    if (!/^\d{10,12}$/.test(newPhone)) {
      setPhoneError("Please enter a valid phone number");
      hasError = true;
    } else {
      setPhoneError("");
    }
    if (
      !isChecked &&
      /^\d{10,12}$/.test(newPhone) &&
      /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(newEmail) &&
      /^[A-Za-z\s]+$/.test(newCompany) &&
      /^[A-Za-z\s]+$/.test(newName)
    ) {
      setTimeout(() => {
        setCheckk(false);
      }, 3000);
      setCheckk(true);
    }

    if (!hasError) {
      /////////////////////////////////////////action to be done////////////////////////////////////
      if (
        isChecked &&
        /^\d{10,12}$/.test(newPhone) &&
        /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(newEmail) &&
        /^[A-Za-z\s]+$/.test(newCompany) &&
        /^[A-Za-z\s]+$/.test(newName)
      ) {
        const backendUrl = apiUrl;
        const dataToSend = {
          inputValue: newEmail,
          body:
            "Demo Type Needed: " +
            selectedOption +
            "\n" +
            "First and Last name: " +
            newName +
            "\n" +
            "Company Name: " +
            newCompany +
            "\n" +
            "Email: " +
            newEmail +
            "\n" +
            "Phone Number: +" +
            newPhone,
        };
        fetch(`${backendUrl}/api/email/send-email`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(dataToSend),
        })
          .then((response) => {
            if (!response.ok) {
              throw new Error("Network response was not ok");
            }
            return response.json();
          })
          .then((data) => {
            console.log(data);
          })
          .catch((error) => {
            console.error("Error:", error);
          });
        setFName("");
        setCompany("");
        setSelectedOption("");
        setEmail("");
        setPhone("");
        setIsChecked(false);
        setSubscribe(true);
        setTimeout(() => {
          setSubscribe(false);
        }, 5000);
      }
    }
  };

  return (
    <div className={styles.imageWithTextContainer}>
      <div className={styles.imageWithTextContainer} style={containerStyle}>
        <Box sx={{ marginTop: "-4.8rem", marginLeft: "1rem" }}>
          <p style={{ ...sharedTitleStyle, color: "#0B3782" }}>{title_first}</p>
          <p style={titleSecondStyle} className={styles.secondP}>
            {title_second}
          </p>
        </Box>
        <Box>
          <form
            className={styles.container}
            onSubmit={onSubmit}
            action="request-demo"
            method="POST"
          >
            <p className={styles.subTitle}>Request Demo</p>
            <InputLabel htmlFor="name" style={customInputLabelStyle}>
              First and last name*
            </InputLabel>
            <TextField
              sx={{ bgcolor: "#fff", borderRadius: "4px" }}
              id="name"
              variant="outlined"
              InputLabelProps={{
                style: customInputLabelStyle,
              }}
              InputProps={{
                style: customTextFieldStyle,
              }}
              placeholder="Enter your name"
              name="name"
              required
              error={Boolean(nameError)}
              helperText={nameError}
              onChange={handleNameChange}
              value={fName}
            />
            <InputLabel htmlFor="company" style={customInputLabelStyle}>
              Company*
            </InputLabel>
            <TextField
              sx={{ bgcolor: "#fff", borderRadius: "4px", }}
              id="company"
              variant="outlined"
              InputLabelProps={{
                style: customInputLabelStyle,
              }}
              InputProps={{
                style: customTextFieldStyle,
              }}
              placeholder="Enter your company"
              name="company"
              required
              error={Boolean(companyError)}
              helperText={companyError}
              onChange={handleCompanyChange}
              value={company}
            />
            <InputLabel htmlFor="company" style={customInputLabelStyle}>
              Demo Type*
            </InputLabel>
            <Select
              labelId="sectioned-select-label"
              id="sectioned-select"
              value={selectedOption}
              onChange={handleCChange}
              required
              sx={{
                color: "#0B3782",
                bgcolor: "#fff",
                borderRadius: "4px",
                width: isSmallScreen ? "15rem" : "528px",
              }}
              displayEmpty
            >
              <MenuItem value="" disabled>
                <em>Intelligent Solutions</em>
              </MenuItem>
              <MenuItem value="Business Intelligence" sx={{ color: "#0B3782" }}>Business Intelligence</MenuItem>
              <MenuItem value="Artificial Intelligence" sx={{ color: "#0B3782" }}>Artificial Intelligence</MenuItem>
              <MenuItem value="Cyber Security" sx={{ color: "#0B3782" }}>Cyber Security</MenuItem>
              <MenuItem value="Customer Care Solutions" sx={{ color: "#0B3782" }}>Customer Care Solutions</MenuItem>
              <MenuItem value="E-commerce Solutions" sx={{ color: "#0B3782" }}>E-commerce Solutions</MenuItem>
              <MenuItem value="" disabled>
                <em>Smart Systems</em>
              </MenuItem>
              <MenuItem value="Education System" sx={{ color: "#0B3782" }}>Education System</MenuItem>
              <MenuItem value="Work System" sx={{ color: "#0B3782" }}>Work System</MenuItem>
              <MenuItem value="Security System" sx={{ color: "#0B3782" }}>Security System</MenuItem>
              <MenuItem value="Surveillance System" sx={{ color: "#0B3782" }}>
                Surveillance System
              </MenuItem>
              <MenuItem value="Safety System" sx={{ color: "#0B3782" }}>Safety System</MenuItem>
              <MenuItem value="Virtual Reality" sx={{ color: "#0B3782" }}>Virtual Reality</MenuItem>
              <MenuItem value="Augmented Reality" sx={{ color: "#0B3782" }}>Augmented Reality</MenuItem>
              <MenuItem value="" disabled>
                <em></em>
              </MenuItem>
            </Select>
            <InputLabel htmlFor="email" style={customInputLabelStyle}>
              Email*
            </InputLabel>
            <TextField
              sx={{ bgcolor: "#fff", borderRadius: "4px" }}
              id="email"
              variant="outlined"
              InputLabelProps={{
                style: customInputLabelStyle,
              }}
              InputProps={{
                style: customTextFieldStyle,
              }}
              placeholder="Your Email"
              name="email"
              required
              error={Boolean(emailError)}
              helperText={emailError}
              onChange={handleEmailChange}
              value={email}
              type="email"
            />
            <InputLabel htmlFor="phone" style={customInputLabelStyle}>
              Phone
            </InputLabel>
            <PhoneInput
              inputClass={styles.customPhoneInput}
              id="phone"
              variant="outlined"
              value={phone}
              placeholder="Enter your phone number"
              onChange={handlePhoneChange}
              inputProps={{
                name: "phone",
                required: true,
                autoFocus: true,
                style: customTextFieldStyle,
              }}
            />
            {phoneError && (
              <div
                style={{
                  color: "#d32f2f",
                  fontSize: "0.78rem",
                  marginTop: "8px",
                  marginLeft: "16px",
                }}
              >
                {phoneError}
              </div>
            )}
            <div className={styles.content}>
              <Checkbox
                checked={isChecked}
                onChange={handleCheckboxChange}
                {...label}
              />
              <p className={styles.description}>
                I agree to personal data processing{" "}
                <Link to="/privacypolicy" target="_blank" className={styles.privacyDescription}>
                  Privacy Policy
                </Link>
              </p>
            </div>
            <Box style={{ width: isSmallScreen ? "15rem" : "528px" }}>
              {checkk && (
                <Alert severity="error">
                  Kindly consent to the processing of personal data in
                  accordance with our Privacy Policy.
                </Alert>
              )}
            </Box>
            <Box style={{ width: isSmallScreen ? "15rem" : "528px" }}>
              {subscribe && (
                <Alert severity="info">
                  Your request has been received and is under consideration.
                  Thank you for reaching out!
                </Alert>
              )}
            </Box>
            <button type="submit" style={buttonSubmit} variant="contained">
              Submit
            </button>
          </form>
        </Box>
      </div>
    </div>
  );
}
