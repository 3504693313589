import React from "react";
import styles from "./Footer.module.css";
import FooterHeading from "../FooterHeading/FooterHeading";
import FooterListItem from "../FooterListItem/FooterListItem";
import PhoneOutlinedIcon from "@mui/icons-material/PhoneOutlined";
import FmdGoodOutlinedIcon from "@mui/icons-material/FmdGoodOutlined";
import TelegramIcon from "@mui/icons-material/Telegram";
import { Link } from "react-router-dom";

export default function Footer() {
  const companyInfoItems = [
    { text: "About Us", path: "/about" },
    { text: "Why Universe?", path: "/whyuniverse" },
    { text: "Jobs", path: "/jobspage" },
    { text: "Blogs", path: "/blogs" },
    { text: "FAQ's", path: "/contact/faqs" },
  ];
  const servicesItems = [
    { text: "Telecom", path: "/telecom" },
    { text: "Software Solutions", path: "/software" },
    { text: "Technologies", path: "/technologies" },
    { text: "E-Marketing", path: "/marketing" },
  ];

  return (
    <footer>
      <div className={styles.footerContent}>
        <div className={`${styles.column} ${styles.imageContainer}`}>
          <img
            src= {process.env.PUBLIC_URL +"/assets/images/Universe.svg"}
            alt="Image"
            className={styles.footerImage}
          />
        </div>
        <div className={styles.columnn}>
          <div className={styles.companyInfo}>
            <FooterHeading>Company Info</FooterHeading>
            <div className={styles.companyI}>
              {companyInfoItems.map((item, index) => (
                <Link to={item.path} key={index} className={styles.link}>
                  <FooterListItem text={item.text} />
                </Link>
              ))}
            </div>
          </div>
        </div>
        <div className={styles.columnn}>
          <div className={styles.companyInfo}>
            <FooterHeading>Our Business Lines</FooterHeading>
            <div className={styles.companyI} >
              {servicesItems.map((item, index) => (
                <Link to={item.path} key={index} className={styles.link}>
                  <FooterListItem text={item.text} />
                </Link>
              ))}
            </div>
          </div>
        </div>
        <div className={styles.columnn}>
          <FooterHeading>Get in Touch</FooterHeading>
          <div className={styles.contactInfo}>
            <div className={styles.footerTextContainerIcon}>
              <PhoneOutlinedIcon fontSize="inherit" />
            </div>
            <div className={styles.footerTextContainer}>
              <FooterListItem text="Tel +97022963005 | Fax +97022963966 | B.O.Box 4326" />
            </div>
          </div>
          <div className={styles.contactInfo}>
            <div className={styles.footerTextContainerIcon}>
              <FmdGoodOutlinedIcon fontSize="inherit" />
            </div>
            <div className={styles.footerTextContainer}>
              <FooterListItem text="Haifa Bldg | 5th Floor | Al-Irsal St. | Ramallah | Palestine" />
            </div>
            
          </div>
          <div className={styles.newInfo}>
          <FooterListItem text="Bldg 01 | Ground Floor | Dubai Internet City | Dubai | UAE"/>
          </div>
          <div className={styles.contactInfo}>
            <div className={styles.footerTextContainerIcon}>
              <TelegramIcon fontSize="inherit" />
            </div>
            <div className={styles.footerTextContainer}>
              <FooterListItem text="info@we-universe.com | www.we-universe.com" />
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}
