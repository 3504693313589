import React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import styles from './ClickableCard.module.css';
import { Typography } from '@mui/material';
import { Link } from 'react-router-dom';

export default function ClickableCard({ imageSrc, link, cardColor, title }) {
    return (
        <Link to={link}>
            <Card
                className={styles.Card}
                sx={{
                    bgcolor: "#fff",
                    borderRadius: "15px",
                    width: "287px",
                    height: "156px",
                    cursor: "pointer",
                    transition: "transform 0.3s ease-in-out",
                    boxShadow: `0px 0px 10px 0px ${cardColor || 'rgb(11, 55, 130)'}`,
                    '&:hover': {
                        transform: 'scale(1.05)',
                    },
                }}
                onClick={() => {
                    if (link) {
                        // window.location.href = link;
                    }
                }}
            >
                <CardContent sx={{ textAlign: "center" }}>
                    <img src={imageSrc} alt="Logo" className={styles.logo}/>
                    <Typography sx={{color: "#0B3782", fontFamily: "Qualy", fontSize: "25px", marginTop: "0.1rem"}}>
                        {title}
                    </Typography>
                </CardContent>
            </Card>
        </Link>
    );
}
