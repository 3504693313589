import React from "react";
import { Box } from "@mui/material";

const WhyUniverseImages = () => {
  return (
    <Box
      sx={{
        backgroundImage: `url("${process.env.PUBLIC_URL}/assets/images/whyUniverse.svg")`,
        backgroundPosition: "right 30px top 55px",
        backgroundSize: "190%",
        backgroundRepeat: "no-repeat",
        position: "relative",
        width: "650px",
        height: "600px",
        margin: "90px 0px",
        marginTop:"130px",
        "@media (max-width: 1100px)": {
          display: "none",
        },
      }}
    >
      {/* <img
        src= {process.env.PUBLIC_URL +"/assets/images/whyUniverse.svg"
        className={`${styles.img} ${styles.img1}`}
        alt="Image 1"
      /> */}
    </Box>
  );
};

export default WhyUniverseImages;
