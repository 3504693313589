const BrandingInfo = (
  <>
    <p>
    Creating meaningful connections between your brand and your target audience in the digital world.
          </p>

    <div
      style={{
        display: "flex",
        gap: "70px",
        "@media (max-width: 600px)": {
          display: "block",
        },
      }}
    >
      <div>
        <b style={{fontFamily: "Roie"}}> ► Main Features: </b>
        <li>Branding Strategy.</li>
        <li>High Engagement with audience.</li>
        <li>Brand guidelines outlining rules of branding.</li>
      </div>
    </div>
  </>
);

const IdentityInfo = (
  <>
    <p>
    Managing and providing secure, trusted, and interoperable digital identities for individuals, businesses, or devices in the digital realm.    </p>

    <div
      style={{
        display: "flex",
        gap: "70px",
        "@media (max-width: 600px)": {
          display: "block",
        },
      }}
    >
      <div>
        <b style={{fontFamily: "Roie"}}> ► Main Features: </b>
        <li>Identity Verification.</li>
        <li>User friendly interface.</li>
        <li>Secure recovery process.</li>
      </div>
    </div>
  </>
);

const CampaignsInfo = (
  <>
    <p>
    Promoting your product, service, brand, or message to a target audience through marketing activities and tactics aimed at achieving your business objectives.    </p>

    <div
      style={{
        display: "flex",
        gap: "70px",
        "@media (max-width: 600px)": {
          display: "block",
        },
      }}
    >
      <div>
        <b style={{fontFamily: "Roie"}}> ► Main Features: </b>
        <li>High reach promotions.</li>
        <li>Unique marketing activities.</li>
        <li>Plans and tactics of marketing.</li>
      </div>
    </div>
  </>
);

const ManagementInfo = (
  <>
    <p>
    Our primary goal is to facilitate content creation, editing, and publishing, ensuring that the right content is delivered to the right audience at the right time.
        </p>

    <div
      style={{
        display: "flex",
        gap: "70px",
        "@media (max-width: 600px)": {
          display: "block",
        },
      }}
    >
      <div>
        <b style={{fontFamily: "Roie"}}> ► Main Features: </b>
        <li>Content organization.</li>
        <li>Multilingual support (English & Arabic).</li>
        <li>Diversify Content based on the audience.</li>
      </div>
    </div>
  </>
);

const SocialMediaInfo = (
  <>
    <p>
    We provide you our full assistance to effectively managing your presence on various social media platforms.
        </p>

    <div
      style={{
        display: "flex",
        gap: "70px",
        "@media (max-width: 600px)": {
          display: "block",
        },
      }}
    >
      <div>
        <b style={{fontFamily: "Roie"}}> ► Main Features: </b>
        <li>Monitoring social media pages.</li>
        <li>Effective & efficient management.</li>
        <li>Guidance of managing social media platforms.</li>
      </div>
    </div>
  </>
);

export const EMarketingLineData = [
  {
    id: 1,
    title: "Digital Branding",
    content: BrandingInfo,
  },

  {
    id: 2,
    title: "Digital Identity",
    content: IdentityInfo,
  },

  {
    id: 3,
    title: "Marketing Campaign",
    content: CampaignsInfo
  },

  {
    id: 4,
    title: "Content Management",
    content: ManagementInfo,
  },
  {
    id: 5,
    title: "Social Media Management",
    content: SocialMediaInfo,
  },
];
