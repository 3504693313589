import React from 'react';
import Navbar from '../Navbar/Navbar';
import Footer from '../Footer/Footer';
import CustomFooter from '../CustomFooter/CustomFooter';
import FooterAppendix from '../FooterAppendix/FooterAppendix';
import PageLoader from '../PageLoader/PageLoader';

function Layout({ children }) {
    return (
        <div>
            {/*  in case u have to show the "en" icon just pass -false- to the hideIcon */}
            <Navbar hideIcon={true} />
            <PageLoader loadingImage={process.env.PUBLIC_URL + "/assets/images/Logoo.png"}/>
            {children}
            <CustomFooter
                title="Let’s talk business, don’t hesitate to contact our experts."
                buttonText="Contact Us"
            />
            <Footer />
            <FooterAppendix />
        </div>
    );
}

export default Layout;
