import React from "react";
import styles from "./HeroImageBusinessLines.module.css";
import { Typography } from "@mui/material";
import { useMediaQuery } from '@mui/material';

function HeroImageBusinessLines({ imageSrc, image, text }) {
  const backgroundImageStyle = {
    backgroundImage: `url(${imageSrc})`,
    height: " calc(100vh - 120px)",
    marginTop:"45px"
  };
  const isSmallScreen = useMediaQuery('(max-width:1024px)');
  return (
    <div className={styles["description-block"]} style={backgroundImageStyle}>
      <div
        className={styles.descriptionTextContainer}
        style={{ paddingTop: "64px" }}
      >
        <img src={image} alt="Image" loading="lazy"/>
        <Typography
          sx={{
            letterSpacing: "0rem",
            fontFamily: "Prelia",
            margin: "auto",
            fontSize: isSmallScreen ? "16px" : "22px",
            color: "#0B3782",
          }}
          variant="body2"
          className={styles["description-text"]}
        >
          {text}
        </Typography>
      </div>
    </div>
  );
}

export default HeroImageBusinessLines;
