import React from 'react';
import PropTypes from 'prop-types';
import styles from './FooterText.module.css';

function FooterText({ children, color, font }) {
  const textColorStyle = {
    color: color || 'inherit',
    fontFamily: font || 'inherit',
  };

  return (
    <div className={styles.footerText} style={textColorStyle}>
      {children}
    </div>
  );
}

FooterText.propTypes = {
  children: PropTypes.node.isRequired,
  color: PropTypes.string,
  font: PropTypes.string,
};

export default FooterText;