import React from 'react'
import styles from "./BusinessCards.module.css";
import CardList from '../CardList/CardList';

export default function BusinessCards() {
    return (
        <div className={styles['block']}>
            <CardList
                title="Our Business Lines"
                data={[
                    { imageSrc: process.env.PUBLIC_URL + '/assets/images/RedLogo.svg' },
                    { imageSrc: process.env.PUBLIC_URL + '/assets/images/GreenLogo.svg' },
                    { imageSrc: process.env.PUBLIC_URL + '/assets/images/WhiteBlueLogo.svg' },
                    { imageSrc: process.env.PUBLIC_URL + '/assets/images/BlueLogo.svg' },
                ]}
                links={[`${process.env.PUBLIC_URL}/telecom`, `${process.env.PUBLIC_URL}/software`, `${process.env.PUBLIC_URL}/technologies`, `${process.env.PUBLIC_URL}/marketing`]}
            />
        </div>
    )
}
