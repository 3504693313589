import { React, useState, useRef, useEffect } from "react";
import SolutionCard from "./SolutionCard/SolutionCard";
import { Box, Container, Typography } from "@mui/material";
import BusinessSectionTitle from "../BusinessSectionTitle/BusinessSectionTitle";
import styled from "@emotion/styled";
import SolutionLargeCard from "./SolutionCard/SolutionLargeCard";
import styles from "./BusinessLineSolutions.module.css";

const BusinessLineSolutionsBox = styled(Box)(({ color }) => ({
  backgroundColor: color,
  padding: "10px 0px",
  position: "relative",
}));

const CardsBox = styled(Box)({
  display: "flex",
  flexWrap: "wrap",
  margin: "30px 0px",
  gap: "18px",
});

const BusinessLineSolutions = ({ color, title, data, paragraph }) => {
  const [selectedCard, setSelectedCard] = useState(null);
  const sectionRef = useRef(null);

  useEffect(() => {
    if (selectedCard && sectionRef.current) {
      sectionRef.current.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    }
  }, [selectedCard]);

  const hexToRGBA = (color) => {
    const r = parseInt(color.slice(1, 3), 16);
    const g = parseInt(color.slice(3, 5), 16);
    const b = parseInt(color.slice(5, 7), 16);
    return `rgba(${r}, ${g}, ${b}, 0.12)`;
  };

  const containerStyle = {
    // textAlign: "justify",
    width: "fit-content"
  };

  return (
    <BusinessLineSolutionsBox color={hexToRGBA(color)} ref={sectionRef}>
      <Container>
        <BusinessSectionTitle title={title} />
        <Typography sx={{color: "#0B3782", fontSize: "16px", fontFamily: "Prelia", lineHeight: "26px", marginTop: "0.5rem"}}> {paragraph}</Typography>
        {selectedCard ? (
          <SolutionLargeCard
            selectedCard={selectedCard}
            onCloseClick={() => setSelectedCard(null)}
          />
        ) : (
          <CardsBox style={containerStyle}>
            {data?.map((item) => (
              <SolutionCard
                cardData={item}
                key={item.id}
                setSelectedCard={setSelectedCard}
                color={color}
              />
            ))}
          </CardsBox>
        )}
      </Container>

      <img
        src= {process.env.PUBLIC_URL +"/assets/images/solutions-wave1.svg"}
        className={styles.img1}
      ></img>
      {data.length > 3 ? (
        <img
          src= {process.env.PUBLIC_URL +"/assets/images/solutions-wave2.svg"}
          className={styles.img2}
        ></img>
      ) : null}
    </BusinessLineSolutionsBox>
  );
};

export default BusinessLineSolutions;
