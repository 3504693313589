import React, { useRef, useEffect } from 'react';
import { Typography } from '@mui/material';
import styles from './HeroImage.module.css';
import { useMediaQuery } from '@mui/material';

function HeroImage({ title, text }) {
    let firstPart = '';
    let secondPart = '';

    if (title) {
        const titleParts = title.split(' ');
        firstPart = titleParts[0];
        secondPart = titleParts.slice(1).join(' ');
    }

    const isSmallScreen = useMediaQuery('(max-width:1024px)');
    const videoRef = useRef(null);

    useEffect(() => {
        const handleVideoEnd = () => {
            if (videoRef.current) {
                videoRef.current.pause();
                setTimeout(() => {
                    videoRef.current.currentTime = 0;
                    videoRef.current.play();
                }, 100); 
            }
        };
        if (videoRef.current) {
            videoRef.current.addEventListener('ended', handleVideoEnd);
        }
        return () => {
            if (videoRef.current) {
                videoRef.current.removeEventListener('ended', handleVideoEnd);
            }
        };
    }, []);

    return (
        <div className={styles['description-block']}>
            <video
                ref={videoRef}
                autoPlay
                muted
                className={styles.backgroundVideo}
            >
                <source src= {process.env.PUBLIC_URL +"/assets/images/UniverseVideoo.mp4"} type="video/mp4" />
            </video>
            <div className={styles.descriptionTextContainer} style={{ paddingTop: '64px' }}>
                <Typography sx={{ fontFamily: 'Roie', fontSize: isSmallScreen ? '28px' : '40px', fontWeight: "700"}} variant="h6">
                    <span style={{ color: '#fff', }}>{firstPart}</span>
                    &nbsp;
                    <span style={{ color: '#fff' }}>{secondPart}</span>
                </Typography>
                <Typography sx={{ fontFamily: 'Roie', margin: "auto", fontSize: isSmallScreen ? '18px' : '24px', color: "#fff", fontWeight: "700", marginTop: "1rem" }} variant="body2" className={styles['description-text']}>
                    {text}
                </Typography>
            </div>
        </div>
    );
}

export default HeroImage;
