import React, { useState, useEffect } from 'react';
import {
    AppBar,
    Toolbar,
    Button,
    Paper,
    Popper,
    MenuList,
    MenuItem,
    IconButton,
    TextField,
    useMediaQuery,
    Drawer,
    Box,
} from '@mui/material';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import InfoIcon from '@mui/icons-material/Info';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ContactsOutlinedIcon from '@mui/icons-material/ContactsOutlined';
import SearchIcon from '@mui/icons-material/Search';
import CloseIcon from '@mui/icons-material/Close';
import ExplicitIcon from '@mui/icons-material/Explicit';
import MenuIcon from '@mui/icons-material/Menu';
import styles from './Navbar.module.css';
import StyledButton from '../StyledButton/StyledButton';
import StyledIcon from '../StyledIcon/StyledIcon';
import DropdownItem from '../DropdownItem/DropdownItem';
import { Link, useNavigate } from 'react-router-dom';

function Navbar({ hideIcon }) {
    const [open, setOpen] = useState(false);
    const [trigger, setTrigger] = useState(false);
    const anchorRef = React.useRef(null);
    const menuRef = React.useRef(null);

    const paths = ["/telecom", "/software", "/technologies", "/marketing"];
    const menuItems = [
        { imageSrc: process.env.PUBLIC_URL +"/assets/images/RedLogo.svg", text: "Telecom" },
        { imageSrc: process.env.PUBLIC_URL +"/assets/images/GreenLogo.svg", text: "Software Solutions" },
        { imageSrc: process.env.PUBLIC_URL +"/assets/images/WhiteBlueLogo.svg", text: "Technologies" },
        { imageSrc: process.env.PUBLIC_URL +"/assets/images/BlueLogo.svg", text: "E-Marketing" },
    ];

    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen);
    };

    const handleClose = (event) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }
        setOpen(false);
    };

    const handleListKeyDown = (event) => {
        if (event.key === 'Tab') {
            event.preventDefault();
            setOpen(false);
        }
    };

    const backgroundColors = [
        'rgba(162, 92, 221, 0.1)',
        'rgba(108, 203, 180, 0.1)',
        'rgba(92, 169, 211, 0.1)',
        'rgba(75, 130, 247, 0.1)'
    ];

    const customTextFieldStyle = {
        color: "#0B3782",
    };

    useEffect(() => {
        const handleScroll = () => {
            if (window.scrollY > 50) {
                setTrigger(true);
            } else {
                setTrigger(false);
            }
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const [isSearching, setIsSearching] = useState(false);
    const [errS, setErrS] = useState(false);
    const handleSearchClick = () => {
        setIsSearching(true);
    };

    const handleSearchClose = () => {
        setIsSearching(false);
        setClick(false);
        setErrS(false);
    };

    const [click, setClick] = useState(false);
    const handleLabelClick = () => {
        setClick(true);
    };
    const [rerenderKey, setRerenderKey] = useState(0);
    const navigate = useNavigate();
    const [inputValue, setInputValue] = useState('');

    const handleEnterKeyPress = (event) => {
        if (event.key === 'Enter') {
            event.preventDefault();
            const enteredValue = event.target.value;
            setInputValue(enteredValue);
            // console.log('User entered:', enteredValue);
            if ((/contact( us)?/i).test(enteredValue.toLowerCase()) ||
                enteredValue.toLowerCase() === "Location".toLowerCase())
                //navigate to "contact us" page
                navigate(`/contact`);
            else if ((/about( universe)?/i).test(enteredValue.toLowerCase()))
                navigate(`/about`);
            else if ((/why( universe)?/i).test(enteredValue.toLowerCase()))
                navigate(`/whyuniverse`);
            else if ((/^blogs?$/i).test(enteredValue.toLowerCase()))
                navigate(`/blogs`);
            else if ((/^(jobs & opportunities|jobs|opportunities)$/i).test(enteredValue.toLowerCase()))
                navigate(`/jobspage`);
            else if ((/^(universe telecom|telecom)$/i).test(enteredValue.toLowerCase()))
                navigate(`/telecom`);
            else if ((/^(universe technologies|technologies)$/i).test(enteredValue.toLowerCase()))
                navigate(`/technologies`);
            else if ((/^(universe software solutions|software solutions|software)$/i).test(enteredValue.toLowerCase()))
                navigate(`/software`);
            else if ((/^(universe e-marketing|e-marketing|marketing)$/i).test(enteredValue.toLowerCase()))
                navigate(`/marketing`);
            else if (/\b(?:connectivity & digital payment|connectivity|payment)\b/g.test(enteredValue.toLowerCase()))
                navigate(`/telecom/business`);
            else if (/\b(?:entertainment & social media solutions|entertainment)\b/g.test(enteredValue.toLowerCase()))
                navigate(`/telecom/entertainment`);
            else if ((/^messaging$/i).test(enteredValue.toLowerCase()))
                navigate(`/telecom/messaging`)
            else if ((/^(universe\s+)?products$/i).test(enteredValue.toLowerCase()))
                navigate(`/software/product`)
            else if ((/^(services|mobile|web|Mobile App & Web Development)$/i).test(enteredValue.toLowerCase()))
                navigate(`/software/service`)
            else if ((/^(Intelligent Solutions|intelligent)$/i).test(enteredValue.toLowerCase()))
                navigate(`/software/intelligent`)
            else if ((/^(Off The Shelf Business Solutions|shelf business|business)$/i).test(enteredValue.toLowerCase()))
                navigate(`/software/business`)
            else if ((/^(smart systems|smart)$/i).test(enteredValue.toLowerCase()))
                navigate(`/technologies/smart`)
            else if ((/^(hardware supplies|hardware)$/i).test(enteredValue.toLowerCase()))
                navigate(`/technologies/hardware`)
            else if ((/^(request demo|request|demo)$/i).test(enteredValue.toLowerCase()))
                navigate(`/technologies/request`)
            else if ((/^(digital marketing|digital|marketing)$/i).test(enteredValue.toLowerCase()))
                navigate(`/marketing/digital`)
            else if ((/^(e-commerce)$/i).test(enteredValue.toLowerCase()))
                navigate(`/marketing/commerce`)
            else {
                setClick(false);
                setErrS(true);
            }
            setRerenderKey((prevKey) => prevKey + 1);
        }
    };

    const handleChange = (event) => {
        setInputValue(event.target.value);
    };
    const [openn, setOpenn] = useState(false);
    const toggleDrawer = (openn) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }
        setOpenn(openn);
    };

    const isMobileView = useMediaQuery('(max-width:1224px)');
    const isxMobileView = useMediaQuery('(max-width:700px)');

    const handleCloseIconClick = () => {
        setOpenn(false);
    };

    const linkStyle = {
        color: '#0B3782',
        fontFamily: 'Prelia'
    };

    const linkColors = [
        'rgba(162, 92, 221, 0.8)', 'rgba(108, 203, 180, 0.8)', 'rgba(92, 169, 211, 0.8)', 'rgba(75, 130, 247, 0.8)',
    ];

    const [isMenuVisible, setIsMenuVisible] = useState(false);

    return (
        <Box>
            {isMobileView ? (
                <AppBar
                    position="fixed"
                    sx={{ boxShadow: "none", zIndex: 3 }}
                    elevation={trigger ? 24 : 0}
                    style={{
                        backgroundColor: "#fff",
                    }}
                >
                    <Toolbar className={styles.toolbar}>
                        <a href="/">
                            <img
                                onMouseEnter={handleClose}
                                src= {process.env.PUBLIC_URL +"/assets/images/UniverseLogo.svg"}
                                alt="Logo"
                                className={trigger ? styles.logoSmall : styles.logo}
                            />
                        </a>
                        <IconButton
                            edge="start"
                            color="inherit"
                            aria-label="open drawer"
                            onClick={toggleDrawer(true)}
                            sx={{
                                color: "#0B3782",
                                '&:hover': {
                                    backgroundColor: "transparent",
                                },
                            }}
                        >
                            <MenuIcon />
                        </IconButton>
                    </Toolbar>
                </AppBar>
            ) : (
                <>
                    <AppBar
                        position="fixed"
                        sx={{ bgcolor: "#fff", boxShadow: "none", zIndex: 3 }}
                        elevation={trigger ? 24 : 0}
                        style={{
                            backgroundColor: "#fff",
                        }}
                    >
                        <Toolbar className={styles.toolbar}>
                            <a href="/">
                                <img
                                    onMouseEnter={handleClose}
                                    src= {process.env.PUBLIC_URL +"/assets/images/UniverseLogo.svg"}
                                    alt="Logo"
                                    className={trigger ? styles.logoSmall : styles.logo}
                                />
                            </a>
                            <div className={styles.centerContent}>
                                <div onMouseEnter={handleClose}>
                                    <StyledButton>
                                        <Link to="/">Home</Link>
                                    </StyledButton>
                                </div>
                                <Button
                                    ref={anchorRef}
                                    onMouseLeave={handleClose}
                                    aria-haspopup="true"
                                    onMouseEnter={handleToggle}
                                    sx={{
                                        fontFamily: 'Roie',
                                        color: "#737373",
                                        fontSize: '14px',
                                        lineHeight: '1.5',
                                        letterSpacing: '0.2px',
                                        textAlign: 'center',
                                        textTransform: 'capitalize',
                                        width: 'fit-content',
                                        height: '24px',
                                        '&:hover': {
                                            backgroundColor: "transparent",
                                        },
                                    }}
                                >
                                    Business Lines
                                </Button>
                                <div onMouseEnter={handleClose}>
                                    <StyledButton>
                                        <Link to="/contact">Contact Us</Link>
                                    </StyledButton>
                                </div>
                                <div onMouseEnter={handleClose}>
                                    <StyledButton>
                                        <Link to="/about">About</Link>
                                    </StyledButton>
                                </div>
                                {isSearching ? (
                                    <div>
                                        <TextField
                                            placeholder="Search Business Lines"
                                            InputProps={{
                                                sx: { height: '3rem' },
                                                style: customTextFieldStyle,
                                            }}

                                            label={
                                                click ? (
                                                    <img
                                                        src= {process.env.PUBLIC_URL +"/assets/images/Logoo.png"}
                                                        alt="Logo"
                                                        className={styles.imgg}
                                                    />
                                                ) : (
                                                    ''
                                                )
                                            }
                                            variant="outlined"
                                            onBlur={handleSearchClose}
                                            onClick={handleLabelClick}
                                            onKeyDown={handleEnterKeyPress}
                                            onChange={handleChange}
                                            value={inputValue}
                                            key={rerenderKey}
                                            className={errS ? styles.redTextfield : ''}
                                        />
                                    </div>
                                ) : (
                                    <IconButton
                                        color="inherit"
                                        style={{ color: '#737373' }}
                                        sx={{
                                            '&:hover': {
                                                backgroundColor: 'transparent',
                                            },
                                        }}
                                        onClick={handleSearchClick}
                                    >
                                        <SearchIcon className={styles.icon} />
                                    </IconButton>
                                )}
                            </div>
                        </Toolbar>
                    </AppBar>
                    <Popper
                        open={open}
                        anchorEl={anchorRef.current}
                        placement="top-start"
                        onMouseLeave={() => setOpen(false)}
                        ref={menuRef}
                        style={{ zIndex: 3 }}
                    >
                        <Paper
                            sx={{ background: '#fff', width: '160px' }}
                            onMouseEnter={() => setOpen(true)}
                        >
                            <MenuList autoFocus={false} id="menu-list-grow" onKeyDown={handleListKeyDown}>
                                {paths.map((path, index) => (
                                    <Link to={path} key={index}>
                                        <MenuItem
                                            onClick={handleClose}
                                            sx={{
                                                '&:hover': {
                                                    backgroundColor: backgroundColors[index],
                                                },
                                            }}
                                        >
                                            <DropdownItem imageSrc={menuItems[index].imageSrc} text={menuItems[index].text} />
                                        </MenuItem>
                                    </Link>
                                ))}
                            </MenuList>
                        </Paper>
                    </Popper>
                </>
            )}
            {isMobileView ? (
                <Drawer
                    anchor="right"
                    open={openn}
                    onClose={toggleDrawer(false)}
                    onOpen={toggleDrawer(true)}
                >
                    <Box sx={{
                        p: 5,
                        height: 1,
                        backgroundColor: "rgb(229, 232, 231, 0.2)",
                    }}>
                        <CloseIcon onClick={handleCloseIconClick} sx={{
                            cursor: 'pointer', marginLeft: "11rem", color: "#0B3782"
                        }}
                            onMouseEnter={(e) => (e.target.style.color = 'RGBA(4, 28, 86, 0.8)')}
                            onMouseLeave={(e) => (e.target.style.color = '#0B3782')} />
                        <p className={styles.paragraph} style={{ display: 'flex', alignItems: 'center' }}>
                            <HomeOutlinedIcon sx={{ marginRight: "10px", color: "#0B3782" }} />
                            <Link to="/" style={linkStyle}
                                onMouseEnter={(e) => (e.target.style.color = 'RGBA(4, 28, 86, 0.8)')}
                                onMouseLeave={(e) => (e.target.style.color = '#0B3782')}>Home</Link>
                        </p>
                        <p className={styles.paragraph} style={{ display: 'flex', alignItems: 'center' }}>
                            <div>
                                <img
                                    src= {process.env.PUBLIC_URL +"/assets/images/Logoo.png"}
                                    alt="Logo"
                                    width="25px"
                                    height="25px"
                                    style={{ marginRight: '10px' }}
                                />
                            </div>
                            <Link style={linkStyle}
                                onMouseEnter={(e) => (e.target.style.color = 'RGBA(4, 28, 86, 0.8)')}
                                onMouseLeave={(e) => (e.target.style.color = '#0B3782')}
                                onClick={() => setIsMenuVisible(!isMenuVisible)}
                            > Business Lines
                                {!isMenuVisible && (<ArrowForwardIosIcon sx={{ marginLeft: "3.5rem", color: "#0B3782", width: "15px", height: "10px" }} />)}
                                {isMenuVisible && (<KeyboardArrowDownIcon sx={{ marginLeft: "3.5rem", color: "#0B3782", width: "15px", height: "20px" }} />)}
                            </Link>
                        </p>
                        {isMenuVisible && (
                            <ul style={{ listStyleType: 'none', padding: 0 }}>
                                {menuItems.map((menuItem, index) => (
                                    <li
                                        key={index}
                                        style={{
                                            paddingLeft: '1.5rem',
                                            position: 'relative',
                                            marginLeft: '1rem',
                                            marginBottom: '2rem',
                                            fontSize: '12.5px',
                                            fontFamily: 'Prelia',
                                        }}
                                    >
                                        <Link
                                            to={paths[index]}
                                            style={{
                                                textDecoration: 'none',
                                                color: 'RGBA(4, 28, 86, 0.8)',
                                                transition: 'color 0.3s ease',
                                            }}
                                            onMouseEnter={(e) => (e.target.style.color = linkColors[index])}
                                            onMouseLeave={(e) => (e.target.style.color = '#0B3782')}
                                        >
                                            <div style={{display: "flex"}}>
                                            <img
                                                src={menuItem.imageSrc}
                                                alt={menuItem.text}
                                                style={{ marginLeft: isxMobileView ? '-2.5rem' : "1rem", marginRight: isxMobileView ? "-2.4rem" : "1rem", marginTop: "-0.7rem", paddingTop: "0.4rem" }}
                                                width="25px"
                                                height="25px"
                                            />
                                             {menuItem.text}
                                            </div>
                                               
                                        </Link>
                                    </li>
                                ))}
                            </ul>
                        )}
                        <p className={styles.paragraph} style={{ display: 'flex', alignItems: 'center' }}>
                            <ContactsOutlinedIcon sx={{ marginRight: "10px", color: "#0B3782" }} />
                            <Link to="/contact" style={linkStyle}
                                onMouseEnter={(e) => (e.target.style.color = 'RGBA(4, 28, 86, 0.8)')}
                                onMouseLeave={(e) => (e.target.style.color = '#0B3782')} >Contact Us</Link>
                        </p>
                        <p className={styles.paragraph} style={{ display: 'flex', alignItems: 'center' }}>
                            <InfoIcon sx={{ marginRight: "10px", color: "#0B3782" }} />
                            <Link to="/about" style={linkStyle}
                                onMouseEnter={(e) => (e.target.style.color = 'RGBA(4, 28, 86, 0.8)')}
                                onMouseLeave={(e) => (e.target.style.color = '#0B3782')} >About</Link>
                        </p>
                        <div>
                            <TextField
                                InputProps={{ sx: { height: '3rem' }, style: customTextFieldStyle, }}
                                label={
                                    click ? (
                                        <img
                                            src= {process.env.PUBLIC_URL +"/assets/images/Logoo.png"}
                                            alt="Logo"
                                            width="25px"
                                            height="25px"
                                        />
                                    ) : (
                                        ''
                                    )
                                }
                                placeholder='Search...'
                                variant="outlined"
                                onBlur={handleSearchClose}
                                onClick={handleLabelClick}
                                onKeyDown={handleEnterKeyPress}
                                onChange={handleChange}
                                value={inputValue}
                                key={rerenderKey}
                                className={errS ? styles.redTextfield : styles.textfield}
                            />

                        </div>
                        {!hideIcon && (
                            <StyledIcon color="#2B2A29" icon={<ExplicitIcon />} />
                        )}
                    </Box>
                </Drawer>
            ) : <></>}
        </Box>
    );
}

export default Navbar;
