import { Avatar, Box, Typography } from "@mui/material";
import React from "react";

function OurTeamList({ img, name, postion }) {
  return (
    <Box>
      <Avatar style={{ height: "100px", width: "100px" }}></Avatar>
      <Typography
        style={{
          color: "#0B3782",
          fontFamily: "Roie",
          fontSize: "14px",
          fontWeight: "400px",
          textAlign: "center",
        }}
      >
        {name}
      </Typography>
      <Typography
        style={{
          color: "#0B3782",
          fontFamily: "Roie",
          fontSize: "14px",
          fontWeight: "400px",
          textAlign: "center",
        }}
      >
        {postion}
      </Typography>
    </Box>
  );
}

export default OurTeamList;
