import React from 'react';
import DisplayData from '../DisplayData/DisplayData';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import Box from '@mui/material/Box';
import AddchartIcon from '@mui/icons-material/Addchart';
import AddLocationAltIcon from '@mui/icons-material/AddLocationAlt';
import RestoreIcon from '@mui/icons-material/Restore';
import styles from './UniverseInfo.module.css';
import { useMediaQuery } from "@mui/material";
import PersonAddAltOutlinedIcon from '@mui/icons-material/PersonAddAltOutlined';

const data = [
    {
        icon: <CalendarMonthIcon />,
        text: '16+',
        paragraphs: ['Years of Experience'],
    },
    {
        icon: <AddchartIcon />,
        text: '350+',
        paragraphs: ['Satisfied Clients'],
    },
    ,
    {
        icon: <PersonAddAltOutlinedIcon fontSize="large"/>,
        text: '15M+',
        paragraphs: ['Subscribers Base'],
    },
    ,
    {
        icon: <AddLocationAltIcon />,
        text: '2+',
        paragraphs: ['Offices'],
    },
];


export default function UniverseInfo() {
    const isSmallScreen = useMediaQuery("(max-width:950px)");
    const isxSmallScreen = useMediaQuery("(max-width:850px)");

    return (
        <div className={styles.toolbar}>
            {data.map((item, index) => (
                <div className={styles.containerBar} key={index}>
                    <Box sx={{ background: isxSmallScreen?"transparent":"#fff", marginLeft: isSmallScreen? '0px':'30px', marginRight: isSmallScreen?'0px':'30px', borderRadius: "8px", height: "12rem"}}>
                        <DisplayData
                            icon={item.icon}
                            text={item.text}
                            paragraph={item.paragraphs}
                        />
                    </Box>
                </div>
            ))}
        </div>
    );
}