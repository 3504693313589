import { React, useState } from "react";
import { useBlogs } from "../../Context/BlogContext";
import { Box, Typography } from "@mui/material";
import BlogCard from "../BlogCard/BlogCard";
import { Link } from "react-router-dom";
import styled from "@emotion/styled";

const BlogsBox = styled(Box)({
  display: "flex",
  flexWrap: "wrap",
  margin: "50px 150px",

  "@media (max-width: 600px)": {
    margin: "50px 30px",
  },
  "@media (max-width: 900px)": {
    margin: "50px 40px",
    justifyContent: "space-around",
  },
  gap: "45px",
});

const CustomText = styled(Typography)({
  margin: "8px auto",
  cursor: "pointer",
  color: "#3B82FD",
  fontFamily: "Prelia",
  fontSize: "20px",
  letterSpacing: "-0.72px",
  textDecorationLine: "underline",
});

const BlogsBottomSection = () => {
  const { filteredBlogs } = useBlogs();
  const [visibleBlogs, setVisibleBlogs] = useState(6);
  const [showAll, setShowAll] = useState(false);

  const toggleShowAll = () => {
    setShowAll(!showAll);
    if (showAll) {
      setVisibleBlogs(6);
    } else {
      setVisibleBlogs(filteredBlogs.length);
    }
  };

  return (
    <Box>
      <BlogsBox>
        {filteredBlogs?.length === 0 ? (
          <Typography
            margin={"50px auto 10px"}
            fontFamily={"Prelia"}
            fontSize={{ xs: "28px", sm: "40px" }}
            color={"#0B3782"}
          >
            No items found!
          </Typography>
        ) : (
          filteredBlogs?.slice(0, visibleBlogs).map((blog) => (
            <Link key={blog.id} to={`/blogs/${blog.id}`}>
              <BlogCard blog={blog} key={blog.id} />
            </Link>
          ))
        )}
      </BlogsBox>

      <Box textAlign={"center"} marginBottom={"93px"}>
        {filteredBlogs.length > 6 && (
          <CustomText onClick={toggleShowAll}>
            {showAll ? "View Less" : "View More"}
          </CustomText>
        )}
      </Box>
    </Box>
  );
};

export default BlogsBottomSection;
