import React from "react";
import WhyUniverseList from "../WhyUniverseList/WhyUniverseList";
import WhyUniverseImages from "../WhyUniverseImages/WhyUniverseImages";
import { Box, Container } from "@mui/material";

const WhyUniverseFirstSection = () => {
  return (
    <Box>
      <Container>
        <Box display={"flex"} justifyContent={"space-between"}>
          <WhyUniverseList />
          <WhyUniverseImages />
        </Box>
      </Container>
    </Box>
  );
};

export default WhyUniverseFirstSection;
