import React, { useEffect, useState } from "react";
import { Box, Typography } from "@mui/material";
import styled from "@emotion/styled";
import { motion } from "framer-motion";

const OuterContainer = styled(Box)(({ image }) => ({
  width: "780px",
  height: "550px",
  backgroundImage: `url(${image})`,
  backgroundSize: "cover",
  backgroundPosition: "center top -50px   ",
  position: "relative",

  "@media (max-width: 600px)": {
    display: "none",
  },
}));

const TextContainerr = styled(Box)({
  position: "absolute",
  bottom: 70,
  top: 40,
  left: 60,
  padding: "16px",
  color: "#0B3782",
});

const ListItemContent = ({ selectedItemText, image }) => {
  const [content, setContent] = useState(selectedItemText);
  useEffect(() => {
    setContent(selectedItemText);
  }, [selectedItemText]);

  const containerStyle = {
    textAlign: "justify",
    width: "fit-content"
  };

  // Convert selectedItemText to string before checking for the specific text
  const textToCheck = selectedItemText?.props?.children?.toString() || '';
  return (
    <motion.div
      key={content}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      style={containerStyle}
      transition={{ duration: 0.3 }}
    >
      <OuterContainer image={process.env.PUBLIC_URL +image}>
            <TextContainerr>
             <motion.div
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5 }}
              >
                <Typography
                  width={"570px"}
                  fontFamily={"Prelia"}
                  fontSize={"13.7px"}
                  lineHeight={"27px"}
                  letterSpacing={"0.2px"}
                >
                  {content}
                </Typography>
              </motion.div>
            </TextContainerr>
      </OuterContainer>
    </motion.div>
  );
};

export default ListItemContent;
