import { React, useState } from "react";
import { Box, List, ListItemButton, Typography } from "@mui/material";
import styled from "@emotion/styled";
import { motion } from "framer-motion";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";

const CustomListItemButton = styled(ListItemButton)(
  ({ color, backgroundColor }) => ({
    "&.Mui-selected": {
      backgroundColor: backgroundColor,
      borderLeft: `4px solid ${color}`,

      "&:hover": {
        backgroundColor: backgroundColor,
      },
    },
    "@media (max-width: 900px)": {
      borderRadius: "20px",
      borderTopLeftRadius: "0",
    },
    "@media (max-width: 600px)": {
      borderRadius: "20px",
      backgroundColor: backgroundColor,
      marginBottom: "5px",
      border: `1px solid ${color}`,
      justifyContent: "space-between",
    },
  })
);

const CustomList = styled(List)({
  "@media (max-width: 900px)": {
    display: "flex",
    flexWrap: "wrap",
    gap: "8px",
  },
  "@media (max-width: 600px)": {
    display: "block",
  },
});

const CustomContainer = styled(Box)({
  width: "340px",
  marginTop: "20px",
  "@media (max-width: 900px)": {
    width: "100%",
  },
});

const BusinessLineDetailsList = ({ onItemClick, listItems, color }) => {
  const [selectedIndex, setSelectedIndex] = useState(
    window.innerWidth > 600 ? 1 : null
  );

  const handleListItemClick = (itemID, content) => {
    setSelectedIndex(itemID);
    onItemClick(content);
  };

  const hexToRGBA = (color) => {
    const r = parseInt(color.slice(1, 3), 16);
    const g = parseInt(color.slice(3, 5), 16);
    const b = parseInt(color.slice(5, 7), 16);
    return `rgba(${r}, ${g}, ${b}, 0.12)`;
  };
  
  return (
    <CustomContainer>
      <CustomList>
        {listItems?.map(({ title, id, content }, index) => (
          <motion.div whileHover={{ x: 10 }}>
            <CustomListItemButton
              key={id}
              selected={selectedIndex === id}
              onClick={() => handleListItemClick(id, content)}
              color={color}
              backgroundColor={hexToRGBA(color)}
            >
              <Typography
                fontFamily={"Roie"}
                fontSize={"15px"}
                color={selectedIndex === id ? color : "#0B3782"}
                lineHeight={"24px"}
                padding={"10px"}
              >
                {`${index + 1}`} &nbsp;&nbsp;&nbsp; {title}
              </Typography>
              {window.innerWidth <= 600 ? (
                <ArrowForwardIcon sx={{ color: color }} />
              ) : null}
            </CustomListItemButton>
          </motion.div>
        ))}
      </CustomList>
    </CustomContainer>
  );
};

export default BusinessLineDetailsList;
