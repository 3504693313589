import React from "react";
import styles from "./OurVision.module.css";
import OurVisionList from "./OurVisionList";

const list = [
  {
    img: process.env.PUBLIC_URL + "/assets/images/greenSquare.svg",
    title: "Our Vision",
    text: "Provide what humanity deserve.",
  },
  {
    img: process.env.PUBLIC_URL + "/assets/images/Square.svg",
    title: "Our Mission",
    text: "We employ state-of-the-art technologies and utilize our cutting-edge skills with our professionals to provide the technology you deserve.",
  },
  {
    img: process.env.PUBLIC_URL + "/assets/images/PurpleSqaure.svg",
    title: "Our Promise",
    text: "We believe that humanity deserves a bright future. Therefore, changing human life for the best is our responsibility.",
  },
];

function OurVision() {
  return (
    <div className={styles.container}>
      {list.map((item) => {
        return (
          <OurVisionList img={item.img} title={item.title} text={item.text} />
        );
      })}
    </div>
  );
}

export default OurVision;
