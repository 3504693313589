import React from "react";
import Layout from "../../Components/Common/Layout";
import PrivacyPolicy from "../../Components/PrivacyPolicy/PrivacyPolicy";

const PrivacyPolicyPage = () => {
    return (
        <Layout>
           <PrivacyPolicy />
        </Layout>
    );
};

export default PrivacyPolicyPage;