import React, { useEffect, useRef } from "react";
import { useParams } from "react-router-dom";
import Layout from "../../Components/Common/Layout";
import HeroImageBusinessLines from "../../Components/HeroImageBusinessLines/HeroImageBusinessLines";
import BusinessLineDetails from "../../Components/BusinessLineDetails/BusinessLineDetails";
import BusinessLineSolutions from "../../Components/BusinessLineSolutions/BusinessLineSolutions";
import { universeTechnologiesLineData } from "../../Data/universeTechnologiesDetailsData";
import { universeTechnologiesSolutionsData } from "../../Data/universeTechnologiesSolutionsData";
import RequestDemo from "../../Components/RequestDemo/RequestDemo";

const TechnologiesPage = () => {
  const { flag } = useParams();
  const smartRef = useRef(null);
  const hardwareRef = useRef(null);
  const requestRef = useRef(null);

  useEffect(() => {
    if (smartRef.current && flag === "smart")
      smartRef.current.scrollIntoView({ behavior: "smooth" });
    else if (hardwareRef.current && flag === "hardware")
      hardwareRef.current.scrollIntoView({ behavior: "smooth" });
    else if (requestRef.current && flag === "request")
      requestRef.current.scrollIntoView({ behavior: "smooth" });
  }, [flag]);

  const technologiesText =
    "We are dedicated to helping businesses leverage the latest and most innovative technologies to drive growth, efficiency, and success by delivering top-notch solutions tailored to your unique needs.";

  return (
    <Layout>
      <HeroImageBusinessLines
        image={process.env.PUBLIC_URL + "/assets/images/green.svg"}
        imageSrc= {process.env.PUBLIC_URL +"/assets/images/TechnologiesFinal.svg"}
        text={technologiesText}
      />
      <div ref={smartRef}>
        <BusinessLineDetails
          color={"#39abd7"}
          data={universeTechnologiesLineData}
          image="/assets/images/Technologies-Details-background.jpg"
          title={"Smart Systems"}
        />
      </div>
      <div ref={hardwareRef}>
        <BusinessLineSolutions
          color={"#39abd7"}
          title={"Hardware Supplies"}
          data={universeTechnologiesSolutionsData}
        />
      </div>
      <div ref={requestRef}>
        <RequestDemo
          backgroundImageUrl= {process.env.PUBLIC_URL +"/assets/images/WAVES.svg"}
          title_first="Ready to Elevate Your Technology?"
          title_second="Request a Demo Today!"
        />
      </div>
    </Layout>
  );
};

export default TechnologiesPage;
