import React, { useState, useEffect } from 'react';
import styles from "./PageLoader.module.css";

function PageLoader({ loadingImage }) {
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const timeout = setTimeout(() => {
            setLoading(false);
        }, 1800);

        return () => {
            clearTimeout(timeout);
        };
    }, []);

    if (loading) {
        return (
            <div className={styles.pageLoader}>
                <div className={styles.imageContainer}>
                    <img src={loadingImage} alt="Loading" className={styles.innerImage} />
                    <img src={process.env.PUBLIC_URL +'/assets/images/other.svg'} className={styles.outerImage} alt="Loading"/>
                </div>
            </div>
        );
    }

    return null;
}

export default PageLoader;
