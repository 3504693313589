import { Typography, Box } from "@mui/material";
import React from "react";

const BlogPublishingInfo = ({ IconName, text }) => {
  return (
    <Box display={"flex"} justifyContent={"space-between"} marginRight={"20px"}>
      <Box color={"#0B3782"}>
        <IconName fontSize={"small"} color={"inherit"} />
      </Box>

      <Typography
        color={"#0B3782"}
        fontSize={"14px"}
        fontFamily={"Prelia"}
        textTransform={"capitalize"}
        marginLeft={"5px"}
      >
        {text}
      </Typography>
    </Box>
  );
};

export default BlogPublishingInfo;
