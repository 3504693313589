import React from "react";
import { Typography, Box } from "@mui/material";

export default function JobSecondary(props) {
  const { text, paragraph } = props;
  const paragraphParts = paragraph.split("@@@");

  return (
    <div>
      <Box>
        <Typography
          sx={{
            fontFamily: "Roie",
            fontSize: "13px",
            fontWeight: 400,
            lineHeight: "24px",
            letterSpacing: "0em",
            textAlign: "left",
            color: "#0B3782",
            marginTop: "2.5rem",
          }}
        >
          {text}
        </Typography>
        <Box>
          {paragraphParts.map((part, index) => (
            <Typography
              key={index}
              sx={{
                fontFamily: "Prelia",
                fontSize: "14px",
                fontWeight: 400,
                lineHeight: "24px",
                letterSpacing: "0em",
                textAlign: "left",
                color: "#0B3782",
              }}
            >
              {part}
            </Typography>
          ))}
        </Box>
      </Box>
    </div>
  );
}
