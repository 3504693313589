import React, { useState, useRef, useEffect } from "react";
import axios from "axios";
import { Typography, Box, Button, TextField } from "@mui/material";
import { Link } from "react-router-dom";
import ArrowBackIosNewOutlinedIcon from "@mui/icons-material/ArrowBackIosNewOutlined";
import { styled } from "@mui/system";
import styles from "./JobDetail.module.css";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import JobSecondary from "../JobSecondary/JobSecondary";
import { Alert } from "@mui/material";
import { useMediaQuery } from "@mui/material";
import config from "../../config";

const apiUrl = config.urls.apiUrl;

const customTextFieldStyle = {
  width: "fit-content",
  height: "3rem",
  marginRight: "3rem",
  color: "#0B3782"
};

const BackIconContainer = styled(Box)({
  backgroundColor: "#FFF",
  borderRadius: "50%",
  boxShadow: "0 0 5px lightgray",
  width: "20px",
  height: "20px",
  padding: "5px",
  marginTop: "1.5px",
});
const customInputLabelStyle = {
  fontFamily: "Prelia",
  fontSize: "14px",
  fontWeight: 400,
  lineHeight: "18px",
  letterSpacing: "0em",
  textAlign: "left",
  color: "#0B3782",
  padding: "8px 0",
  marginTop: "15px",
};
export default function JobDetail({
  id,
}) {
  const [jobTitle, setJobTitle] = useState('');
  const [jobCategory, setJobCategory] = useState('');
  const [jobDescription, setJobDescription] = useState('');
  const [jobDetails, setJobDetails] = useState('');
  const [jobQualifications, setJobQualifications] = useState('');
  const [jobDate, setJobDate] = useState('');
  const [jobsData, setJobsData] = useState([]);
  let str;
  let colorLabel = "";

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${apiUrl}/api/jobs/GetAllJobs`);
        const data = response.data;
        setJobsData(data);
        const filteredData = id ? data.find((job) => job.id === parseInt(id)) : null;
        if (filteredData) {
          setJobTitle(filteredData.title);
          setJobCategory(filteredData.category);
          setJobDescription(filteredData.description);
          setJobDetails(filteredData.jobDetails);
          setJobQualifications(filteredData.qualifications);
          setJobDate(filteredData.createdAt);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []); 

  if (jobCategory.toLowerCase() === "telecom") {
    colorLabel = "#A25CDD";
  } else if (jobCategory.toLowerCase() === "software") {
    colorLabel = "#6CCBB4";
  } else if (jobCategory.toLowerCase() === "technology") {
    colorLabel = "#5CA9D3";
  } else if (jobCategory.toLowerCase() === "e-marketing") {
    colorLabel = "#4B82F7";
  }

  let date = jobDate.split("T");
  let dateParts = date[0].split("-");

  str = dateParts[2] + " ";
  switch (dateParts[1]) {
    case "01":
      str += "January, ";
      break;
    case "02":
      str += "February, ";
      break;
    case "03":
      str += "March, ";
      break;
    case "04":
      str += "April, ";
      break;
    case "05":
      str += "May, ";
      break;
    case "06":
      str += "June, ";
      break;
    case "07":
      str += "July, ";
      break;
    case "08":
      str += "August, ";
      break;
    case "09":
      str += "September, ";
      break;
    case "10":
      str += "October, ";
      break;
    case "11":
      str += "November, ";
      break;
    case "12":
      str += "December, ";
      break;
    default:
      str += "Invalid Month, ";
      break;
  }
  str += dateParts[0];

  const [name, setName] = useState("Upload Your CV");
  const [selectedFile, setSelectedFile] = useState(null);
  const [isPdfInvalid, setIsPdfInvalid] = useState(false);
  const [success, setSuccess] = useState(false);
  const [isPdf, setIsPdf] = useState(true);
  const [cFlag, setCFlag] = useState(false);
  const toggleFileInput = () => {
    setCFlag(true);
  };
  const fileInputRef = useRef(null);
  const handleFileChange = (event) => {
    const selected = event.target.files[0];
    if (selected) {
      if (selected.type === "application/pdf") {
        setSelectedFile(selected);
        setName("Submit Yout CV");
        setIsPdfInvalid(false);
        setIsPdf(false);
      } else {
        setIsPdfInvalid(true);
        setSelectedFile(null);
        if (fileInputRef.current) {
          fileInputRef.current.value = "";
        }
        setTimeout(() => {
          setIsPdfInvalid(false);
        }, 3000);
      }
    }
  };

  const [namee, setNamee] = useState("");
  const [nameeError, setNameeError] = useState("");
  const handleNameChange = (event) => {
    const newName = event.target.value;
    setNameeError("");
    setNamee(newName);
  };

  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState("");
  const handleEmailChange = (event) => {
    const newEmail = event.target.value;
    setEmailError("");
    setEmail(newEmail);
  };
  const isSmallScreen = useMediaQuery("(max-width:858px)");
  const isxSmallScreen = useMediaQuery("(max-width:400px)");

  const onSubmit = async (event) => {
    if (cFlag) event.preventDefault();
    const newEmail = email;
    const newName = namee;

    if (!/^[A-Za-z\s]+$/.test(newName)) {
      setNameeError("Only letters are allowed");
    } else {
      setNameeError("");
    }

    if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(newEmail)) {
      setEmailError("Please enter a valid email address");
    } else {
      setEmailError("");
    }

    try {
      if (isPdfInvalid) {
        setTimeout(() => {
          setIsPdfInvalid(false);
        }, 3000);
      } else if (cFlag && selectedFile) {
        console.log("File uploaded successfully" + selectedFile.type);
        if (
          /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(newEmail) &&
          /^[A-Za-z\s]+$/.test(newName)
        ) {
          const backendUrl = apiUrl;
          const formData = new FormData();
          formData.append("cv", selectedFile);
          formData.append("name", newName);
          formData.append("email", newEmail);

          fetch(`${backendUrl}/api/email/send-job`, {
            method: "POST",
            body: formData,
          })
            .then((response) => {
              if (!response.ok) {
                throw new Error("Network response was not ok");
              }
              return response.json();
            })
            .then((data) => {
              console.log(data);
            })
            .catch((error) => {
              console.error("Error:", error);
            });
          setEmail("");
          setNamee("");
          setSelectedFile(null);
          setName("Upload Your CV");
          if (fileInputRef.current) {
            fileInputRef.current.value = "";
          }
          setSuccess(true)
          setTimeout(() => {
            setSuccess(false);
          }, 3000);
        }
      }
    } catch (error) {
      console.error("Error uploading file", error);
    }
    setCFlag(true);
  };

  return (
    <div>
      <Box
        display={"flex"}
        style={{
          margin: isxSmallScreen ? "8.4rem 5rem 0 2rem" : "8.4rem 5rem 0 5rem",
        }}
      >
        <Link to="/jobspage">
          <BackIconContainer>
            <ArrowBackIosNewOutlinedIcon fontSize="small" color="disabled" />
          </BackIconContainer>
        </Link>
        <Typography
          color={"rgba(0, 0, 0, 0.54)"}
          fontFamily={"Prelia"}
          fontSize={"15px"}
          lineHeight={"24px"}
          marginLeft={"8px"}
          marginTop={"5px"}
        >
          <Box display="flex" alignItems="center">
            <Link to="/jobspage" className={styles.pathSpan}>
              Jobs
            </Link>
            <Typography
              color="rgba(0, 0, 0, 0.54)"
              fontFamily="Prelia"
              fontSize="15px"
              lineHeight="24px"
              marginLeft="8px"
              marginTop="2px"
            >
              / Job Details
            </Typography>
          </Box>
        </Typography>
      </Box>
      <Box
        style={{
          margin: isxSmallScreen ? "1.4rem 2rem 0 2rem" : "1.4rem 5rem 0 5rem",
        }}
      >
        <Typography
          fontFamily="Roie"
          fontSize="32px"
          lineHeight="22px"
          marginLeft="8px"
          marginTop="2px"
          fontWeight={"400"}
          letterSpacing={"0em"}
          color="#0B3782"
        >
          {jobTitle}
        </Typography>
      </Box>
      <Box
        style={{ margin: isxSmallScreen ? "0 5rem 0 2rem" : "0 5rem 0 5rem" }}
      >
        <Typography
          color={"rgba(0, 0, 0, 0.54)"}
          fontFamily={"Prelia"}
          fontSize={"15px"}
          lineHeight={"24px"}
          marginLeft={"8px"}
          marginTop={"5px"}
        >
          <Box
            display="flex"
            alignItems="center"
            style={{ marginTop: "0.2rem" }}
          >
            <CalendarMonthIcon />
            <p className={styles.parag}>Posted: </p>
            <p className={styles.dateparag}>{str}</p>
            <Button
              variant="contained"
              style={{
                //fixed color
                backgroundColor: colorLabel,
                borderRadius: "10px",
                fontFamily: "Prelia",
                fontSize: isxSmallScreen ? "8px" : "11px",
                fontWeight: 400,
                lineHeight: "16px",
                letterSpacing: "0em",
                textAlign: "left",
                padding: "4px, 16px, 4px, 12px",
                height: "24px",
                width: "fit-content",
                textTransform: "capitalize",
                marginLeft: "30px",
              }}
            >
              {jobCategory}
            </Button>
          </Box>
        </Typography>
        <Box style={{ marginTop: "2.4rem" }}>
          <Typography
            color={"rgba(0, 0, 0, 0.54)"}
            fontFamily={"Prelia"}
            fontSize={"15px"}
            lineHeight={"24px"}
            marginLeft={"8px"}
            marginTop={"5px"}
          >
            <JobSecondary text="Job Description" paragraph={jobDescription} />
            <JobSecondary
              text="Job Qualifications"
              paragraph={jobQualifications}
            />
            <JobSecondary text="Job Details" paragraph={jobDetails} />
          </Typography>
        </Box>
      </Box>
      <Box
        style={{
          textAlign: "center",
          marginTop: "2rem",
          alignItems: isSmallScreen?"center":0,
        }}
        className={styles.boxContainer}
      >
        <form onSubmit={onSubmit}>
          <TextField
            sx={{ bgcolor: "#fff", borderRadius: "4px" }}
            id="name"
            variant="outlined"
            value={namee}
            InputLabelProps={{
              style: customInputLabelStyle,
            }}
            InputProps={{
              style: customTextFieldStyle,
            }}
            placeholder="Enter your name"
            name="name"
            required
            error={Boolean(nameeError)}
            helperText={nameeError}
            onChange={handleNameChange}
          />
          <TextField
            sx={{
              bgcolor: "#fff",
              borderRadius: "4px",
              marginTop: isSmallScreen ? "2rem" : 0,
            }}
            id="email"
            variant="outlined"
            value={email}
            InputLabelProps={{
              style: customInputLabelStyle,
            }}
            InputProps={{
              style: customTextFieldStyle,
            }}
            placeholder="Your Email"
            name="email"
            required
            error={Boolean(emailError)}
            helperText={emailError}
            onChange={handleEmailChange}
          />
          {cFlag && (
            <input
              type="file"
              name="cv"
              onChange={handleFileChange}
              ref={fileInputRef}
            />
          )}
          <button className={styles.btns} onClick={toggleFileInput}>
            {name}
          </button>
        </form>
        <Box
          style={{
            margin: isSmallScreen
              ? "1.4rem  5rem 0 2rem"
              : "1.4rem 5rem 0 5rem",
            width: isSmallScreen ? "50%" : "78%",
          }}
        >
          {isPdfInvalid && (
            <Alert severity="error">Please select a PDF file.</Alert>
          )}
          {success && (
            <Alert severity="success">CV submitted successfully.</Alert>
          )}
        </Box>
      </Box>
    </div>
  );
}
