import React, { useEffect } from "react";
import { BrowserRouter, Route, Routes, useLocation } from "react-router-dom";
import HomePage from "./Pages/HomePage";
import ContactUsPage from "./Pages/ContactUsPage";
import AboutUsPage from "./Pages/AboutUsPage";
import TelecomPage from "./Pages/TelecomPage";
import TechnologiesPage from "./Pages/TechnologiesPage";
import SoftwarePage from "./Pages/SoftwarePage";
import MarketingPage from "./Pages/MarketingPage";
import BlogsNewsletterDetailsPage from "./Pages/BlogsNewsletterDetailsPage";
import BlogsNewsletterPage from "./Pages/BlogsNewsletterPage";
import JobsPage from "./Pages/JobsPage";
import WhyUniverse from "./Pages/WhyUniverse";
import BlogProvider from "./Context/BlogContext";
import JobsDetailsPage from "./Pages/JobsDetailsPage";
import NotFoundPage from "./Pages/NotFoundPage"
import PrivacyPolicyPage from "./Pages/PrivacyPolicyPage";

function ScrollToTopOnNavigate() {
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  return null;
}

function App() {
  return (
    <BlogProvider>
      <BrowserRouter basename={process.env.PUBLIC_URL}>
        <ScrollToTopOnNavigate />
        <Routes>
          <Route path="/" element={<HomePage />} />
          {/*  add your pages here */}
          <Route path="/contact/:flag?" element={<ContactUsPage />} />
          <Route path="/about" element={<AboutUsPage />} />
          <Route path="/telecom/:flag?" element={<TelecomPage />} />
          <Route path="/technologies/:flag?" element={<TechnologiesPage />} />
          <Route path="/software/:flag?" element={<SoftwarePage />} />
          <Route path="/marketing/:flag?" element={<MarketingPage />} />
          <Route path="/whyuniverse" element={<WhyUniverse />} />
          <Route path="jobspage" element={<JobsPage />} />    
          <Route
            path="/jobsdetails/:id"
            element={<JobsDetailsPage />}
          />
          <Route path="/blogs/:id" element={<BlogsNewsletterDetailsPage />} />
          <Route path="/privacypolicy" element={<PrivacyPolicyPage />} />
          <Route path="/blogs" element={<BlogsNewsletterPage />} />
          <Route path="*" element={<NotFoundPage />} />
        </Routes>
      </BrowserRouter>
    </BlogProvider>
  );
}

export default App;
