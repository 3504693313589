import React from "react";
import { Container, Typography, Box } from "@mui/material";
import BusinessSectionTitle from "../BusinessSectionTitle/BusinessSectionTitle";
import ServiceInfo from "./ServiceInfo";
import styled from "@emotion/styled";

const InfoBox = styled(Box)((props) => ({
  display: "flex",
  justifyContent: "space-between",
  margin: "30px 0px",
  "@media (max-width: 900px)": {
    flexDirection: props.reverse ? "column-reverse" : "column",
    margin: "30px 0px ",
  },
}));

const Services = () => {
  return (
    <Box sx={{ margin: "30px 0px 0px" }}>
      <Container sx={{textAlign: "justify"}}>
        <BusinessSectionTitle title={"Services"} />
        <Typography
          fontFamily={"Prelia"}
          fontSize={"16px"}
          lineHeight={"26px"}
          letterSpacing={"0.2px"}
          margin={{ xs: "10px 0px", md: "20px 0px" }}
          color={"#0B3782"}
        >
          At Universe, our Software Solutions Department stands at the forefront of technological innovation. Our passionate and skilled professionals are dedicated to crafting cutting-edge software solutions that empower businesses and individuals alike. Ensuring we deliver to deliver tailored solutions that perfectly align with your needs.
        </Typography>

        <Box display={"flex"} gap={10} >
          <Box marginTop={"0px"} padding={"1px"}>
          <InfoBox >
          <ServiceInfo
            title={"Software development"}
            text={
              "Building customized software solutions that is aligned with your business objectives by creating scalable, user-friendly, and secure software."
            }
          />
        </InfoBox>

        <InfoBox>
          <Box marginTop={"40px"}>
          <ServiceInfo
            title={"Mobile App & Web Development"}
            text={
              "Our professionals combine creativity with functionality to stay ahead of providing intuitive webs. Besides our top-notch mobile app development services."
            }
          />
          </Box>
        
        </InfoBox>

          </Box >
          <Box height={"520px"} marginLeft={"20px"}>
            <img src={process.env.PUBLIC_URL +'/assets/images/Service.svg'} style={{height:"100%"}}></img>
          </Box>
        </Box>

      
      </Container>
    </Box>
  );
};

export default Services;
