import React from "react";
import { Box, Container, Typography } from "@mui/material";
import BusinessSectionTitle from "../BusinessSectionTitle/BusinessSectionTitle";
import styles from "./Ecommerce.module.css";
import styled from "@emotion/styled";

const ECommerceBox = styled(Box)({
  position: "relative",
  marginBottom: "50px",
  "&::before": {
    content: '""',
    position: "absolute",
    top: "10rem",
    bottom: 0,
    left: "250px",
    right:"50px",
    backgroundImage: `url("${process.env.PUBLIC_URL}/assets/images/WAVES.svg")`,
    backgroundPosition: "center",
    backgroundSize: "100%",
    backgroundRepeat: "no-repeat",
    zIndex: -1,
    transform: "scaleX(-1) rotate(-20deg)",
  },
  "@media (max-width: 1100px)": {
    "&::before": {
      display:"none"
    },
  },

});


const ECommerce = () => {
  const listItems = [
    "Services we offer.",
    "Consultation and Planning.",
    "E-commerce Platform Selection.",
    "Store Setup and Design.",
    "Payment Gateway Integration.",
    "Shipping and Fulfillment Setup.",
    "Security and Data Protection.",
    "Mobile Optimization.",
    "Analytics and Performance Monitoring.",
    "Customer Support Integration.",
    "Continuous Support and Maintenance.",
  ];

  return (
    <ECommerceBox>
      <Container>
        <BusinessSectionTitle title={"E-commerce"} />
        <Typography
          fontFamily={"Prelia"}
          fontSize={{ xs: "14px", sm: "16px" }}
          lineHeight={{ xs: "30px", sm: "44px" }}
          letterSpacing={"0.1px"}
          color={"#0B3782"}
        >
          We are here to help you in setting up and managing your online stores
          to sell products or services over the internet.
        </Typography>

        <Box sx={{ margin: "20px" }}>
          <ul>
            {listItems.map((item, index) => (
              <li key={index}>
                <p className={styles.listItem}> {item}</p>
              </li>
            ))}
          </ul>
        </Box>
      </Container>
    </ECommerceBox>
  );
};

export default ECommerce;
