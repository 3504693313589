import React from 'react';
import styles from './CustomFooter.module.css';
import { Button, useMediaQuery } from '@mui/material';
import { Link } from 'react-router-dom';

function CustomFooter({ title, buttonText }) {
  const isSmallScreen = useMediaQuery("(max-width:800px)");
  return (
    <div className={styles.customComponent}>
      <div className={styles.content}>
        <h3 className={styles.title}>{title}</h3>
      </div>
      <Link to="/contact">
        <Button
          sx={{
            bgcolor: "#4B82F7",
            marginTop: "3%",
            fontFamily: 'Prelia',
            fontSize: isSmallScreen?'12px':'14px',
            fontWeight: '400',
            width: 'fit-content',
            lineHeight: '28px',
            letterSpacing: '0.20000000298023224px',
            textAlign: 'center',
            textTransform: 'none',
  
          }}
          variant="contained"
        >
          {buttonText}
        </Button>
      </Link>
    </div>
  );
}

export default CustomFooter;