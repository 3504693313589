import React from "react";
import DisplayData from "../DisplayData/DisplayData";
import Styles from "./AboutUniverseInfo.module.css";
import { Box, useMediaQuery } from "@mui/material";

const data = [
  {
    text: "1B+",
    paragraphs: ["Transactions"],
  },
  {
    text: "15M+",
    paragraphs: ["Subscribers Base"],
  },
  {
    text: "600+",
    paragraphs: ["Products and Services"],
  },
  {
    text: "350+",
    paragraphs: ["Happy Clients"],
  },
  {
    text: "100+",
    paragraphs: ["Connectivity & Business Partners"],
  },
  {
    text: "16+",
    paragraphs: ["Years in Business"],
  },
  {
    text: "2+",
    paragraphs: ["Offices"],
  },
];

function AboutUniverseInfo() {
  const isSmallScreen = useMediaQuery("(max-width:800px)");

  return (
    <Box
      marginTop={"100px"}
      marginLeft={isSmallScreen ? "2rem" : "50px"}
      marginRight={"55px"}
      className={Styles.toolbar}
      style={{
        position: 'relative',
      }}
      marginBottom={"17rem"}
    >
      <div className={Styles.toolbar}>
        {data.map((item, index) => (
          <div className={Styles.containerBar} key={index}>
            <DisplayData
              icon={item.icon}
              text={item.text}
              paragraph={item.paragraphs}
              color={"#0B3782"}
            />
          </div>
        ))}
      </div>
    </Box>
  );
}

export default AboutUniverseInfo;