import { Box, Typography, useMediaQuery } from "@mui/material";
import React from "react";
const ourStoryText =
  "Universe Telecom (UT) was born in 2007, in a small office located at a very crowded area in the city center with a small crew with the belief that humanity deserves a bright future, and with the aim of changing human life to the best. We decided to put our fingerprints in the telecommunication and IT services field, therefore we worked day and night with minds full of passion and love, defied the odds, and overcame barriers to achieve our goal and be a distinctive addition to this industry by providing humanity outstanding services. We succeeded to be the first licensed company in Palestine in that field and moved forward to build business partnerships with local and international companies in many sectors. We have fulfilled our promise and had a quantum leap by using revolutionary technologies, UT has a significant role in bringing the DCB technology which leads to achieving partnerships with international brands. Nowadays, UT has made that ambition a reality and become Universe with 4 business units under this brand. We are now opening our new headquarter with spacious office accommodation by which UT can emanate towards expanding globally with many brilliant minds working at the company to provide humanity what deserves.";

const containerStyle = {
  textAlign: "justify",
  width: "fit-content",
};

export default function OurStory() {
  const isSmallScreen = useMediaQuery("(max-width:800px)");

  return (
    <Box>
      <Box
        marginTop={{ xs: "11rem", md: "2rem" }}
        position={"relative"}
      >
        <Typography
          textAlign={"center"}
          fontFamily={"Qualy"}
          fontSize={isSmallScreen ? "25px" : "35px"}
          color={"#0B3782"}
          marginTop={{ xs: "20px", md: "50px" }}
        >
          Our Story
        </Typography>
        <Typography
          color={"#0B3782"}
          fontFamily={"Prelia"}
          fontSize={isSmallScreen ? "12px" : "18px"}
          height={"fit-content"}
          margin={isSmallScreen ? "22px 1rem" : "22px 100px"}
          letterSpacing={"0.72px"}
          lineHeight={"40px"}
          textAlign={"left"}
          style={containerStyle}
        >
          {ourStoryText}
        </Typography>
      </Box>
    </Box>
  );
}
