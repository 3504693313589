import React from 'react';
import ClickableCard from '../ClickableCard/ClickableCard';
import styles from './CardList.module.css';

export default function CardList({ title, data, links }) {
    const colors = ['rgba(162, 92, 221, 0.8)', 'rgba(108, 203, 180, 0.8)', 'rgba(92, 169, 211, 0.8)', 'rgba(75, 130, 247, 0.8)'];
    const titles = ['Telecom', 'Software Solutions', 'Technologies', 'E-Marketing'];

    return (
        <div className={styles.container}>
            <p className={styles.paragraph}>{title}</p>
            <div className={styles.cardList}>
                {data.map((item, index) => (
                    <ClickableCard
                        cardColor={colors[index % colors.length]} 
                        title={titles[index % titles.length]} 
                        key={index}
                        imageSrc={item.imageSrc}
                        link={links[index]}
                        className={styles.card}
                    />
                ))}
            </div>
        </div>
    );
}