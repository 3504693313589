import { Typography, Card, IconButton, Stack } from "@mui/material";
import { React } from "react";
import CloseIcon from "@mui/icons-material/Close";
import styled from "@emotion/styled";
import { motion, AnimatePresence } from "framer-motion";

const LargeCardBox = styled(Card)({
  height: "566px",
  backgroundColor: "rgba(255,255,255,0.9)",
  borderRadius: "8px",
  padding: "1px",
  color: "#0B3782",
  margin: "40px 0px",
});

const SolutionLargeCard = ({ onCloseClick, selectedCard }) => {
  return (
    <AnimatePresence>
      <motion.div
        initial={{ opacity: 0, scale: 0.5 }}
        animate={{ opacity: 1, scale: 1 }}
        exit={{ opacity: 0, scale: 0.5 }}
        transition={{
          duration: 0.3,
          ease: [0, 0.71, 0.2, 1.001],
          scale: {
            type: "spring",
            damping: 5,
            stiffness: 100,
            restDelta: 0.001,
          },
        }}
      >
        <LargeCardBox>
          <Stack
            direction={"row"}
            justifyContent={"space-between"}
            margin={"20px 10px 20px 20px"}
          >
            <Typography
              padding={"10px"}
              fontFamily={"Roie"}
              fontSize={"20px"}
              lineHeight={"24px"}
            >
              Test
            </Typography>
            <IconButton
              sx={{ width: "45px", color: "#1C1D22" }}
              onClick={onCloseClick}
            >
              <CloseIcon />
            </IconButton>
          </Stack>

          <Typography margin={"20px"}>{selectedCard.text}</Typography>
        </LargeCardBox>
      </motion.div>
    </AnimatePresence>
  );
};

export default SolutionLargeCard;
