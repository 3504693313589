import React from "react";
import Navbar from "../Navbar/Navbar";
import Footer from "../Footer/Footer";
import CustomFooter from "../CustomFooter/CustomFooter";
import FooterAppendix from "../FooterAppendix/FooterAppendix";

function Layout({ children }) {
  return (
    <div>
      {/*  in case u have to show the "en" icon just pass -false- to the hideIcon */}
      <Navbar hideIcon={true} />
      {children}
      <CustomFooter
        title="Let’s talk business, don’t hesitate to contact our experts."
        buttonText="Contact Us"
      />
      <Footer />
      <FooterAppendix />
    </div>
  );
}

export default Layout;
