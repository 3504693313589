import React from "react";
import Layout from "../../Components/Common/Layout";
import BlogsTopSection from "../../Components/BlogsTopSection/BlogsTopSection";
import BlogsBottomSection from "../../Components/BlogsBottomSection/BlogsBottomSection";

const BlogsNewsletterPage = () => {
  return (
    <Layout>
      <BlogsTopSection />
      <BlogsBottomSection />
    </Layout>
  );
};

export default BlogsNewsletterPage;
