import React from "react";
import {
  Button,
  Card,
  CardContent,
  Typography,
  Box,
  CardActions,
  IconButton,
  Divider,
} from "@mui/material";
import styled from "@emotion/styled";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { motion } from "framer-motion";

const StyledSolutionCard = styled(Card)({
  width: "372px",
  height: "285px",
  borderRadius: "8px",
  backgroundColor: "rgba(255,255,255, .9)",
  color: "#0B3782",
  position: "relative",
  marginBottom: "20px",
  "@media (max-width: 800px)": {
    width: "350px",
  },
  "@media (max-width: 600px)": {
    width: "372px",
    margin: "0px auto",
  },
});

const CardActionsBox = styled(CardActions)({
  position: "absolute",
  bottom: 0,
  width: "100%",
  borderTop: "1px solid #E9EAEB",
});

const CardActionContainer = styled(Box)({
  width: "90%",
  justifyContent: "space-between",
  display: "flex",
});

const SolutionCard = ({ cardData, setSelectedCard, color }) => {
  const { title, text } = cardData;

  const handleLearnMoreClick = () => {
    setSelectedCard(cardData);
  };

  return (
    <StyledSolutionCard
      component={motion.div}
      whileHover={{ scale: 1.03, boxShadow: `0px 4px 10px ${color}` }}
      whileTap={{ scale: 0.8 }}
    >
      <CardContent>
        <Typography
          variant="h4"
          fontFamily="Roie"
          fontSize={{ xs: "15px", sm: "24px" }}
          lineHeight={"24px"}
          margin={"15px 2px"}
          color={"#0B3782"}
        >
          {title}
        </Typography>

        <Typography
          fontFamily="Prelia"
          fontSize={{ xs: "12px", sm: "16px" }}
          lineHeight={"24px"}
          marginTop={"10px"}
          color={"#0B3782"}
        >
          {text}
        </Typography>
      </CardContent>

      {/* <CardActionsBox>
        <CardActionContainer>
          <Button
            fontFamily="Prelia"
            sx={{ color: { color }, fontSize: "12px" }}
          >
            Learn More
          </Button>

          <IconButton sx={{ width: "45px" }}>
            <ArrowForwardIcon sx={{ padding: "3px 0px", color: { color } }} />
          </IconButton>
        </CardActionContainer>
      </CardActionsBox> */}
    </StyledSolutionCard>
  );
};

export default SolutionCard;
