export const universeTechnologiesSolutionsData = [
  {
    id: 1,
    title: "Virtual Reality",
    text: "Our VR hardware is more than just a technology; it’s a gateway to a universe of possibilities. We are dedicated to pushing the boundaries of VR technology and delivering the most immersive, cutting-edge hardware which are designed to unlock new dimensions of reality.",
  },
  {
    id: 2,
    title: " Augmented Reality",
    text: "We deliver top-tier hardware designed to immerse users in the AR experience. Our AR hardware is not just a piece of technology; it’s a gateway to a world of wonders. We assure you quality and providing you innovative and extraordinary experience. ",
  },
  {
    id: 3,
    title: " Security",
    text: "We are providing high-tech security hardware that assure protect your digital assets. Our security hardware is a result of continuous study and understanding of the evolving threats that might affect individuals and businesses. ",
  },
  {
    id: 4,
    title: " Safety",
    text: "We are dedicated to offering top-quality safety hardware solutions designed to protect people, assets and environments from a potential catastrophe or disaster. Our products are prepared to meet the highest industry standards and fit with our clients’ specific requirements. ",
  },
];
