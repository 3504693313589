import React from "react";
import Layout from "../../Components/Common/Layout";
import JobsHead from "../../Components/JobsHead/JobsHead";
import JobsLink from "../../Components/JobsLink/JobsLink";

const JobsPage = () => {
  return (
    <Layout>
      <JobsHead />
      <JobsLink />
    </Layout>
  );
};

export default JobsPage;
