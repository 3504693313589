import styled from "@emotion/styled";
import React from "react";
import { Avatar, Card, Typography, Box } from "@mui/material";

const ClientCard = styled(Card)({
  width: "360px",
  height: "21rem",
  padding: "16px 0px",
  border: "1px solid rgpa(255,255,255,.7)",
  margin: "5px",
  boxShadow:
    "0px 1px 4px 0px rgba(7, 52, 119, 0.14), 0px 1px 1px 0px rgba(8, 32, 60, 0.15)",
  display: "flex", 
  flexDirection: "column", 
  "@media (max-width:900px)": {
    width: "95%",
  },
  "@media (max-width:600px)": {
    width: "95%",
  },
});

const StyledTypography = styled(Typography)({
  flex: 1, 
  width: "300px",
  // textAlign: "justify",
  fontFamily: "Prelia",
  fontSize: "16px",
  lineHeight: "20px",
  color: "#0B3782",
  letterSpacing:".5px",
  margin: "0px 40px",
  "@media (max-width:900px)": {
    width: "90%",
  },
});

const ClientsCard = ({ title, paragraph }) => {
  return (
    <ClientCard>
      <StyledTypography>
        {paragraph}
      </StyledTypography>
      <Box display={"flex"} margin={"16px 20px 0px 20px"}>
        <Avatar src= {process.env.PUBLIC_URL +""} sx={{ width: "42px", height: "42px" }} />
        <Box marginLeft={"10px"} paddingTop={"2px"}>
          <Typography
            color={"#0B3782"}
            fontFamily={"Roie"}
            fontSize={" 16px"}
            marginTop={"0.44rem"}
            textTransform={"uppercase"}
          >
            {title}
          </Typography>
          <Typography
            color={"rgba(0, 0, 0, 0.54)"}
            fontFamily={"Prelia"}
            fontSize={" 10px"}
          >
          </Typography>
        </Box>
      </Box>
    </ClientCard>
  );
};

export default ClientsCard;
