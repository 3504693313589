import React from 'react';
import PropTypes from 'prop-types';
import styles from './DisplayDataa.module.css';

export default function DisplayDataa({ paragraph, imageSrc, flag }) {
    const textStyle = {
        color: "#0B3782",
        whiteSpace: "pre",
        paddingLeft: (flag=="true")?"2.7rem":""
    };

    const formattedParagraph = paragraph.replace(/\n/g, "\n");

    return (
        <div className={styles.container}>
            <img src={imageSrc} width="20px" height="20px" />
            <div style={textStyle} className={styles.textStyle}>
                {formattedParagraph}
                <br />
            </div>
        </div>
    );
}

DisplayDataa.propTypes = {
    paragraph: PropTypes.string.isRequired,
};
