import React from 'react'
import styles from './FooterAppendix.module.css';
import FooterText from '../FooterText/FooterText';
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import TwitterIcon from '@mui/icons-material/Twitter';
import LinkedInIcon from '@mui/icons-material/LinkedIn';

export default function FooterAppendix() {
    return (
        <div className={styles.bottomDiv}>
            <FooterText font="Prelia" color="#0B3782">All Rights Reserved @ Universe Software</FooterText>
            <div className={styles.iconContainer}>
                <a href="https://www.facebook.com/Universe.we1" target="_blank" rel="noopener noreferrer">
                    <FacebookIcon sx={{ color: "#335BF5" }} />
                </a>
                <a href="https://www.instagram.com/we_universe_/" target="_blank" rel="noopener noreferrer">
                    <InstagramIcon sx={{ color: "#E51F5A" }} />
                </a>
                <a href="https://www.twitter.com/we_universe_" target="_blank" rel="noopener noreferrer">
                    <img src={process.env.PUBLIC_URL +'/assets/images/x-twitter.svg'} alt="Twitter" style={{ width: '20px', height: '18px' }} />
                </a>
                <a href="https://www.linkedin.com/company/we-universe/mycompany/" target="_blank" rel="noopener noreferrer">
                    <LinkedInIcon sx={{ color: "#0A66C2" }} />
                </a>
            </div>
        </div>
    )
}
