import React from "react";
import { Box, Typography } from "@mui/material";
import styled from "@emotion/styled";

const Dot = styled(Box)({
  width: "5px",
  height: "3px",
  borderRadius: "50%",
  backgroundColor: "#0B3782",
  padding: "2px",
  margin: "20px 15px 15px 15px",
});

const ServiceInfo = ({ title, text }) => {
  return (
    <Box sx={{ display: "flex"}}>
      <Dot />
      <Box>
        <Typography
          fontFamily={"Roie"}
          fontSize={"24px"}
          lineHeight={{ xs: "30px", md: "44px" }}
          letterSpacing={"0.2px"}
          color={"#0B3782"}
        >
          {title}
        </Typography>

        <Typography
          fontFamily={"Prelia"}
          fontSize={"16px"}
          lineHeight={{ xs: "30px", md: "36px" }}
          letterSpacing={"0.2px"}
          color={"#0B3782"}
          width={{ xs: "100%", md: "494px" }}
          marginBottom={{ xs: "10px", md: "0px" }}
        >
          {text}
        </Typography>
      </Box>
    </Box>
  );
};

export default ServiceInfo;
