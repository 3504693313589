import { React, useState } from "react";
import { Box, InputBase, Typography } from "@mui/material";
import styled from "@emotion/styled";
import SearchIcon from "@mui/icons-material/Search";
import Filtration from "./Filtration";
import { useBlogs } from "../../Context/BlogContext";

const SearchContainer = styled(Box)({
  display: "flex",
  alignItems: "center",
  padding: "10px",
  border: "1px solid #D7DDE3",
  borderRadius: " 3px",
  width: "100%",
});

const BlogsTopSection = () => {
  const [searchInput, setSearchInput] = useState("");
  const { blogs, setFilteredBlogs } = useBlogs();
  const [filteredValue, setFilteredValue] = useState("see all");

  const handleSearch = (e) => {
    const searchTerm = e.target.value.toLowerCase();

    const filtered = blogs.filter((blog) => {
      const titleMatch = blog.title.toLowerCase().includes(searchTerm);
      // Check if the blog category matches the selected filter
      const categoryMatch =
        filteredValue === "see all" ||
        blog.category.toLowerCase() === filteredValue.toLowerCase();

      return titleMatch && categoryMatch;
    });

    setFilteredBlogs(filtered);
    setSearchInput(e.target.value);
  };

  const handleFilterChange = (filter) => {
    setFilteredValue(filter);
  };

  return (
    <Box
      sx={{
        margin: "100px 150px 0px 150px",
        "@media (max-width: 900px)": {
          margin: "100px 20px 0px 20px ",
        },
      }}
    >
      <Typography
        color={"#0B3782"}
        fontFamily={"Qualy"}
        fontSize={{ xs: "28px", sm: "40px" }}
        letterSpacing={"-0.72px"}
        margin={"60px 0px 30px 0px"}
      >
        Blogs
      </Typography>
      <Box display={"flex"} justifyContent={"space-between"}>
        <SearchContainer>
          <SearchIcon color="disabled" />
          <InputBase
            sx={{
              fontFamily: "Prelia",
              fontSize: "14px",
              marginLeft: "15px",
              width: "100%",
            }}
            placeholder="Blog search"
            onChange={handleSearch}
            value={searchInput}
          />
        </SearchContainer>
      </Box>

      <Filtration onFilterChange={handleFilterChange} />
    </Box>
  );
};

export default BlogsTopSection;
