import React, { useEffect, useRef } from "react";
import { useParams } from "react-router-dom";
import Layout from "../../Components/Common/Layout";
import HeroImageBusinessLines from "../../Components/HeroImageBusinessLines/HeroImageBusinessLines";
import ECommerce from "../../Components/ECommerce/ECommerce";
import BusinessLineDetails from "../../Components/BusinessLineDetails/BusinessLineDetails";
import { EMarketingLineData } from "../../Data/EMarketingDetailsData";

const MarketingPage = () => {
  const { flag } = useParams();
  const digitalRef = useRef(null);
  const commerceRef = useRef(null);

  useEffect(() => {
    if (digitalRef.current && flag === "digital")
      digitalRef.current.scrollIntoView({ behavior: "smooth" });
    else if (commerceRef.current && flag === "commerce")
      commerceRef.current.scrollIntoView({ behavior: "smooth" });
  }, [flag]);

  const marketingText =
    "We aim to drive digital success for businesses of all sizes. Our E-Marketing services are designed to take your brand to new heights in the digital landscape.";

  return (
    <Layout>
      <HeroImageBusinessLines
        image={process.env.PUBLIC_URL + '/assets/images/blue.svg'}
        imageSrc= {process.env.PUBLIC_URL +"/assets/images/MarketingFinal.svg"}
        text={marketingText}
      />
      <div ref={digitalRef}>
        <BusinessLineDetails
          color={"#4B82F7"}
          data={EMarketingLineData}
          image= "/assets/images/Emarketing-details-background.jpg"
          title={"Digital Marketing"}
        />
      </div>
      <div ref={commerceRef}>
        <ECommerce />
      </div>
    </Layout>
  );
};

export default MarketingPage;
