import React from "react";
import Styles from "./OurVisionList.module.css";
import { Box, Typography, useMediaQuery } from "@mui/material";
import styled from "@emotion/styled";

const CustomTypography = styled(Typography)({
  color: "#0B3782",
  fontFamily: "Roie",
  fontSize: "32px",
  fontStyle: "normal",
  fontWeight: 400,
  letterSpacing: "1px",
  marginTop: "20px",
  "@media (max-width: 1110px)": {
    fontSize: "25px",
  },
});

const containerStyle = {
 // textAlign: "justify",
};

function OurVisionList({ img, title, text }) {
  const isSmallScreen = useMediaQuery("(max-width:1110px)");

  return (
    <Box sx={{ marginTop: isSmallScreen ? "-3rem" : "0px" }} style={containerStyle}>
      <img src={img} className={Styles.img} />
      <CustomTypography>{title}</CustomTypography>
      <div className={Styles.text}>
        <p>{text}</p>
      </div>
    </Box>
  );
}

export default OurVisionList;
