import { Box, Container, Typography, useMediaQuery } from "@mui/material";
import React from "react";
import styled from "@emotion/styled";
import DisplayDataa from "../DisplayDataa/DisplayDataa";

const OurValuesBox = styled(Box)({
  backgroundImage: `url(${process.env.PUBLIC_URL}/assets/images/Rectangle.svg)`,//'url("/assets/images/Rectangle.svg")',
  backgroundPosition: "center",
  backgroundRepeat: "no-repeat",
  //padding: "0.5px",
});

const imageStyle = {
  backgroundImage: `url(${process.env.PUBLIC_URL}/assets/images/WorldMap.svg)`,//`url("")`,
  backgroundSize: 'contain',
  backgroundRepeat: 'no-repeat',
  backgroundPosition: 'center',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'flex-start',
  padding: '20px',
};

function OurValues() {
  const isSmallScreen = useMediaQuery("(max-width:900px)");
  const isxSmallScreen = useMediaQuery("(max-width:850px)");
  const isxxSmallScreen = useMediaQuery("(max-width:700px)");

  return (
    <OurValuesBox
      sx={{
        backgroundColor: "#f9f9f9",
        height: "fit-content",
        alignItems: "center",
        marginBottom: "50px",
      }}
    >
      <div style={imageStyle}>
        <Typography
          color={"#0B3782"}
          fontFamily={"Qualy"}
          fontSize={isSmallScreen ? "25px" : "35px"}
          textAlign={"center"}
          marginTop={"45px"}
        >
          Our Values
        </Typography>
        <Container sx={{ marginTop: "10px" }}>
          <Box
            display={isxxSmallScreen ? "block" : "flex"}
            justifyContent={"space-between"}
            margin={"10px 0px"}
          >
            <Box>
              <Box
                sx={{
                  background: isxSmallScreen ? "transparent" : "#fff",
                  textAlign: "left",
                  borderRadius: "8px",
                  height: "fit-content",
                  margin: isxSmallScreen ? "0rem" : "2rem",
                  padding: isxSmallScreen ? "0rem" : "1.3rem",
                  width: "14rem",
                  boxShadow: isxSmallScreen ? "" : "0px 0px 10px 0px #5CA9D3",
                }}
              >
                <DisplayDataa paragraph={"Innovation"} imageSrc= {process.env.PUBLIC_URL +"/assets/images/Square.svg"} />
                <DisplayDataa paragraph={"Reliability"} imageSrc= {process.env.PUBLIC_URL +"/assets/images/Square.svg"} />
                <div style={{ marginLeft: isxxSmallScreen ? "2.8rem" : "" }}>
                  <DisplayDataa paragraph={"Continuous Development"} imageSrc= {process.env.PUBLIC_URL +"/assets/images/Square.svg"} flag={isxxSmallScreen?"true":"false"}/>
                </div>
              </Box>
              <Box
                sx={{
                  background: isxSmallScreen ? "transparent" : "#fff",
                  textAlign: "left",
                  borderRadius: "8px",
                  height: "fit-content",
                  margin: isxSmallScreen ? "0rem" : "2rem",
                  padding: isxSmallScreen ? "0rem" : "1.3rem",
                  width: "14rem",
                  boxShadow: isxSmallScreen ? "" : "0px 0px 10px 0px #A25CDD",
                }}
              >
                <DisplayDataa paragraph={"Team Spirit"} imageSrc= {process.env.PUBLIC_URL +"/assets/images/PurpleSqaure.svg"} />
                <DisplayDataa paragraph={"Challenging"} imageSrc= {process.env.PUBLIC_URL +"/assets/images/PurpleSqaure.svg"} />
                <DisplayDataa paragraph={"Hi-Tech"} imageSrc= {process.env.PUBLIC_URL +"/assets/images/PurpleSqaure.svg"} />
              </Box>
            </Box>

            <Box>
              <Box
                sx={{
                  background: isxSmallScreen ? "transparent" : "#fff",
                  textAlign: "left",
                  borderRadius: "8px",
                  height: "fit-content",
                  margin: isxSmallScreen ? "0rem" : "2rem",
                  padding: isxSmallScreen ? "0rem" : "1.3rem",
                  width: "14rem",
                  boxShadow: isxSmallScreen ? "" : "0px 0px 10px 0px #6CCBB4",
                }}
              >
                <DisplayDataa paragraph={"Inspiration"} imageSrc= {process.env.PUBLIC_URL +"/assets/images/greenSquare.svg"} />
                <DisplayDataa paragraph={"Responsibility"} imageSrc= {process.env.PUBLIC_URL +"/assets/images/greenSquare.svg"} />
                <DisplayDataa paragraph={"Imagination"} imageSrc= {process.env.PUBLIC_URL +"/assets/images/greenSquare.svg"} />
              </Box>
              <Box
                sx={{
                  background: isxSmallScreen ? "transparent" : "#fff",
                  textAlign: "left",
                  borderRadius: "8px",
                  height: "fit-content",
                  margin: isxSmallScreen ? "0rem" : "2rem",
                  padding: isxSmallScreen ? "0rem" : "1.3rem",
                  width: "14rem",
                  boxShadow: isxSmallScreen ? "" : "0px 0px 10px 0px #5CA9D3",
                }}
              >
                <DisplayDataa paragraph={"Youth"} imageSrc= {process.env.PUBLIC_URL +"/assets/images/Square.svg"} />
                <DisplayDataa paragraph={"Professionalism"} imageSrc= {process.env.PUBLIC_URL +"/assets/images/Square.svg"} />
                <DisplayDataa paragraph={"Intelligence"} imageSrc= {process.env.PUBLIC_URL +"/assets/images/Square.svg"} />
              </Box>
            </Box>

            <Box>
              <Box
                sx={{
                  background: isxSmallScreen ? "transparent" : "#fff",
                  textAlign: "left",
                  borderRadius: "8px",
                  height: "fit-content",
                  margin: isxSmallScreen ? "0rem" : "2rem",
                  padding: isxSmallScreen ? "0rem" : "1.3rem",
                  width: "14rem",
                  boxShadow: isxSmallScreen ? "" : "0px 0px 10px 0px #A25CDD",
                }}
              >
                <DisplayDataa paragraph={"Integrity and Trust"} imageSrc= {process.env.PUBLIC_URL +"/assets/images/PurpleSqaure.svg"} />
                <div style={{ marginLeft: isxxSmallScreen ? "2.8rem" : "" }}>
                  <DisplayDataa paragraph={"Humanity"} imageSrc= {process.env.PUBLIC_URL +"/assets/images/PurpleSqaure.svg"} flag={isxxSmallScreen?"true":"false"}/>
                </div>
                <DisplayDataa paragraph={"Excitement"} imageSrc= {process.env.PUBLIC_URL +"/assets/images/PurpleSqaure.svg"} />
              </Box>
              <Box
                sx={{
                  background: isxSmallScreen ? "transparent" : "#fff",
                  textAlign: "left",
                  borderRadius: "8px",
                  height: "fit-content",
                  margin: isxSmallScreen ? "0rem" : "2rem",
                  padding: isxSmallScreen ? "0rem" : "1.3rem",
                  width: "14rem",
                  boxShadow: isxSmallScreen ? "" : "0px 0px 10px 0px #6CCBB4",
                }}
              >
                <DisplayDataa paragraph={"High Quality"} imageSrc= {process.env.PUBLIC_URL +"/assets/images/greenSquare.svg"} />
                <DisplayDataa paragraph={"Creativity"} imageSrc= {process.env.PUBLIC_URL +"/assets/images/greenSquare.svg"} />
                <DisplayDataa paragraph={"Confidence"} imageSrc= {process.env.PUBLIC_URL +"/assets/images/greenSquare.svg"} />
              </Box>
            </Box>
          </Box>
        </Container>
      </div>
    </OurValuesBox>
  );
}

export default OurValues;
