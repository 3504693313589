import React from 'react';
import styles from './ExternalActions.module.css';
import CardActionList from '../CardActionList/CardActionList';

export default function ExternalActions() {
    const contacttInfo = (
        <>
        <br />
        Tel +97022963005 | Fax +97022963966 | B.O.Box 4326 
        </>
    );

    const contactInfo = (
        <>
        <br />
            info@we-universe.com
            <br />
            www.we-universe.com
        </>
    );

    const locationInfo = (
        <>
            Haifa Bldg | 5th Floor | Al-Irsal St. | Ramallah | Palestine            <br />  
            Bldg 01 | Ground Floor | Dubai Internet City | Dubai | UAE
        </>
    );

    return (
        <div className={styles['block']}>
            <CardActionList
                data={[
                    { imageSrc: process.env.PUBLIC_URL + '/assets/images/phone.svg', title: 'Contact Info' },
                    { imageSrc: process.env.PUBLIC_URL + '/assets/images/location.svg', title: 'Location' },
                    { imageSrc: process.env.PUBLIC_URL + '/assets/images/message.svg', title: 'Tech support 24/7' },
                ]}
                paragraphs={[
                    contacttInfo,
                    locationInfo,
                    contactInfo,
                ]}
            />
        </div>
    );
}
