import React from "react";
import Layout from '../../Components/Common/Layout';
import JobDetail from "../../Components/JobDetail/JobDetail";
import { useParams } from 'react-router-dom';

const JobsDetailsPage = () => {
    const { id } = useParams();
   
    return (
        <Layout>
            <JobDetail id={id}/>
        </Layout>
    );
};

export default JobsDetailsPage;