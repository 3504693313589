import React, { useEffect, useRef } from "react";
import { useParams } from "react-router-dom";
import Layout from "../../Components/Common/Layout";
import BusinessLineDetails from "../../Components/BusinessLineDetails/BusinessLineDetails";
import BusinessLineSolutions from "../../Components/BusinessLineSolutions/BusinessLineSolutions";
import Messaging from "../../Components/Messaging/Messaging";
import { BusinessTelecomMessagingData } from "../../Data/MessagingData";
import { universeTelecomSolutionsData } from "../../Data/universeTelecomSolutions";
import { universeTelecomLineData } from "../../Data/universeTelecomDetailsData";
import HeroImageBusinessLines from "../../Components/HeroImageBusinessLines/HeroImageBusinessLines";

const TelecomPage = () => {
  const { flag } = useParams();
  const businessLineRef = useRef(null);
  const entertainmentRef = useRef(null);
  const messagingRef = useRef(null);

  useEffect(() => {
    if (businessLineRef.current && flag === "business")
      businessLineRef.current.scrollIntoView({ behavior: "smooth" });
    else if (entertainmentRef.current && flag === "entertainment")
      entertainmentRef.current.scrollIntoView({ behavior: "smooth" });
    else if (messagingRef.current && flag === "messaging")
      messagingRef.current.scrollIntoView({ behavior: "smooth" });
  }, [flag]);

  const telecomText =
    "Universe Telecom LLC is a leading telecom aggregator, specialized in Digital Payment, Direct Carrier Billing, Value-Added Services (VAS) and systems. Offering its solutions to over of 350 partners regionally and connecting digital merchants with our platform to start hitting new records of revenues.";

  return (
    <Layout>
      <HeroImageBusinessLines
        image={process.env.PUBLIC_URL +"/assets/images/red.svg"}
        imageSrc= {process.env.PUBLIC_URL +"/assets/images/TelecomFinal.svg"}
        text={telecomText}
      />
      <div ref={businessLineRef}>
        <BusinessLineDetails
          color={"#Ad58e4"}
          data={universeTelecomLineData}
          image="/assets/images/telecom-background.jpg"
          title={"Mobile Payment"}
        />
      </div>
      <div ref={entertainmentRef}>
        <BusinessLineSolutions
          color={"#Ad58e4"}
          title={"Entertainment & Social Media Solutions"}
          data={universeTelecomSolutionsData}
        />
      </div>
      <div ref={messagingRef}>
        <Messaging
          color={"#Ad58e4"}
          data={BusinessTelecomMessagingData}
          hasDescription={true}
        />
      </div>
    </Layout>
  );
};

export default TelecomPage;
