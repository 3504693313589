import React from "react";
import { Box, IconButton, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

const ResponsiveListItemContent = ({
  onCloseClick,
  selectedItemText,
  color,
}) => {
  const hexToRGBA = (color) => {
    const r = parseInt(color.slice(1, 3), 16);
    const g = parseInt(color.slice(3, 5), 16);
    const b = parseInt(color.slice(5, 7), 16);
    return `rgba(${r}, ${g}, ${b}, 0.12)`;
  };
  return (
    <Box
      sx={{
        backgroundColor: hexToRGBA(color),
        padding: "5px",
        borderRadius: "8px",
      }}
    >
      <IconButton
        sx={{
          width: "45px",
          color: "#1C1D22",
          marginLeft: "80%",
        }}
        onClick={onCloseClick}
      >
        <CloseIcon />
      </IconButton>

      <Typography sx={{ margin: "15px" }}>{selectedItemText}</Typography>
    </Box>
  );
};

export default ResponsiveListItemContent;
