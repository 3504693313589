const DigitalCodexInfo = (
  <>
    <p>
      Digital codex is a one-stop shop in-house platform, which is the home of a wide range of digital cards and vouchers, it has fully secure, easy, fast, and guaranteed payment methods.
      Digital Codex will enhance the entire online shopping by making it a simple, easy, and enjoyable process!
    </p>

    <div style={{ marginTop: "-0.6rem" }}>
      <b style={{fontFamily: "Roie"}}> ► Main Features </b>
      <div
        style={{
          display: "flex",
          gap: "9px",
        }}
      >
        <div style={{ display: "flex", gap: "2rem" }}>
          <div>
            <ul style={{paddingLeft: "1rem", margin: 0 }}>
              <li>User-friendly interface.</li>
              <li>Loyalty and Rewards System.</li>
              <li>Supporting Multi Currencies.</li>
              <li>Simple and easy purchase process.</li>
              <li>Multi E-payment channels and top-up methods.</li>
              <li>Wide range of vouchers and various categories.</li>
            </ul>
          </div>
         
        </div>

      </div>
    </div>
  </>
);

const cashInfo = (
  <>
    <p>
      Cash-Quiz is an in-house competition game that gives subscribers monthly Prizes. By answering 9 questions correctly in 90 seconds and collecting the highest number of points to win the monthly prize.
    </p>

    <div
      style={{
        display: "flex",
        gap: "70px",
        "@media (max-width: 600px)": {
          display: "block",
        },
      }}
    >
      <div>
        <b style={{fontFamily: "Roie"}}> ► Main Features: </b>
        <li>Cash prizes.</li>
        <li>User-friendly Interface.</li>
        <li>Challenges and Extra Points.</li>
        <li>Unique User Experience.</li>
      </div>
    </div>
  </>
);

const hareefInfo = (
  <>
    <p>
      Hareef: in-house entertainment platform which includes all football matches and leagues around the world by which subscribers can get the latest news for football clubs and teams. Besides the interactive features that allow subscribers to predict matches results and compete to win prizes.       </p>

    <div
      style={{
        display: "flex",
        gap: "70px",
        "@media (max-width: 600px)": {
          display: "block",
        },
      }}
    >
      <div>
        <b style={{fontFamily: "Roie"}}> ► Main Features: </b>
        <li>Live scoring.</li>
        <li>Cash prizes.</li>
        <li>Include all leagues.</li>
        <li>Access to latest news of football teams.</li>
      </div>
    </div>
  </>
);

const livaInfo = (
  <>
    <p>
      Live@ is an in-house social media platform that aims to increase the followers base and engagement on the retailers’ social media pages (Facebook and Instagram). The influencers play a significant role in promoting the LIVE@ platform on their social media accounts, to encourage their followers to follow the retailers’ social media accounts, in return followers will get gifts and giveaways from the participating retailers.      </p>

    <div
      style={{
        display: "flex",
        gap: "70px",
        "@media (max-width: 600px)": {
          display: "block",
        },
      }}
    >
      <div>
        <b style={{fontFamily: "Roie"}}> ► Main Features: </b>
        <li>High reach of promotions.</li>
        <li>Increase the customer base.</li>
        <li>Get active & real followers on social media.</li>
      </div>
    </div>
  </>
);

export const softwareSolutionLineDetailsData = [
  {
    id: 1,
    title: "Cash Quiz",
    content: cashInfo,
  },

  {
    id: 2,
    title: "Digital Codex",
    content: DigitalCodexInfo,
  },

  {
    id: 3,
    title: "Hareef",
    content: hareefInfo,
  },

  {
    id: 4,
    title: "Liva@",
    content: livaInfo,
  },
];
