import { Typography } from "@mui/material";
import React from "react";
import styled from "@emotion/styled";

const SectionTitle = styled(Typography)({
  fontFamily: "Qualy",
  fontSize: "30px",
  lineHeight: "44px",
  color: "#0B3782",
  marginTop: "30px",
  "@media (max-width: 600px)": {
    fontSize: "25px",
  },
});

const BusinessSectionTitle = ({ title }) => {
  return <SectionTitle>{title}</SectionTitle>;
};

export default BusinessSectionTitle;
