import { Box, Typography } from "@mui/material";
import React from "react";
import OurTeamList from "./OurTeamList";
import styled from "@emotion/styled";

const teamList = [
  {
    img: process.env.PUBLIC_URL + "/assets/images/GreenSvgSqaure.svg",
    name: "Lorem Ipsum",
    postion: "Software Engineer",
  },

  {
    img: process.env.PUBLIC_URL + "/assets/images/GreenSvgSqaure.svg",
    name: "Lorem Ipsum",
    postion: "Head Manager",
  },
  {
    img: process.env.PUBLIC_URL + "/assets/images/GreenSvgSqaure.svg",
    name: "Lorem Ipsum",
    postion: "Tech Lead",
  },
  {
    img: process.env.PUBLIC_URL + "/assets/images/GreenSvgSqaure.svg",
    name: "Lorem Ipsum",
    postion: "Digital Marketing",
  },
  {
    img: process.env.PUBLIC_URL + "/assets/images/GreenSvgSqaure.svg",
    name: "Lorem Ipsum",
    postion: "Digital Marketing",
  },
];

const OurTeamBox = styled(Box)({
  backgroundImage: 'url("/assets/images/wave.svg")',
  backgroundPosition: "bottom left",
  backgroundRepeat: "no-repeat",
  height: "900px",
  padding: "0.5px",
});

function OurTeam() {
  return (
    <Box>
      <Typography
        fontFamily={"Roie"}
        color={"#0B3782"}
        fontSize={"32px"}
        textAlign={"center"}
        marginTop={"58px"}
      >
        Our Team
      </Typography>
      <Box
        display={"flex"}
        justifyContent={"space-between"}
        margin={"44px 130px"}
      >
        {teamList.map((item) => {
          return (
            <OurTeamList
              img={item.img}
              name={item.name}
              postion={item.postion}
            />
          );
        })}
      </Box>
    </Box>
  );
}

export default OurTeam;
