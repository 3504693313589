import React, { useState, useEffect } from "react";
import styles from "./JobsLink.module.css";
import { Button, useMediaQuery } from "@mui/material";
import JobAccordion from "../JobAccordion/JobAccordion";
import axios from "axios";
import config from "../../config";

const apiUrl = config.urls.apiUrl;

export default function JobsLink() {
  const [flag, setFlag] = useState(true);
  const [activeCategory, setActiveCategory] = useState(null);
  const [buttonClick, setButtonClick] = useState(true);
  const isxSmallScreen = useMediaQuery("(max-width:400px)");
  const buttons = [
    { text: "Software", color: "#6CCBB4", borderColor: "#6CCBB4" },
    { text: "Technology", color: "#5CA9D3", borderColor: "#5CA9D3" },
    { text: "Telecom", color: "#A25CDD", borderColor: "#A25CDD" },
    { text: "E-marketing", color: "#4B82F7", borderColor: "#4B82F7" },
  ];

  const [buttonStates, setButtonStates] = useState(
    Array(buttons.length).fill(false)
  );

  const [jobsData, setJobsData] = useState([]);
  useEffect(() => {
    axios
      .get(`${apiUrl}/api/jobs/GetAllJobs`)
      .then((response) => {
        const data = response.data;
        if (data.length > 0) {
          setFlag(false);
          setJobsData(data);
        } else {
          setFlag(true);
        }
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, []);

  ///////dummy data
  // const jobDataDummy = [
  //   {
  //     id: 1,
  //     title: 'Job Title 1',
  //     description: 'Description for Job 1@Description for Job 2',
  //     category: 'telecom',
  //     qualification: 'Qualification for Job 1@Description for Job 2',
  //     details: 'Details for Job 1@Description for Job 2',
  //     date: "2023-09-11 07:49:24"
  //   },
  //   {
  //     id: 2,
  //     title: 'Job Title 3',
  //     description: 'Description for Job 3@Description for Job 2',
  //     category: 'e-marketing',
  //     qualification: 'Qualification for Job 3@Description for Job 2',
  //     details: 'Details for Job 3@Description for Job 2',
  //     date: "2023-09-11 07:49:24"
  //   },
  //   {
  //     id:3,
  //     title: 'Job Title 4',
  //     description: 'Description for Job 4@Description for Job 2',
  //     category: 'technology',
  //     qualification: 'Qualification for Job 4@Description for Job 2',
  //     details: 'Details for Job 4@Description for Job 2',
  //     date: "2023-09-11 07:49:24"
  //   },
  // ];

  const handleCategoryClick = (category, index) => {
    setActiveCategory(category);
    const newButtonStates = buttonStates.map((state, i) => i === index);
    setButtonStates(newButtonStates);
    setButtonClick(false);
  };

  const filteredJobData = activeCategory
    ? jobsData.filter(
      (job) => job.category.toLowerCase() === activeCategory.toLowerCase()
    )
    : jobsData;

  const handleClick = () => {
    setActiveCategory(null);
    const newButtonStates = Array(buttons.length).fill(false);
    setButtonStates(newButtonStates);
    setButtonClick(true);
  };

  const [openAccordionIndex, setOpenAccordionIndex] = useState(null);
  const handleAccordionClick = (index) => {
    if (openAccordionIndex === index) {
      setOpenAccordionIndex(null);
    } else {
      setOpenAccordionIndex(index);
    }
  };

  const flagTelecom = filteredJobData.some(
    (job) => job.category.toLowerCase() === "telecom"
  );
  const flagSoftware = !filteredJobData.some((job) => job.category.toLowerCase() === 'software');
  const flagTechnology = !filteredJobData.some((job) => job.category.toLowerCase() === 'technology');
  const flagEMarketing = !filteredJobData.some((job) => job.category.toLowerCase() === 'e-marketing');
  // console.log("Filtered job data:", flagTelecom);

  return (
    <div>
      {flag ? (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <p className={styles.descriptionText}>No Vacancies Opening Yet!</p>
          <p className={styles.descriptionn}>
            Keep an eye on this page for when we post a new vacancy!
          </p>
        </div>
      ) : (
        <div>
          <div className={styles.descriptionTextContainer}>
            <div style={{ width: "fit-content" }}>
              <Button
                variant="outlined"
                style={{
                  backgroundColor: buttonClick ? "#273043" : "white",
                  borderRadius: "10px",
                  fontFamily: "Prelia",
                  fontSize: "11px",
                  fontWeight: 400,
                  lineHeight: "16px",
                  letterSpacing: "0em",
                  textAlign: "left",
                  height: "24px",
                  padding: "4px 16px 4px 12px",
                  textTransform: "capitalize",
                  borderColor: "#0B3782",
                  color: buttonClick ? "white" : "#273043",
                }}
                onClick={() => handleClick()}
              >
                All Jobs
              </Button>
            </div>
            {buttons.map((button, index) => (
              <div key={index} style={{ width: "fit-content" }}>
                <Button
                  variant="outlined"
                  style={{
                    backgroundColor: buttonClick
                      ? "white"
                      : buttonStates[index]
                        ? button.color
                        : "white",
                    borderRadius: "10px",
                    fontFamily: "Prelia",
                    fontSize: "11px",
                    fontWeight: 400,
                    lineHeight: "16px",
                    letterSpacing: "0em",
                    textAlign: "left",
                    height: "24px",
                    padding: "4px 16px 4px 12px",
                    textTransform: "capitalize",
                    borderColor: button.borderColor,
                    color: buttonClick
                      ? button.color
                      : buttonStates[index]
                        ? "white"
                        : button.color,
                  }}
                  onClick={() => handleCategoryClick(button.text, index)}
                >
                  {button.text}
                </Button>
              </div>
            ))}
          </div>
          {!flagTelecom && activeCategory === 'Telecom' && (
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <p className={styles.descriptionText}>No Vacancies Opening Yet!</p>
              <p className={styles.descriptionn}>
                Keep an eye on this page for when we post a new vacancy!
              </p>
            </div>
          )}

          {flagSoftware && activeCategory === 'Software' && (
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <p className={styles.descriptionText}>No Vacancies Opening Yet!</p>
              <p className={styles.descriptionn}>
                Keep an eye on this page for when we post a new vacancy!
              </p>
            </div>
          )}
          {flagTechnology && activeCategory === 'Technology' && (
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <p className={styles.descriptionText}>No Vacancies Opening Yet!</p>
              <p className={styles.descriptionn}>
                Keep an eye on this page for when we post a new vacancy!
              </p>
            </div>
          )}
           {flagEMarketing && activeCategory === 'E-marketing' && (
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <p className={styles.descriptionText}>No Vacancies Opening Yet!</p>
            <p className={styles.descriptionn}>
              Keep an eye on this page for when we post a new vacancy!
            </p>
          </div>
        )}
          <div
            style={{
              margin: isxSmallScreen ? "1.3rem 2rem 3rem" : "1.3rem 5rem 3rem",
            }}
          >
            {filteredJobData.map((job, index) => {
              let colorLabel = "";
              if (job.category.toLowerCase() === "telecom") {
                colorLabel = "#A25CDD";
              } else if (job.category.toLowerCase() === "software") {
                colorLabel = "#6CCBB4";
              } else if (job.category.toLowerCase() === "technology") {
                colorLabel = "#5CA9D3";
              } else if (job.category.toLowerCase() === "e-marketing") {
                colorLabel = "#4B82F7";
              }
              return (
                <JobAccordion
                  key={index}
                  id={job.id}
                  jobTitle={job.title}
                  colorLabel={colorLabel}
                  textLabel={job.category}
                  jobCategory={job.category}
                  jobDescription={job.description}
                  jobDetails={job.jobDetails}
                  jobQualifications={job.qualifications}
                  jobDate={job.createdAt}
                  isOpen={openAccordionIndex === index}
                  onAccordionClick={() => handleAccordionClick(index)}
                />
              );
            })}
          </div>
        </div>
      )}
    </div>
  );
}
