import React, { useState } from "react";
import styles from "./Rectangle.module.css";
import TextField from "@mui/material/TextField";
import { Alert } from "@mui/material";
import { useMediaQuery } from "@mui/material";
import config from "../../config";

const apiUrl = config.urls.apiUrl;

export default function Rectangle() {
  const customInputLabelStyle = {
    fontFamily: "Prelia",
    fontSize: "14px",
    fontWeight: 400,
    lineHeight: "18px",
    letterSpacing: "0em",
    textAlign: "left",
    color: "rgba(0, 0, 0, 0.7)",
    padding: "8px 0",
    marginTop: "15px",
  };

  const [emailError, setEmailError] = useState("");
  const [subscribe, setSubscribe] = useState(false);
  const [err, setErr] = useState(false);
  const [content, setContent] = useState("");
  const isSmallScreen = useMediaQuery("(max-width:940px)");
  const customTextFieldStyle = {
    width: isSmallScreen ? "100%" : "515px",
    height: "48px",
    color: "#0B3782"
  };

  const onSubmit = (event) => {
    event.preventDefault();
    const newEmail = event.target.elements.email.value;
    if (!newEmail) {
      setEmailError("Email Address is required");
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(newEmail)) {
      setEmailError("Please enter a valid email address");
    } else {
      const backendUrl = apiUrl;
      const dataToSend = {
        inputValue: newEmail,
      };

      fetch(`${backendUrl}/api/subscribers/AddSubscriber`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(dataToSend),
      })
        .then(async (response) => {
          if (response.ok) {
            setEmailError("");
            setSubscribe(true);
            setTimeout(() => {
              setSubscribe(false);
            }, 2000);
          }
          else if (!response.ok) {
            setErr(true);
            setTimeout(() => {
              setErr(false);
            }, 3000);
            const errorText = await response.text();
            setContent(errorText);
            setSubscribe(false);
          }
          return response.json();
        })
        .then((data) => {
          console.log(data);
        })
        .catch((error) => {
          console.error("Error:", error.message);
        });
    }
    event.target.elements.email.value = "";
  };

  const handleEmailChange = (event) => {
    const newEmail = event.target.value;
    if (!newEmail) setEmailError("");
  };

  return (
    <div className={styles.container}>
      <form onSubmit={onSubmit}>
        <div className={styles.header}>
          <h2>Subscribe For Latest Newsletter</h2>
          <div>
            <TextField
              sx={{
                bgcolor: "#F9F9F9",
                borderRadius: "4px",
                ...customTextFieldStyle,
              }}
              id="email"
              variant="outlined"
              InputLabelProps={{
                style: customInputLabelStyle,
              }}
              InputProps={{
                style: customTextFieldStyle,
              }}
              placeholder="Your Email"
              name="email"
              //required
              error={Boolean(emailError)}
              helperText={emailError}
              onChange={handleEmailChange}
            />
            {subscribe && (
              <Alert severity="success">
                Your email has been successfully subscribed!
              </Alert>
            )}
            {err && <Alert severity="error">{content}</Alert>}
          </div>
          <button type="submit" className={styles.btn}>Subscribe</button>
        </div>
      </form>
    </div>
  );
}