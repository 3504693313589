import React, { useState } from "react";
import {
  MenuItem,
  Box,
  TextField,
  InputLabel,
  FormControl,
  useMediaQuery,
} from "@mui/material";
import Select from "@mui/material/Select";
import { Alert } from "@mui/material";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import styles from "./ContactForm.module.css";
import config from "../../config";

const apiUrl = config.urls.apiUrl;

export default function ContactForm({ backgroundImageUrl, title_first }) {
  const isSmallScreen = useMediaQuery("(max-width:940px)");
  const containerStyle = {
    backgroundImage: `url(${backgroundImageUrl})`,
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    height: "100vh",
    paddingBottom: isSmallScreen ? "0" : "2rem",
  };
  const buttonSubmit = {
    width: isSmallScreen ? "15rem" : "528px",
    backgroundColor: "#4B82F7",
    margin: "40px 0",
    fontWeight: "bold",
    fontSize: "14px"
  };
  const sharedTitleStyle = {
    fontFamily: "Roie",
    fontSize: isSmallScreen ? "27px" : "33px",
    fontWeight: 400,
    lineHeight: "83px",
    letterSpacing: "-0.7200000286102295px",
    textAlign: isSmallScreen ? "center" : "left",
    width: "266px",
    marginTop: isSmallScreen ? "8rem" : "-18rem",
  };
  const customInputLabelStyle = {
    fontFamily: "Prelia",
    fontSize: isSmallScreen ? "12px" : "14px",
    fontWeight: 400,
    lineHeight: "18px",
    letterSpacing: "0em",
    textAlign: "left",
    // color: "#0B3782",
    padding: "8px 0",
    marginTop: "15px",
    color:"#0B3782"
  };
  const customTextFieldStyle = {
    width: isSmallScreen ? "15rem" : "528px",
    color: "#0B3782",
  };
  const [department, setDepartment] = useState("");
  const handleChange = (event) => {
    setDepartment(event.target.value);
  };
  const [name, setName] = useState("");
  const [nameError, setNameError] = useState("");
  const handleNameChange = (event) => {
    const newName = event.target.value;
    setNameError("");
    setName(newName);
  };
  const [subscribe, setSubscribe] = useState(false);

  const [company, setCompany] = useState("");
  const [companyError, setCompanyError] = useState("");
  const handleCompanyChange = (event) => {
    const newCompany = event.target.value;
    setCompanyError("");
    setCompany(newCompany);
  };
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState("");
  const handleEmailChange = (event) => {
    const newEmail = event.target.value;
    setEmailError("");
    setEmail(newEmail);
  };
  const [phone, setPhone] = useState("");
  const [phoneError, setPhoneError] = useState("");

  const handlePhoneChange = (value) => {
    setPhoneError("");
    setPhone(value);
  };

  const onSubmit = (event) => {
    event.preventDefault();
    const newEmail = event.target.elements.email.value;
    const newPhone = phone;
    const newCompany = event.target.elements.company.value;
    const newName = event.target.elements.name.value;

    let hasError = false;

    if (!/^[A-Za-z\s]+$/.test(newName)) {
      setNameError("Only letters are allowed");
      hasError = true;
    } else {
      setNameError("");
    }

    if (!/^[A-Za-z\s]+$/.test(newCompany)) {
      setCompanyError("Only letters are allowed");
      hasError = true;
    } else {
      setCompanyError("");
    }

    if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(newEmail)) {
      setEmailError("Please enter a valid email address");
      hasError = true;
    } else {
      setEmailError("");
    }

    if (!/^\d{10,12}$/.test(newPhone)) {
      setPhoneError("Please enter a valid phone number");
      hasError = true;
    } else {
      setPhoneError("");
    }

    /////////////////////////////////////////action to be done////////////////////////////////////
    if (
      !hasError &&
      /^\d{10,12}$/.test(newPhone) &&
      /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(newEmail) &&
      /^[A-Za-z\s]+$/.test(newCompany) &&
      /^[A-Za-z\s]+$/.test(newName)
    ) {
      const backendUrl = apiUrl;
      const dataToSend = {
        inputValue: newEmail,
        body:
          "Contact attached to department: " +
          department +
          "\n" +
          "First and Last name: " +
          newName +
          "\n" +
          "Company Name: " +
          newCompany +
          "\n" +
          "Email: " +
          newEmail +
          "\n" +
          "Phone Number: +" +
          newPhone,
      };
      fetch(`${backendUrl}/api/email/send-contact`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(dataToSend),
      })
        .then((response) => {
          if (!response.ok) {
            throw new Error("Network response was not ok");
          }
          return response.json();
        })
        .then((data) => {
          console.log(data);
        })
        .catch((error) => {
          console.error("Error:", error);
        });

      setDepartment("");
      setName("");
      setEmail("");
      setPhone("");
      setCompany("");
      setSubscribe(true);
      setTimeout(() => {
        setSubscribe(false);
      }, 5000);
    }
  };

  return (
    <div className={styles.imageWithTextContainer} style={containerStyle}>
      <div>
        <p style={{ ...sharedTitleStyle, color: "#0B3782" }}>{title_first}</p>
      </div>
      <Box>
        <div className={styles.container}>
          <form onSubmit={onSubmit} action="contact-us" method="POST">
            <InputLabel htmlFor="name" style={customInputLabelStyle}>
            Business Lines*
            </InputLabel>
            <FormControl
              fullWidth
              style={{ width: isSmallScreen ? "15rem" : "528px" }}
            >
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={department}
                label="Department"
                onChange={handleChange}
                displayEmpty
                style={{ height: "55px" }}
                sx={{  color: "#0B3782", }}
                required
              >
                <MenuItem value="" disabled>
                  <span
                    style={{
                      fontFamily: "Prelia",
                      fontSize: "14px",
                      fontWeight: 400,
                      lineHeigemailht: "18px",
                      letterSpacing: "0em",
                      textAlign: "left",
                      display: "block",
                      color: "#0B3782"
                    }}
                  >
                    <p style={{ color: "#0B3782", opacity: ".4" }}>
                      Select Business line
                    </p>
                  </span>
                </MenuItem>
                <MenuItem value="Telecom" sx={{color: "#0B3782",}}>Telecom</MenuItem>
                <MenuItem value="Software Solutions" sx={{color: "#0B3782",}}>
                  Software Solutions
                </MenuItem>
                <MenuItem value="Technologies" sx={{color: "#0B3782",}}>Technologies</MenuItem>
                <MenuItem value="E-Marketing" sx={{color: "#0B3782",}}>E-Marketing</MenuItem>
              </Select>
            </FormControl>
            <InputLabel htmlFor="name" style={customInputLabelStyle}>
              First and last name*
            </InputLabel>
            <TextField
              sx={{ bgcolor: "#fff", borderRadius: "4px" }}
              id="name"
              variant="outlined"
              value={name}
              InputLabelProps={{
                style: customInputLabelStyle,
              }}
              InputProps={{
                style: customTextFieldStyle,
              }}
              placeholder="Enter your name"
              name="name"
              required
              error={Boolean(nameError)}
              helperText={nameError}
              onChange={handleNameChange}
            />
            <InputLabel htmlFor="company" style={customInputLabelStyle}>
              Company*
            </InputLabel>
            <TextField
              sx={{ bgcolor: "#fff", borderRadius: "4px" }}
              id="company"
              value={company}
              variant="outlined"
              InputLabelProps={{
                style: customInputLabelStyle,
              }}
              InputProps={{
                style: customTextFieldStyle,
              }}
              placeholder="Enter your company"
              name="company"
              required
              error={Boolean(companyError)}
              helperText={companyError}
              onChange={handleCompanyChange}
            />
            <InputLabel htmlFor="email" style={customInputLabelStyle}>
              Email*
            </InputLabel>
            <TextField
              sx={{ bgcolor: "#fff", borderRadius: "4px" }}
              id="email"
              variant="outlined"
              value={email}
              InputLabelProps={{
                style: customInputLabelStyle,
              }}
              InputProps={{
                style: customTextFieldStyle,
              }}
              placeholder="Your Email"
              name="email"
              required
              error={Boolean(emailError)}
              helperText={emailError}
              onChange={handleEmailChange}
            />
            <InputLabel htmlFor="phone" style={customInputLabelStyle}>
              Phone
            </InputLabel>
            <PhoneInput
              inputClass={styles.customPhoneInput}
              id="phone"
              variant="outlined"
              value={phone}
              placeholder="Enter your phone number"
              onChange={handlePhoneChange}
              inputProps={{
                name: "phone",
                required: true,
                autoFocus: true,
                style: customTextFieldStyle,
              }}
            />
            {phoneError && (
              <div
                style={{
                  color: "#d32f2f",
                  fontSize: "0.78rem",
                  marginTop: "8px",
                  marginLeft: "16px",
                }}
              >
                {phoneError}
              </div>
            )}
            {subscribe && (
              <Alert severity="info">
                Your request has been received and is under consideration. We
                will contact you shortly. Thank you for reaching out!
              </Alert>
            )}

            <button type="submit" style={buttonSubmit} variant="contained">
              Submit
            </button>
          </form>
        </div>
      </Box>
    </div>
  );
}
