import React from "react";
import Layout from "../../Components/Common/Layout";
import BlogDetails from "../../Components/BlogDetails/BlogDetails";

const BlogsNewsletterDetailsPage = () => {
  return (
    <Layout>
      <BlogDetails />
    </Layout>
  );
};

export default BlogsNewsletterDetailsPage;
