import React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useMediaQuery } from "@mui/material";

export default function Faquestion({ isOpen, text, paragraph, toggleOpen }) {
    const isSmallScreen = useMediaQuery("(max-width:1100px)");
    const commonTypographyStyle = {
        fontFamily: 'Prelia',
        fontSize: isSmallScreen?'12px':'16px',
        fontWeight: 400,
        lineHeight: '24px',
        letterSpacing: '0em',
        textAlign: 'left',
        color: isOpen ? '#4B82F7' : '#0B3782',
    };
    const commonTypographyStylee = {
        fontFamily: 'Roie',
        fontSize: isSmallScreen?'12px':'16px',
        fontWeight: 400,
        lineHeight: '24px',
        letterSpacing: '0em',
        textAlign: 'left',
        color: isOpen ? '#4B82F7' : '#0B3782',
    };
    return (
        <Accordion expanded={isOpen} onChange={toggleOpen}>
            <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
                sx={{ bgcolor: isOpen ? '#4B82F71A' : '#fff' }}
            >
                <Typography sx={commonTypographyStylee}>
                    {text}
                </Typography>
            </AccordionSummary>
            <AccordionDetails>
                <Typography sx={{ ...commonTypographyStyle, color: '#0B3782' }}>
                    {paragraph}
                </Typography>
            </AccordionDetails>
        </Accordion>
    )
}