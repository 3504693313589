import { Box, Typography } from "@mui/material";
import React from "react";
import BlogPublishingInfo from "../BlogPublishingInfo/BlogPublishingInfo";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import PersonOutlineOutlinedIcon from "@mui/icons-material/PersonOutlineOutlined";
import styles from "./BlogDetailsTopSection.module.css";
import { Container } from "@mui/system";
import styled from "@emotion/styled";
import BlogText from "../BlogText/BlogText";
import ArrowBackIosNewOutlinedIcon from "@mui/icons-material/ArrowBackIosNewOutlined";
import { useNavigate, Link } from "react-router-dom";
import config from "../../../config";

const portalUrl = config.urls.portalUrl;

const Category = styled(Typography)({
  color: "#FFF",
  fontFamily: "Prelia",
  fontSize: "11px",
  lineHeight: "16px",
  padding: "5px 10px",
  backgroundColor: "#5570F1",
  borderRadius: " 8px",
  display: "inline-block",
  margin: "29px 0px 32px 0px ",
  textTransform: "capitalize",
});
const BackIconContainer = styled(Box)({
  backgroundColor: "#FFF",
  borderRadius: "50%",
  boxShadow: "0 0 5px lightgray",
  width: "20px",
  height: "20px",
  padding: "5px",
  marginTop: "1.5px",
});

const BlogDetailsTopSection = ({ blogDetails }) => {
  const navigate = useNavigate();
  const { title, category, createdAt, description, image, descriptionDetails } =
    blogDetails ?? {};

  function formatDate(inputDateStr) {
    const options = { year: "numeric", month: "long", day: "numeric" };
    const inputDate = new Date(inputDateStr);
    return inputDate.toLocaleDateString("en-US", options);
  }
  const formattedDate = formatDate(createdAt);
  const handleBackButton = () => {
    navigate("/blogs");
  };

  return (
    <Box>
      <Container>
        <Box display={"flex"}>
          <BackIconContainer onClick={handleBackButton}>
            <ArrowBackIosNewOutlinedIcon fontSize="5px" color="disabled" />
          </BackIconContainer>
          <Typography
            color={"#276DCB"}
            fontFamily={"Prelia"}
            fontSize={"15px"}
            lineHeight={" 24px"}
            marginLeft={"8px"}
            marginTop={"5px"}
          >
            <Link to="/blogs">
              Blogs  /
            </Link>
            <span className={styles.pathSpan}>{title}</span>
          </Typography>
        </Box>
        <Typography
          color={"#0B3782"}
          fontFamily={"Roie"}
          fontSize={{ xs: "20px", sm: "32px" }}
          margin={"15px 0px 25px"}
        >
          {/* This is the place representing the blog title. and it could be longer. */}
          {title}
        </Typography>

        <Box display={"flex"} marginBottom={"5px"}>
          <BlogPublishingInfo
            IconName={CalendarTodayIcon}
            // text={"27 July, 2023"}
            text={formattedDate}
          />
          <BlogPublishingInfo
            IconName={PersonOutlineOutlinedIcon}
            text={"Universe Team"}
          />
        </Box>

        <Box height="90vh">
          <img src={`${portalUrl}/${image}`} className={styles.img}></img>
        </Box>

        <Category>
          {/* Category Tag */}
          {category}
        </Category>
        <BlogText descriptionDetails={descriptionDetails} />
      </Container>
    </Box>
  );
};

export default BlogDetailsTopSection;
