import React, { useEffect, useRef } from "react";
import Layout from "../../Components/Common/Layout";
import HeroImageBusinessLines from "../../Components/HeroImageBusinessLines/HeroImageBusinessLines";
import Messaging from "../../Components/Messaging/Messaging";
import Services from "../../Components/Services/Services";
import { ShelfBusinessSolutionsData } from "../../Data/MessagingData";
import BusinessLineSolutions from "../../Components/BusinessLineSolutions/BusinessLineSolutions";
import { softwareSolutionLineData } from "../../Data/softwareSolutionsData";
import BusinessLineDetails from "../../Components/BusinessLineDetails/BusinessLineDetails";
import { softwareSolutionLineDetailsData } from "../../Data/softwareSolutionsDetailsData";
import { useParams } from "react-router-dom";

const SoftwarePage = () => {
  const { flag } = useParams();
  const productLineRef = useRef(null);
  const serviceRef = useRef(null);
  const intelligentRef = useRef(null);
  const businessRef = useRef(null);

  useEffect(() => {
    if (productLineRef.current && flag === "product")
      productLineRef.current.scrollIntoView({ behavior: "smooth" });
    else if (serviceRef.current && flag === "service")
      serviceRef.current.scrollIntoView({ behavior: "smooth" });
    else if (intelligentRef.current && flag === "intelligent")
      intelligentRef.current.scrollIntoView({ behavior: "smooth" });
    else if (businessRef.current && flag === "business")
      businessRef.current.scrollIntoView({ behavior: "smooth" });
  }, [flag]);

  return (
    <Layout>
      <HeroImageBusinessLines
        image={process.env.PUBLIC_URL +"/assets/images/whiteblue.svg"}
        imageSrc= {process.env.PUBLIC_URL +"/assets/images/SoftwareFinal.svg"}
        text="We are committed to providing cutting-edge software solutions that empower businesses to thrive in today’s landscape. We deliver tailor-made software that meets your unique needs and drives your success."
      />
      <div ref={productLineRef}>
        <BusinessLineDetails
          color={"#3eceb3"}
          data={softwareSolutionLineDetailsData}
          image="/assets/images/software-details-background.jpg"
          title={"Universe Products"}
        />
      </div>
      <div ref={serviceRef}>
        <Services />
      </div>
      <div ref={intelligentRef}>
        <BusinessLineSolutions
          color={"#3eceb3"}
          title={"Intelligent Solutions"}
          data={softwareSolutionLineData}
         // paragraph={"We are committed to revolutionizing your business with cutting-edge intelligent solutions. Our experts are passionate about innovation and will collaborate with you deliver bespoke solutions that drive growth and success."}
        />
      </div>
      <div ref={businessRef}>
        <Messaging color={"#3eceb3"} data={ShelfBusinessSolutionsData} />
      </div>
    </Layout>
  );
};

export default SoftwarePage;
