import styled from "@emotion/styled";
import { Card, CardMedia, Typography, CardContent } from "@mui/material";
import React from "react";
import { motion } from "framer-motion";
import config from "../../config";

const portalUrl = config.urls.portalUrl;

const StyledBlogCard = styled(Card)({
  width: "330px",

  "@media (max-width: 900px)": {
    width: "320px",
  },
  "@media (max-width: 600px)": {
    width: "95%",
  },
});

const CategoryTag = styled(Typography)({
  fontFamily: "Prelia",
  fontSize: "11px",
  color: "#FFF",
  lineHeight: "16px",
  background: "#5570F1",
  padding: " 4px 15.91px 4px 12px",
  width: "110.91px",
  borderRadius: "0px 8px 0px 0px",
  position: "absolute",
  bottom: 0,
  textTransform: "capitalize",
});
const CardContentBox = styled(CardContent)({
  padding: "28px 27.98px 13px 28px",
});
const CustomTypography = styled(Typography)((fontSize) => ({
  color: "#0B3782",

  lineHeight: "22px",
  fontSize: fontSize,
  marginBottom: "7px",
  width: " 316px",
  "@media (max-width: 900px)": {
    width: "100%",
  },
}));

const BlogCard = ({ blog }) => {
  const { title, category, createdAt, description, image } = blog;

  function formatDate(inputDateStr) {
    const options = { year: "numeric", month: "long", day: "numeric" };
    const inputDate = new Date(inputDateStr);
    return inputDate.toLocaleDateString("en-US", options);
  }
  const formattedDate = formatDate(createdAt);

  return (
    <StyledBlogCard
      component={motion.div}
      whileHover={{ scale: 1.09 }}
      whileTap={{ scale: 0.8 }}
      initial={{ opacity: 0, y: 50 }} // Initial animation values (transparent and starting from the bottom)
      animate={{ opacity: 1, y: 0 }} // Animate to full opacity and original position
      transition={{ duration: 0.5, delay: 0.05 }} // Duration of the animation with a slight delay
    >
      <CardMedia height="140" sx={{ height: "208px", position: "relative" }}>
        <img
          src={`${portalUrl}/${image}`}
          style={{ width: "100%", height: "100%" }}
        ></img>
        <CategoryTag>{category}</CategoryTag>
      </CardMedia>
      <CardContentBox>
        <Typography
          color={"rgba(0, 0, 0, 0.54)"}
          fontFamily={"Prelia"}
          fontSize={"10px"}
          textTransform={"uppercase"}
          marginBottom={"7px"}
        >
          {/* {createdAt} */}
          {formattedDate}
        </Typography>
        <CustomTypography fontSize={"16px"} fontFamily={"Roie"}>
          {title}
        </CustomTypography>
        <CustomTypography fontSize={"14px"} fontFamily={"Prelia"}>
          {description}
        </CustomTypography>
      </CardContentBox>
    </StyledBlogCard>
  );
};

export default BlogCard;