import React from 'react'
import styles from './NotFound.module.css';

export default function NotFound() {
    return (
        <div className={styles.toolbar}>
            <img src={process.env.PUBLIC_URL +'/assets/images/notfound.jpg'} alt="not found" width={550} />
        </div>
    )
}
