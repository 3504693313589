const config = {
  urls: {
    //Development
    //apiUrl: 'http://localhost:8080',

    //Server
    apiUrl: "http://api.we-universe.com/UTWebsiteApi",

    //Development
    // portalUrl: 'http://localhost:8083',

    //Server
    portalUrl: "http://api.we-universe.com/UTWebsitePortalApi",
  },
};

export default config;
