import React from 'react';
import CardAction from '../CardAction/CardAction';
import styles from './CardActionList.module.css';
import PropTypes from 'prop-types';

export default function CardActionList({ data, paragraphs }) {
    return (
        <div>
            <div className={styles.cardList}>
                {data.map((item, index) => (
                    <div key={index} className={styles.card}>
                        <CardAction
                            imageSrc={item.imageSrc}
                            title={item.title}
                            paragraph={paragraphs[index]}
                        />
                    </div>
                ))}
            </div>
        </div>
    );
}

CardActionList.propTypes = {
    data: PropTypes.array.isRequired,
    paragraphs: PropTypes.array.isRequired,
};