import { Box, Typography } from "@mui/material";
import React from "react";

const WhyUniverseList = () => {
  const whyUniverseListItem = [
    "Technology.",
    "Connectivity.",
    "Commitment.",
    "Adding Value.",
    "Qualified Team.",
    "Professionalism.",
    "Aiming to the best.",
    "Distinctive Network.",
    "Needs Identification.",
    "Intensive Experience.",
    "Delighted Stakeholders.",
    "Sustain fruitful business relationships.",
    "Utilizing the latest knowledge and technologies.",
  ];

  return (
    <Box margin={{ xs: "120px 0px 50px", sm: "120px 0px 70px" }}>
      <Typography
        fontFamily={"Qualy"}
        fontSize={{ xs: "28px", sm: "35px" }}
        lineHeight={"44px"}
        letterSpacing={"0.5px"}
        color={"#0B3782"}
        marginBottom={"30px"}
      >
        Why Universe
      </Typography>
      <Box>
        {whyUniverseListItem.map((item) => {
          return (
            <Box display={"flex"}>
              <Typography
                fontFamily={"Prelia"}
                fontSize={{ xs: "15px", sm: "18px" }}
                lineHeight={"44px"}
                letterSpacing={"0.5px"}
                color={"#0B3782"}
                fontWeight={700}
                marginRight={"5px"}
              >
                ✓
              </Typography>
              <Typography
                fontFamily={"Prelia"}
                fontSize={{ xs: "15px", sm: "18px" }}
                lineHeight={"38px"}
                letterSpacing={"0.3px"}
                color={"#0B3782"}
              >
                {item}
              </Typography>
            </Box>
          );
        })}
      </Box>
    </Box>
  );
};

export default WhyUniverseList;
