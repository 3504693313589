import { Typography, Box, useMediaQuery } from '@mui/material'
import React from 'react'

export default function PrivacyPolicy() {
    const isSmallScreen = useMediaQuery("(max-width:500px)");
    return (
        <Box sx={{ margin: "8.4rem 5rem 5rem 5rem", textAlign: "justify" }}>
            <Typography sx={{ color: "#0B3782", fontFamily: "Qualy", fontSize:isSmallScreen?"26px": "40px" }}>General Terms<br /></Typography>
            <Typography sx={{ color: "#0B3782", fontFamily: "Prelia", fontSize: isSmallScreen?"12px":"16px" }}>
                Last updated: 21/11/2023
                <br /><br />
                This Privacy Policy describes our policies and procedures on the collection, use and disclosure of the information you provide when using the website and the services provided from it. This Privacy Policy is designed to help you understand how we collect, use and safeguard your personal information when you visit our website.
                <br /> <br />
                By using this website, you consent to the practices described here and you agree to the collection and use of information in accordance with this privacy policy. This website is designed and developed by Universe the sole owner of it including all content, designs and any provided information.
                <br /><br />
                By accessing to Universe website, you have the right to check and explore the provided services, and you confirm to protect, respect all the information provided and confirm that it’s owned to Universe. These terms apply to the entire website and any email or other type of communication between you and Universe, its team, affiliates and subsidiaries.<br /><br /> <br/>
            </Typography>
            <Typography sx={{ color: "#0B3782", fontFamily: "Roie", fontSize: isSmallScreen?"20px":"24px" }}>Definitions and Key Terms:<br/><br/></Typography>
            <Typography sx={{ color: "#0B3782", fontFamily: "Prelia", fontSize: isSmallScreen?"12px":"16px" }}><span style={{ fontWeight: 'bold' }}>Cookie:</span> Small files generated by a website and saved by your browser. It is used to identifying your browser, providing analytics, browsing history on the website and remembering information about you such as your language preference.<br /><br /></Typography>
            <Typography sx={{ color: "#0B3782", fontFamily: "Prelia", fontSize: isSmallScreen?"12px":"16px" }}><span style={{ fontWeight: 'bold' }}>Company:</span> When this policy mentions “Company,” “we,” or “our,” it refers to Universe that is responsible for your information under this Privacy Policy.<br /><br /></Typography>
            <Typography sx={{ color: "#0B3782", fontFamily: "Prelia", fontSize: isSmallScreen?"12px":"16px" }}><span style={{ fontWeight: 'bold' }}>Country:</span> Where Universe or the owners/founders of Universe are based.<br /><br /></Typography>
            <Typography sx={{ color: "#0B3782", fontFamily: "Prelia", fontSize: isSmallScreen?"12px":"16px" }}><span style={{ fontWeight: 'bold' }}>Device:</span> Any device connected to the Internet is assigned a number known as an Internet protocol (IP) address. These numbers are usually assigned in geographic blocks. An IP address can often be used to identify the location from which a device is connecting to the Internet.<br /><br /></Typography>
            <Typography sx={{ color: "#0B3782", fontFamily: "Prelia", fontSize: isSmallScreen?"12px":"16px" }}><span style={{ fontWeight: 'bold' }}>Personnel:</span> Refers to those individuals who are employed by Universe or are under contract to perform a service on behalf of one of the parties.<br /><br /></Typography>
            <Typography sx={{ color: "#0B3782", fontFamily: "Prelia", fontSize: isSmallScreen?"12px":"16px" }}><span style={{ fontWeight: 'bold' }}>Personal Data:</span> is any information that relates to an identified or identifiable individual.<br /><br /></Typography>
            <Typography sx={{ color: "#0B3782", fontFamily: "Prelia", fontSize: isSmallScreen?"12px":"16px" }}><span style={{ fontWeight: 'bold' }}>Service:</span> Refers to all services provided by Universe and mentioned on the website.<br /><br /></Typography>
            <Typography sx={{ color: "#0B3782", fontFamily: "Prelia", fontSize: isSmallScreen?"12px":"16px" }}><span style={{ fontWeight: 'bold' }}>Usage Data:</span> refers to data collected automatically, either generated by the use of the service or from the service infrastructure itself (for example, the duration of a page visit).<br /><br /></Typography>
            <Typography sx={{ color: "#0B3782", fontFamily: "Prelia", fontSize: isSmallScreen?"12px":"16px" }}><span style={{ fontWeight: 'bold' }}>Website:</span> Universe’s site, which can be accessed via this URL.<br /><br /></Typography>
            <Typography sx={{ color: "#0B3782", fontFamily: "Prelia", fontSize: isSmallScreen?"12px":"16px" }}><span style={{ fontWeight: 'bold' }}>You:</span> a person and/or company browsing this website.<br /><br /></Typography>
            <br/><br/>
            <Typography sx={{ color: "#0B3782", fontFamily: "Roie", fontSize: isSmallScreen?"20px":"24px" }}>Types of Data Collected<br /><br /></Typography>
            <Typography sx={{ color: "#0B3782", fontFamily: "Roie", fontSize: isSmallScreen?"20px":"24px", marginTop: "1rem", marginLeft: "2rem" }}>- Personal Data <br/><br /></Typography>
            <Typography sx={{ color: "#0B3782", fontFamily: "Prelia", fontSize: isSmallScreen?"12px":"16px" }}>While using Our Website, we may ask you to provide us with certain personally identifiable information that can be used to contact or identify you based on your decision to contacting us for any purpose. Personally identifiable information may include, but is not limited to:<br /><br />
                <Box sx={{ marginLeft: "2rem" }}>
                    <li>Email address</li>
                    <li>First name and last name</li>
                    <li>Phone number</li>
                    <li>Address, State, Province, ZIP/Postal code, City</li>
                    <li>Resumes</li>
                    <li>Usage Data</li>
                </Box>
                <br/><br/>
            </Typography>
            <Typography sx={{ color: "#0B3782", fontFamily: "Roie", fontSize: isSmallScreen?"20px":"24px" }}>Use of Your Personal Data<br /><br /></Typography>
            <Typography sx={{ color: "#0B3782", fontFamily: "Prelia", fontSize: isSmallScreen?"12px":"16px" }}>The Company may use Personal Data for the following purposes:<br /><br />
                <Box sx={{ marginLeft: "2rem" }}>
                    <li><span style={{ fontWeight: 'bold' }}>To provide and maintain our Service,</span> including to monitor the usage of our website</li>
                    <li><span style={{ fontWeight: 'bold' }}>To contact You:</span> To contact you by email, telephone calls, SMS, or other equivalent forms of electronic communication, such as a {isSmallScreen ? '' : '\u00A0\u00A0\u00A0\u00A0'}mobile application's push notifications regarding updates or informative communications related to the functionalities, products or {isSmallScreen ? '' : '\u00A0\u00A0\u00A0\u00A0\u00A0'}contracted services, including the security updates, when necessary or reasonable for their implementation.</li>
                    <li><span style={{ fontWeight: 'bold' }}>To provide You</span> with news, special offers and general information about other goods, services and events which we offer that are {isSmallScreen ? '' : '\u00A0\u00A0\u00A0\u00A0\u00A0'}similar to those that you have already purchased or enquired about unless You have opted not to receive such information.</li>
                    <li><span style={{ fontWeight: 'bold' }}>To manage Your requests:</span> To attend and manage Your requests to Us.</li>
                    <li><span style={{ fontWeight: 'bold' }}>For other purposes:</span> We may use Your information for other purposes, such as data analysis, identifying usage trends, determining the {isSmallScreen ? '' : '\u00A0\u00A0\u00A0'}effectiveness of our promotional campaigns and to evaluate and improve our Service, products, services, marketing and your {isSmallScreen ? '' : '\u00A0\u00A0\u00A0\u00A0\u00A0'}experience.</li> <br/><br/>
                </Box>
            </Typography>
            <Typography sx={{ color: "#0B3782", fontFamily: "Roie", fontSize: isSmallScreen?"20px":"24px", marginTop: "1rem", marginLeft: "2rem" }}>- Usage Data <br/><br/></Typography>
            <Typography sx={{ color: "#0B3782", fontFamily: "Prelia", fontSize: isSmallScreen?"12px":"16px" }}>Usage Data is collected automatically when using the Website. It may include information such as the IP address of your device, operating system, browser type, history of the pages that you visited on our website, the time and date of your visit, the time you spent on those pages and other diagnostic data.<br /><br />
                We may also collect information that your browser sends whenever you visit our website or when you access the website by or through a mobile device.
            </Typography>
            <br/><br/>
            <Typography sx={{ color: "#0B3782", fontFamily: "Roie", fontSize: isSmallScreen?"20px":"24px", marginTop: "1rem" }}>Tracking Technologies and Cookies <br/><br/></Typography>
            <Typography sx={{ color: "#0B3782", fontFamily: "Prelia", fontSize: isSmallScreen?"12px":"16px" }}>We use Cookies and similar tracking technologies to track the activity on our website and store certain information. Tracking technologies used are beacons, tags, and scripts to collect and track information and to improve and analyze our website.<br /><br />
            </Typography>
            <br/><br/>
            <Typography sx={{ color: "#0B3782", fontFamily: "Roie", fontSize: isSmallScreen?"20px":"24px", marginTop: "1rem" }}>Security of Your Personal Data <br/><br/></Typography>
            <Typography sx={{ color: "#0B3782", fontFamily: "Prelia", fontSize: isSmallScreen?"12px":"16px" }}>The security of Your Personal Data is important to Us, but remember that no method of transmission over the Internet, or method of electronic storage is 100% secure. While We strive to use commercially acceptable means to protect Your Personal Data, we cannot guarantee its absolute security.<br /><br />
            </Typography>
            <br/><br/>
            <Typography sx={{ color: "#0B3782", fontFamily: "Roie", fontSize: isSmallScreen?"20px":"24px", marginTop: "1rem" }}>Changes to this Privacy Policy <br/><br/></Typography>
            <Typography sx={{ color: "#0B3782", fontFamily: "Prelia", fontSize: isSmallScreen?"12px":"16px" }}>We may update our Privacy Policy from time to time. We will notify You of any changes by posting the new Privacy Policy on this page.<br /><br />
                We will let You know via email and/or a prominent notice on our website, prior to the change becoming effective and update the "Last updated" date at the top of this Privacy Policy.
                <br /><br />
                You are advised to review this Privacy Policy periodically for any changes. Changes to this Privacy Policy are effective when they are posted on this page.
                <br /><br />
            </Typography>
            <Typography sx={{ color: "#0B3782", fontFamily: "Roie", fontSize: isSmallScreen?"20px":"24px", marginTop: "1rem" }}>Contact Us <br/><br/></Typography>
            <Typography sx={{ color: "#0B3782", fontFamily: "Prelia", fontSize: isSmallScreen?"12px":"16px" }}>If you have any questions about this Privacy Policy, you can contact us:<br /><br />
                <Box sx={{ marginLeft: "2rem" }}>
                    <li>By email: info@we-universe.com</li>
                    <li>By phone number: 022963005</li>
                </Box>
            </Typography>

        </Box>
    )
}
