export const softwareSolutionLineData = [
  {
    id: 1,
    title: "Business Intelligence ",
    text: "We use the best technologies, tools and practices to help your business stay ahead of the competition and make strategic decisions based on real-time insights.",
  },
  {
    id: 2,
    title: " Artificial Intelligence ",
    text: "We offer a wide range of AI solutions to businesses across various industries. AI technologies allows you to empower your clients with the intelligent tools and capabilities. ",
  },
  {
    id: 3,
    title: " Cyber Security",
    text: "Our cyber security services are designed to safeguarding your organizations, businesses and clients against a wide range of digital threats.",
  },
  {
    id: 4,
    title: " Customer Care Solutions",
    text: "Our solutions are designed to ensure exceptional customer experience. We will enhance your customer satisfaction and build a long-lasting relationship with your clients and customers. ",
  },
  {
    id: 5,
    title: "E-commerce Solutions",
    text: "Offering a comprehensive range of solutions to help businesses establish and optimize their inline retail operations and enhance the digital shopping experience.",
  },
];
