import React, { useState, createContext, useContext, useEffect } from "react";
import axios from "axios";
import config from "../config";

const apiUrl = config.urls.apiUrl;

const BlogsContext = createContext();

export function useBlogs() {
  return useContext(BlogsContext);
}

const BlogProvider = ({ children }) => {
  const [blogs, setBlogs] = useState([]);
  const [filteredBlogs, setFilteredBlogs] = useState([]);

  useEffect(() => {
    axios.get(`${apiUrl}/api/blogs/GetAllBlogs`).then((response) => {
      setBlogs(response.data);
      setFilteredBlogs(response.data);
    });
  }, []);

  const updateBlogs = (newBlogs) => {
    setBlogs(newBlogs);
  };

  const value = {
    blogs,
    updateBlogs,
    filteredBlogs,
    setFilteredBlogs,
  };

  return (
    <BlogsContext.Provider value={value}>{children}</BlogsContext.Provider>
  );
};

export default BlogProvider;
