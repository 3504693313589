import React from 'react';
import { IconButton } from '@mui/material';
import styles from './StyledIcon.module.css';

function StyledIcon({ color, icon }) {
  return (
    <IconButton color="inherit" style={{ color }} sx={{
      '&:hover': {
        backgroundColor: "transparent",
      },
    }}>
      {React.cloneElement(icon, { className: styles.icon })}
    </IconButton>
  );
}

export default StyledIcon;