import { Box, Typography } from "@mui/material";
import React from "react";
import CustomCarousel from "../CustomCarousel/CustomCarousel";
import ClientsCard from "./ClientsCard/ClientsCard";
import styled from "@emotion/styled";

const SecondSectionBox = styled(Box)({
  position: "relative",
  marginBottom: "100px",
  height: "400px",
  backgroundImage: `url("${process.env.PUBLIC_URL}/assets/images/WAVES.svg")`,
  backgroundRepeat: "no-repeat",
  // objectFit: "cover",
  backgroundPosition: "bottom",
});
const WhyUniverseSecondSection = () => {
  return (
    <SecondSectionBox>
      <Typography
        color={"#0B3782"}
        fontFamily={"Qualy"}
        fontSize={{ xs: "20px", sm: "35px" }}
        lineHeight={"44px"}
        letterSpacing={"-0.72px"}
        margin={{ xs: "15px auto 18px", sm: "20px auto 30px" }}
        textAlign="center"
      >
        What Our Clients Say
      </Typography>
      <CustomCarousel
        items={[
          <ClientsCard title="Unith" paragraph={ <>
            <br /><br /><br /><br />Universe Telekom has been an indispensable partner for our business at UNITH. Their DCB platform has empowered us to efficiently monetize our AI VAS products, and their support has been nothing short of exceptional. </>}/>,
          <ClientsCard title="Mondia Pay" paragraph={<>
            <br /><br /><br />We are delighted with our partnership with Universe Telecom. Top professionals and exceptional support that allows business to reach new heights and unlock growth opportunities. Universe Telecom are our go-to partner for outstanding delivery at a top-notch time-to-market.</>}/>,
          <ClientsCard title="Digitantra FZC" paragraph={"Working with the Excellent and Friendly team at Universe Telecom is a rewarding and enjoyable experience since 2018. They have provided great professional support and guidance through all our launches with them. Their friendly and talented team is always ready to collaborate and provide crucial inputs which have made a favorable impact on our revenues so far and always striving to deliver high-quality solutions and services."}/>,
          <ClientsCard title="Centili" paragraph={<>
            <br />In the dynamic landscape of the telecommunications industry, fostering strong client relationships is key. From troubleshooting technical issues to optimizing solutions, our journey together has exemplified the importance of agility, clear communication, and a commitment to delivering reliable connectivity solutions. Looking forward to more insightful projects!</>}/>,
        ]}
      />
    </SecondSectionBox>
  );
};

export default WhyUniverseSecondSection;
