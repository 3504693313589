const livaInfo = (
  <>
    <p>
    We are committed to empowering education systems and ensuring that students receive the best possible learning experiences, equipping them for success in their personal and professional lives.
     </p>

    <div
      style={{
        display: "flex",
        gap: "70px",
        "@media (max-width: 600px)": {
          display: "block",
        },
      }}
    >
      <div>
        <b style={{fontFamily: "Roie"}}> ► Main Features: </b>
        <li>Collaborative Tools.</li>
        <li>Digital content & resources.</li>
        <li>Automation and AI assistance</li>
        <li>Learning Analytics and Insights.</li>
      </div>
    </div>
  </>
);

const workInfo = (
  <>
    <p>
    Providing working system services involves offering solutions and support to ensure the efficient functioning of various systems within an organization or business.     </p>

    <div
      style={{
        display: "flex",
        gap: "70px",
        "@media (max-width: 600px)": {
          display: "block",
        },
      }}
    >
      <div>
        <b style={{fontFamily: "Roie"}}> ► Main Features: </b>
        <li>Unified database.</li>
        <li>Collaboration Tools.</li>
        <li>Systematic work flow.</li>
        <li>Security and Compliance.</li>
      </div>
    </div>
  </>
);

const securityInfo = (
  <>
    <p>
    We play a critical role in safeguarding organizations and businesses against potential risks by offering comprehensive security solutions to businesses.    </p>

    <div
      style={{
        display: "flex",
        gap: "70px",
        "@media (max-width: 600px)": {
          display: "block",
        },
      }}
    >
      <div>
        <b style={{fontFamily: "Roie"}}> ► Main Features: </b>
        <li>Remote monitoring.</li>
        <li>High security to premises.</li>
        <li>Full guidance and training.</li>
      </div>
    </div>
  </>
);

const SurveillanceInfo= (
  <>
    <p>
    We offer tailored and advanced solutions to address the unique security needs of businesses and individuals. We upgrade your existing security measures and implement a robust surveillance system.
        </p>

    <div
      style={{
        display: "flex",
        gap: "70px",
        "@media (max-width: 600px)": {
          display: "block",
        },
      }}
    >
      <div>
        <b style={{fontFamily: "Roie"}}> ► Main Features: </b>
        <li>Backup systems.</li>
        <li>Alerts Triggered by movement.</li>
        <li>Remote access via smartphones.</li>
      </div>
    </div>
  </>
);

const VirtualInfo= (
  <>
    <p>
    We are dedicated to delivering innovative and reliable safety solutions to organizations across various industries in order to ensure the protection of people, assets, and environments.        </p>

    <div
      style={{
        display: "flex",
        gap: "70px",
        "@media (max-width: 600px)": {
          display: "block",
        },
      }}
    >
      <div>
        <b style={{fontFamily: "Roie"}}> ► Main Features: </b>
        <li>VR web browsing.</li>
        <li>Immersive Audio.</li>
        <li>User Interface (UI) integration.</li>
      </div>
    </div>
  </>
);

export const universeTechnologiesLineData = [
  {
    id: 1,
    title: "Education Systems",
    content: livaInfo,
  },

  {
    id: 2,
    title: "Work Systems",
    content: workInfo,
  },

  {
    id: 3,
    title: " Security Systems",
    content: securityInfo,
  },

  {
    id: 4,
    title: "Surveillance Systems ",
    content: SurveillanceInfo,
  },
  
  {
    id: 5,
    title: "Virtual Reality",
    content: VirtualInfo,
  },
];
