import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import styles from './DisplayData.module.css';
import { useMediaQuery } from "@mui/material";

export default function DisplayData({ icon, text, paragraph }) {
    const textStyle = {
        color: "#0B3782",
    };
    const isSmallScreen = useMediaQuery("(max-width:898px)");
    
    let initialValue = parseInt(text, 10);
    const nonNumericPart = text.replace(/[0-9]/g, '');
    let [counter, setCounter] = useState(0);
    const hasKM = nonNumericPart.includes('k') || nonNumericPart.includes('M');
    const hasB = nonNumericPart.includes('B');

    if (hasKM) initialValue *= 250;
    else if (hasB) initialValue *= 6000;
    else if (initialValue < 40) initialValue *= 10;

    const finalValue = parseInt(text, 10);

    const targetRef = useRef(null);

    useEffect(() => {
        const observer = new IntersectionObserver(
            (entries) => {
                entries.forEach((entry) => {
                    if (entry.isIntersecting && counter < initialValue) {
                        const timer = setInterval(() => {
                            setCounter((prevCounter) =>
                                prevCounter + 2 <= initialValue
                                    ? prevCounter + 2
                                    : initialValue
                            );
                        }, 100); 
                        return () => clearInterval(timer);
                    }
                });
            },
            { threshold: 0.5 } 
        );

        if (targetRef.current) {
            observer.observe(targetRef.current);
        }

        return () => {
            if (targetRef.current) {
                observer.unobserve(targetRef.current);
            }
        };
    }, [counter, initialValue]);
    return (
        <div ref={targetRef} style={{ textAlign: 'center', width: isSmallScreen?'6rem':'7.2rem', marginTop:isSmallScreen?"3rem":"-4rem", padding: isSmallScreen?"":"1.8rem", }}>
            <div className={styles.buttonColor}>{icon}</div>
            <div style={textStyle} className={styles.titleStyle}>
                {counter === initialValue ? `${finalValue}${nonNumericPart}` : counter}
            </div>
            <div style={textStyle} className={styles.textStyle}>
                {paragraph}
            </div>
        </div>
    );
}

DisplayData.propTypes = {
    text: PropTypes.string,
    paragraph: PropTypes.string.isRequired,
};