import React from 'react';
import styles from './ImageGallery.module.css';

function ImageGallery() {
    const images = [
        process.env.PUBLIC_URL+ '/assets/images/EnvoyGlobal.svg',
        process.env.PUBLIC_URL+ '/assets/images/JawwalLogo.svg',
        process.env.PUBLIC_URL+ '/assets/images/Royaa.svg',
        process.env.PUBLIC_URL+ '/assets/images/NazaraTechnologies.svg',
        process.env.PUBLIC_URL+ '/assets/images/Ooredoo.svg',
    ];

    return (
            <div className={styles.imageGalleryContainer}>
                {images.map((imageSrc, index) => (
                    <img
                        key={index}
                        src={imageSrc}
                        alt={`Image ${index + 1}`}
                        className={styles.galleryImage}
                    />
                ))}
            </div>
    );
}

export default ImageGallery;
