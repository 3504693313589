import React from "react";
import Layout from "../../Components/Common/Layout";
import NotFound from "../../Components/NotFound/NotFound";

const NotFoundPage = () => {
    return (
        <Layout>
            <NotFound />
        </Layout>
    );
};

export default NotFoundPage;