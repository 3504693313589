export const BusinessTelecomMessagingData = {
  title: "Messaging",
  bodyText:
    "Providing helpful messaging channels, and rich communication to keep connected!",
  items: [
    {
      title: "Bulk Accounts",
      text: "We help you to reach your target audience by sending bulk messages to reach them all in once click.",
    },
    {
      title: "Ads SMS",
      text: "Segment your audience, customize your messages and access to different categories to strengthen the SMS campaign quality.",
    },
    {
      title: "Application to Person (A2P)",
      text: "We support you to verified your call, email, accounts…. Etc.",
    },
    {
      title: " Person to Application (P2A)",
      text: "We make the authentication process much easier.",
    },
    {
      title: "Rich SMS",
      text: "Replace your standard SMS messages with upgraded, interactive messages that support P2P or B2P transmission. We will help you to attach high-resolution photos and other multimedia to enhance your experience.",
    },
    {
      title: "Machine to machine",
      text: "Enhance automated communication among different machines and applications through a fast, secure, and reliable network.",
    },
  ],
};

export const ShelfBusinessSolutionsData = {
  title: "Off The Shelf Business Solutions",
  bodyText:
    "Addressing common business needs through pre-built, ready-to-use software that is available to businesses without the need for extensive customization or development.",
  items: [
    "Enterprise Resource Planning (ERP)",
    "Customer Relationship Management (CRM)",
    "Human Resources Management (HRM)",
    "Point of Sales Solutions (POS)",
  ],
};
