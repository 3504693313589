import React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import styles from './CardAction.module.css';
import { useMediaQuery } from "@mui/material";

export default function CardAction({ imageSrc, title, paragraph }) {
    const isSmallScreen = useMediaQuery("(max-width:1100px)");
    return (
        <Card
            sx={{
                bgcolor: "#fff",
                width: "320px",
                height: "274px",
                borderRadius: '8px',
                margin: isSmallScreen?"0rem":'2rem'
            }}
        >
            <CardContent sx={{ textAlign: "left", paddingTop: "2rem" }}>
                <img src={imageSrc} alt="Logo" className={isSmallScreen?styles.smallLogo:styles.logo} />
                <Typography
                    sx={{
                        marginTop: "0.9rem",
                        color: "#0B3782",
                        fontSize: isSmallScreen?15:20,
                        fontFamily: 'Roie',
                        fontWeight: 400,
                        lineHeight: "28px",
                        letterSpacing: "-0.48px",
                        textAlign: "left",
                    }}
                    color="text.secondary"
                    gutterBottom
                >
                    {title}
                </Typography>
                <Typography
                    sx={{
                        marginTop: "0.9rem",
                        color: "#0B3782",
                        fontSize: isSmallScreen?12:15,
                        fontFamily: 'Prelia',
                        fontWeight: 400,
                        lineHeight: "29.5px",
                        letterSpacing: ".2px",
                        textAlign: "left",
                        width: '242px',
                        height: '59px',
                    }}
                    color="text.secondary"
                    gutterBottom
                >
                    {paragraph}
                </Typography>
            </CardContent>
        </Card>
    );
}