import React, { useEffect, useRef } from "react";
import { useParams } from "react-router-dom";
import Layout from '../../Components/SpecialComponent/Layout';
import ContactForm from '../../Components/ContactForm/ContactForm';
import ExternalActions from '../../Components/ExternalActions/ExternalActions';
import Faquestions from "../../Components/Faquestions/Faquestions";

const ContactUsPage = () => {
    const { flag } = useParams();
    const faqsLineRef = useRef(null);

  useEffect(() => {
    if (faqsLineRef.current && flag === "faqs")
        faqsLineRef.current.scrollIntoView({ behavior: "smooth" });
  }, [flag]);

    return (
        <Layout>
            <ContactForm 
            backgroundImageUrl= {process.env.PUBLIC_URL +"/assets/images/WAVES.svg"}
            title_first="Let’s get in touch" />
            <ExternalActions />
            <div ref={faqsLineRef}>
                <Faquestions />
            </div>
        </Layout>
    );
};

export default ContactUsPage;
