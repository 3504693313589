import React from 'react';
import { Button } from '@mui/material';

function StyledButton({ children }) {
  return (
    <Button
      sx={{
        fontFamily: 'Roie',
        color: "#737373",
        fontSize: '14px',
        lineHeight: '1.5',
        letterSpacing: '0.2px',
        textAlign: 'center',
        width: 'fit-content',
        textTransform: 'capitalize',
        height: '24px',
        '&:hover': {
          backgroundColor: "transparent",
        },
      }}
    >
      {children}
    </Button>
  );
}

export default StyledButton;