import React from "react";
import { Box, Typography } from "@mui/material";
import styled from "@emotion/styled";

const CustomTypography = styled(Typography)({
  color: "#0B3782",
  fontFamily: "Prelia",
  fontSize: "16px",
  lineHeight: "44px",
  letterSpacing: "-0.72px",
  marginBottom: "16px",
  "@media (max-width: 600px)": {
    lineHeight: "30px",
    marginBottom: "20px",
  },
});

const BlogText = ({ descriptionDetails }) => {
  const BlogTextItems = descriptionDetails?.split("@@@");

  return (
    <Box>
      {BlogTextItems?.map((item) => {
        return <CustomTypography>{item}</CustomTypography>;
      })}
    </Box>
  );
};

export default BlogText;
