import { Box, Button, Typography, useMediaQuery } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";

const aboutUniverseText =
  "We at Universe employ state-of-the-art technologies and utilize our cutting-edge skills with our professionals to provide the technology you deserve, 16 years ago Universe Telecom has been established and it was the first licensed VAS company in Palestine. Due to the success that has been achieved by Universe Telecom, we adopt a growth strategy that revealed to expand our business into four main business units which are Universe Telecom, Universe Software Solutions, Universe Technologies, and Universe E-Marketing all under the Universe brand name.";

function AboutFirstSection() {
  const navigate = useNavigate();
  const isMediumScreen = useMediaQuery("(max-width:1100px)");
  const isSmallScreen = useMediaQuery("(max-width:800px)");
  return (
    <>
      <Box display={"flex"} justifyContent={"space-between"} marginTop={"120px"}>
        <Box
          className="left-section"
          marginLeft={isSmallScreen ? "2rem" : "100px"}
          textAlign={"justify"}
        >
          <Typography
            fontFamily={'Qualy'}
            fontSize={isSmallScreen ? "28px" : "35px"}
            color={"#0B3782"}
          >
            About Universe
          </Typography>
          <Typography
            fontFamily={"Prelia"}
            fontSize={isSmallScreen ? "15px" : "18px"}
            width={isSmallScreen ? "70%" : "596px"}
            margin={"30px 0px 44px"}
            color={"#0B3782"}
          >
            {aboutUniverseText}
          </Typography>
          <Button
            onClick={() => navigate("/whyuniverse")}
            sx={{
              bgcolor: "#4B82F7",
              fontFamily: "Prelia",
              fontSize: isSmallScreen ? "12px" : "15px",
              width: isSmallScreen ? "8rem" : "230px",
              height: "40px",
              letterSpacing: "0.20000000298023224px",
              textAlign: "center",
              textTransform: "none",
            }}
            variant="contained"
          >
            Why Universe
          </Button>
        </Box>
        {!isMediumScreen && (
          <Box className="right-section" style={{ position: 'relative' }}>
            <img src={process.env.PUBLIC_URL +'/assets/images/aboutuniverse.svg'} width="100%" height="100%" />
          </Box>
        )}
      </Box>
    </>
  );
}

export default AboutFirstSection;
