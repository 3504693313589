import React from 'react';
import styles from './ImageWithText.module.css';
import UniverseInfo from '../UniverseInfo/UniverseInfo';

function ImageWithText({ backgroundImageUrl, imageUrl, title, paragraph }) {
    const containerStyle = {
        backgroundImage: `url(${process.env.PUBLIC_URL}${backgroundImageUrl})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        height: "590px"
    };

    const imageStyle = {
        backgroundImage: `url(${process.env.PUBLIC_URL}(${imageUrl})`,
        backgroundSize: 'contain',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        height: '590px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'flex-start',
        padding: '20px',
    };

    return (
        <div className={styles.imageWithTextContainer} style={containerStyle}>
            <div className={styles.content}>
                <div className={styles.image} style={imageStyle}>
                    <h2 className={styles.title}>{title}</h2>
                    <p className={styles.paragraph}>{paragraph}</p>
                    <div className={styles.universeInfo}>
                        <UniverseInfo />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ImageWithText;
